import React, { useContext, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  AudioContext,
  LoaderContext,
  PodcastContext,
  UserContext,
} from "../../contexts";
import moment from "moment";
import Logo from "../../assets/earbud.jpg";
import {
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Paper,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import "./index.css";
import { useState } from "react";
import { useEffect } from "react";
import ReportIcon from "@mui/icons-material/Report";
import { authReq, sendErrorReport } from "../../utilities/requests";
import Quiz from "../../components/quiz";
import { CardContext } from "../../contexts/cardContext";
import { Add, Favorite, FavoriteBorder } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import NotPublished from "../../components/NotPublished";

const uuidv4 = require("uuid").v4;

const PodcastDetails = () => {
  const navigate = useNavigate();
  let { podcastId } = useParams();
  let { arrayOfAllPodcasts } = useContext(PodcastContext);
  const { handlePlay, currentAudio } = useContext(AudioContext);
  const { user, plan, setPremiumModal } = useContext(UserContext);

  const { loader, setLoader, isMobile } = useContext(LoaderContext);
  const { myClass, setMyClass } = useContext(PodcastContext);
  let types = ["Verdadera / Falsa", "Opción multiple"];
  let questionTemplate = {
    type: types[0],
    content: "",
    answers: ["Verdadera", "Falsa"],
    checkboxValues: [false, false],
    selectedAnswer: null,
    id: Date.now(),
  };

  const [questions, setQuestions] = useState([questionTemplate]);
  const [areUnsavedChanges, setAreUnsavedChanges] = useState(false);
  const [podcast, setPodcast] = useState(null);
  const [showQuestions, setShowQuestions] = useState(false);
  const [editQuestions, setEditQuestions] = useState(false);
  const [showResponses, setShowResponses] = useState(false);
  const [reportText, setReportText] = useState("");
  const [reportModal, setReportModal] = useState(false);
  const [reportMsg, setReportMsg] = useState("Reportar este podcast");
  const [responses, setResponses] = useState([]);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [liked, setLiked] = useState(false);
  const [saved, setSaved] = useState(false);
  const responsesRef = useRef(null);
  const { likedPodcasts, setLikedPodcasts, savedPodcasts, setSavedPodcasts } =
    useContext(CardContext);

  const { messages, setMessages } = useContext(PodcastContext);
  useEffect(() => {
    let podcastFromContext = arrayOfAllPodcasts.find(
      (obj) => obj.id === podcastId
    );
    if (podcastFromContext && podcastFromContext.owner) {
      setPodcast(podcastFromContext);
    } else {
      authReq({ url: `/get_podcasts/id/?id=${podcastId}` })
        .then((res) => {
          let podcastOwner = res.data[0]?.users.find(
            (user) => user.role === "owner"
          );
          // parse transcriptData and transcriptText
          if (res.data[0].transcriptData) {
            res.data[0].transcriptData = JSON.parse(res.data[0].transcriptData);
            res.data[0].transcriptText = JSON.parse(res.data[0].transcriptText);
          }
          setPodcast({
            ...res.data[0],
            owner: podcastOwner,
          });
        })
        .catch((err) => {
          console.log("ERROR");
          console.log(err);
          navigate("/");
        });
    }
  }, [podcastId]);

  const handleLikeButtonClick = () => {
    liked ? turnNegative("like") : turnPositive("like");
  };
  const handleSaveButtonClick = () => {
    saved ? turnNegative("save") : turnPositive("save");
  };

  const turnPositive = async (type) => {
    type === "like" ? setLiked(true) : setSaved(true);
    let person = podcast.owner;

    setMessages([
      ...messages,
      {
        type: type === "like" ? "like" : "save",
        podcast,
        person: person,
        id: uuidv4(),
      },
    ]);
    let response = await authReq({
      url: `/like_or_save/?type=${type}`,
      method: "POST",
      data: {
        podcast: podcast.id,
      },
    });
    if (type === "like") {
      setLikedPodcasts([...likedPodcasts, response.data[0]]);
    } else {
      setSavedPodcasts([...savedPodcasts, response.data[0]]);
    }
  };

  const turnNegative = async (type) => {
    let person = podcast.owner;
    type === "like" ? setLiked(false) : setSaved(false);
    setMessages([
      ...messages,
      {
        type: type === "like" ? "unlike" : "unsave",
        podcast,
        person: person,
        id: uuidv4(),
      },
    ]);

    let response = await authReq({
      url: `/unlike_or_unsave/?type=${type}`,
      method: "DELETE",
      data: {
        podcast: podcast.id,
      },
    });
    let cache = type === "like" ? likedPodcasts : savedPodcasts;

    const filteredPods = cache.filter((pod) => {
      return pod.podcast !== response.data;
    });

    type === "like"
      ? setLikedPodcasts(filteredPods)
      : setSavedPodcasts(filteredPods);
  };
  useEffect(() => {
    if (showResponses && responsesRef?.current) {
      responsesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [showResponses, showQuestions, loader]);
  useEffect(() => {
    if (likedPodcasts.find((pod) => pod.podcast === podcast?.id)) {
      setLiked(true);
    }
    if (savedPodcasts.find((pod) => pod.podcast === podcast?.id)) {
      setSaved(true);
    }
  }, [podcast]);
  const handleCreatorClick = (participant, series) => {
    navigate(
      `/perfil/${participant.id}/?clickedUser=${JSON.stringify(
        participant
      )}&series=${encodeURIComponent(JSON.stringify(series))}`
    );
    // setLoader(true);
  };

  const handleClassClick = (participant) => {
    navigate(`/clase/${podcast.owner.classCode}`);
    // setLoader(true)
  };
  const handleGetPDF = async () => {
    if (!plan.podcastDocFeature) return setPremiumModal(true);
    if (!podcast?.pdfId) return alert("No hay documento para este podcast");

    try {
      const response = await authReq({
        url: `/pdf_file/${podcast.pdfId}`,
        responseType: "blob",
      });

      const blobUrl = URL.createObjectURL(response.data);
      window.open(blobUrl, "_blank");
    } catch (error) {
      sendErrorReport("PodcastDetails", error);

      console.error("Error fetching PDF:", error);
      alert("No hay PDF para este podcast");
    }
  };
  const handleViewClick = async (classCode, isForShowResponse) => {
    if (!plan.quizFeature) return setPremiumModal(true);
    if (isForShowResponse) setShowResponses(!showResponses);
    if (showQuestions && editQuestions) {
      setEditQuestions(false);
    } else {
      setShowQuestions(!showQuestions);
      setEditQuestions(false);
    }
  };

  const handleEditClick = async (classCode) => {
    if (!plan.quizFeature) return setPremiumModal(true);

    if (showQuestions && !editQuestions) {
      setEditQuestions(true);
      // setPremiumModal(true);
    } else {
      setShowQuestions(!showQuestions);
      setEditQuestions(!editQuestions);
      // setPremiumModal(true);
    }
  };
  const handleReport = async () => {
    if (!reportText) return alert("Por favor, incluya un mensaje");
    authReq({
      url: "/report",
      method: "POST",
      data: { podcastId: podcast.id, text: reportText },
    }).then((res) => {
      setReportMsg("Ya reportado");
      setReportText("");
      setReportModal(false);
    });
  };
  const formattedDate = moment(podcast?.date).format("MMMM YYYY");
  const capitalizeFirstLetter = (string) => {
    return string.replace(/\b(\w)/g, (s) => s.toUpperCase());
  };
  // Capitalize the first letter of each word in the formatted date
  const returnFlag = () => {
    // <span className="fi fi-ca"></span>
    // switch case for data.languageCode (for example "es-ES") reutnring the flag
    // english ,spanish, catalan, basque,
    switch (podcast.languageCode) {
      case "es-ES":
        return <span className="fi fi-es"></span>;
      case "en-US":
        return <span className="fi fi-us"></span>;
      case "ca-ES":
        return <span className="fi fi-es-ct"></span>;
      case "eu-ES":
        return <span className="fi fi-es-pv"></span>;
      default:
        return <span></span>;
    }
  };
  const capitalizedDate = capitalizeFirstLetter(formattedDate);
  const returnContent = () => {
    if (podcast) {
      return (
        <div className="details-container">
          <img src={`/img_file/${podcast.seriesId}`} />
          <div className="top-details">
            {!podcast.isPublished && <NotPublished />}
            <div className="d-flex gap-2 mt-2">
              {returnFlag()} <h1>{podcast.title}</h1>
            </div>

            <p>
              {podcast.series ? (
                <span>
                  Episodio del podcast{" "}
                  <span
                    className="link-text"
                    onClick={() =>
                      handleCreatorClick(podcast.owner, podcast.series)
                    }
                  >
                    {podcast.series}
                  </span>
                </span>
              ) : (
                ""
              )}
            </p>
            <p>{capitalizedDate}</p>
          </div>
          {isMobile && (
            <div className="buttons">
              <Button
                aria-label="settings"
                size="large"
                className="podcast-button"
                onClick={() => handlePlay(podcast)}
              >
                {podcast.id === currentAudio?.id && currentAudio?.playing ? (
                  <PauseCircleFilledIcon />
                ) : (
                  <PlayCircleFilledWhiteIcon />
                )}
                Reproducir
              </Button>
            </div>
          )}

          <div className="secondary-info">
            <span>{podcast.classLevel && podcast.classLevel}</span>
            {/* <span>{podcast.subject && podcast.subject}</span>
            <span>{podcast.language && "| " + podcast.language}</span> */}
          </div>
          <p className="description">{podcast.description}</p>
          <div className="buttons">
            {!isMobile && (
              <Button
                aria-label="settings"
                size="large"
                className="podcast-button"
                onClick={() => handlePlay(podcast)}
                style={{ minWidth: "200px" }}
              >
                {podcast.id === currentAudio?.id && currentAudio?.playing ? (
                  <PauseCircleFilledIcon />
                ) : (
                  <PlayCircleFilledWhiteIcon />
                )}
                Reproducir
              </Button>
            )}

            <Button
              aria-label="settings"
              size="large"
              className="podcast-button"
              onClick={() => handleSaveButtonClick()}
            >
              {saved ? (
                <>
                  <CheckIcon
                  //  className="liked"
                  />
                  Añadido
                </>
              ) : (
                <>
                  <Add
                  //  className="liked"
                  />
                  Añadir a tu lista
                </>
              )}
            </Button>
            <Button
              aria-label="settings"
              size="large"
              className="podcast-button"
              onClick={() => handleLikeButtonClick(podcast)}
            >
              {liked ? (
                <>
                  <Favorite
                  //  className="liked"
                  />
                  Te gusta
                </>
              ) : (
                <>
                  <FavoriteBorder
                  //  className="liked"
                  />
                  Me gusta
                </>
              )}
            </Button>
            <Button
              aria-label="settings"
              size="large"
              className={`podcast-button ${
                reportMsg === "ya reportado" ? "lightgrey" : ""
              }`}
              onClick={() => setReportModal(true)}
              disabled={reportMsg === "ya reportado"}
            >
              <ReportIcon
              //  className="liked"
              />
              {reportMsg}
            </Button>
          </div>
          <div className="span-5-hr"></div>

          <h2 style={{ maxWidth: "90vw" }}>
            Este episodio tiene más contenido
          </h2>
          <div
            className="buttons center-buttons"
            style={{ marginBottom: "20px" }}
          >
            <Button
              aria-label="settings"
              size="large"
              className="podcast-button"
              onClick={() => {
                if (!plan.quizFeature) return setPremiumModal(true);
                handleGetPDF();
              }}
            >
              Ficha de aprendizaje
            </Button>
            {user?.classCode === podcast.owner?.classCode && (
              <Button
                aria-label="settings"
                size="large"
                className={`podcast-button ${
                  showQuestions ? "active-button" : ""
                }`}
                onClick={() => {
                  if (!plan.quizFeature) return setPremiumModal(true);
                  setShowQuestions(!showQuestions);
                  setShowResponses(false);
                }}
              >
                Revisión del aprendizaje
              </Button>
            )}

            {user?.isClassAdmin &&
              user?.classCode === podcast.owner?.classCode && (
                <Button
                  aria-label="settings"
                  size="large"
                  className={`podcast-button ${
                    showResponses ? "active-button" : ""
                  }`}
                  onClick={() => {
                    if (!plan.quizFeature) return setPremiumModal(true);
                    setShowResponses(!showResponses);
                    setShowQuestions(true);
                  }}
                >
                  Resultados de revisión
                </Button>
              )}
          </div>
          <div
            style={{
              gridColumn: "span 5",
              maxWidth: "90vw",
              overflow: "auto",
            }}
          >
            {showQuestions && (
              <Quiz
                setIsFormDirty={setIsFormDirty}
                podcast={podcast}
                editQuestions={editQuestions}
                setEditQuestions={setEditQuestions}
                questions={questions}
                showQuestions={showQuestions}
                setQuestions={setQuestions}
                types={types}
                questionTemplate={questionTemplate}
                areUnsavedChanges={areUnsavedChanges}
                setAreUnsavedChanges={setAreUnsavedChanges}
                showResponses={showResponses}
                setShowResponses={setShowResponses}
                responses={responses}
                setResponses={setResponses}
                responsesRef={responsesRef}
              />
            )}
          </div>
          <Modal
            open={reportModal}
            onClose={() => setReportModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className="app-modal-container report-modal">
              <Paper>
                <IconButton
                  className="modal-close-button"
                  onClick={() => setReportModal(false)}
                >
                  <CloseIcon />
                </IconButton>
                <h3>Razón del informe</h3>
                <textarea
                  value={reportText}
                  onChange={(e) => setReportText(e.target.value)}
                />
                <button onClick={handleReport}>Reportar</button>
              </Paper>
            </div>
          </Modal>
        </div>
      );
    } else {
      return <div>Cargando...</div>;
    }
  };

  return returnContent();
};

export default PodcastDetails;
