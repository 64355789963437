import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { Add } from "@mui/icons-material";
const UserTable = ({
  users,
  user,
  resentEmails,
  resend,
  setRole,
  setShowAddModal,
}) => {
  const [sendingStatus, setSendingStatus] = useState(
    users?.map((user) => false)
  );

  const loadAndSend = async (email, index) => {
    setSendingStatus((prev) =>
      prev.map((status, i) => (i === index ? true : status))
    );
    await resend(email);
    setSendingStatus((prev) =>
      prev.map((status, i) => (i === index ? false : status))
    );
  };
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nombre</TableCell>
            <TableCell>Correo electrónico</TableCell>
            <TableCell>Acciones</TableCell>
            <TableCell>Rol</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users?.map((classUser, index) => (
            <TableRow key={classUser.id}>
              <TableCell>
                {classUser.firstName
                  ? `${classUser.firstName} ${classUser.lastName}`
                  : "invitado - pero aun no ha creado cuenta"}
              </TableCell>
              <TableCell>
                <span>{classUser.email}</span>
              </TableCell>
              <TableCell>
                {/* Conditional rendering for admin actions */}
                {user.isClassAdmin &&
                  user.classCode === classUser?.class?.id && (
                    // Example of how to integrate the remove user functionality, adjust as needed
                    // <HighlightOff className="close-button" onClick={() => handleRemoveUser(classUser)} />
                    <></>
                  )}
                {!classUser.isPending ? (
                  <Link
                    to={`/perfil/${classUser.id}/?clickedUser=${JSON.stringify(
                      classUser
                    )}`}
                  >
                    <Button>Ver perfil</Button>
                  </Link>
                ) : sendingStatus[index] ? (
                  <div
                    className="spinner-border"
                    style={{ width: "1rem", height: "1rem" }}
                    role="status"
                  >
                    <span className="sr-only"></span>
                  </div>
                ) : resentEmails.includes(classUser.email) ? (
                  <span>¡Invitación reenviada!</span>
                ) : (
                  <button
                    className="btn btn-primary text-white"
                    onClick={() => loadAndSend(classUser.email, index)}
                  >
                    Reenviar invitación
                  </button>
                )}
              </TableCell>
              <TableCell>
                {user.isClassAdmin && (
                  <>
                    <Button
                      onClick={() =>
                        setRole(classUser, true, user.id !== classUser.id)
                      }
                      className={`${classUser.teacherRole ? "active" : ""}`}
                    >
                      Profesor
                    </Button>
                    <Button
                      onClick={() =>
                        setRole(classUser, false, user.id !== classUser.id)
                      }
                      className={`${!classUser.teacherRole ? "active" : ""}`}
                    >
                      Estudiante
                    </Button>
                  </>
                )}
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>
              <div></div>
              <button
                onClick={() => setShowAddModal((prev) => !prev)}
                className="icon-and-text-button"
              >
                <AddIcon />
                Agregar miembros
              </button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserTable;
