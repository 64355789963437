import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useContext } from "react";
import { LoaderContext } from "../../contexts";
import CircularProgress from "@mui/material/CircularProgress";
import "./index.css";

export default function LoaderModal() {
  const { loader } = useContext(LoaderContext);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "2px solid #000",
  };

  return (
    <div>
      <Modal
        open={loader}
        // onClose={() => setLoader(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style} className="app-modal-container">
          <CircularProgress size={200} />
        </div>
      </Modal>
    </div>
  );
}
