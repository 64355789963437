import { useState } from "react";
import Login from "./Login";
import Register from "./Register";
import appLogo from "../../assets/Playpod_beta_logo.png";
import "./index.css";
import { Navigate, useLocation } from "react-router-dom";
import Password from "./Password";
import titleImg from "../../assets/title_question.png";
import playpod1 from "../../assets/playpod_1.png";
import playpod2 from "../../assets/playpod_2.png";
import playpod3 from "../../assets/playpod_3.png";
import playpod4 from "../../assets/playpod_4.png";

const Auth = () => {
  const [page, setPage] = useState("Iniciar sesión");
  const location = useLocation();
  if (location.pathname.length > 1) {
    return <Navigate to="/" />;
  }
  return (
    <div className="auth-page">
      <div id="left-auth">
        <a className="title-flex" target="_" href="https://playpod.education">
          <img src={appLogo} alt="logo" className="logo" />
          <p id="logo-subtitle">Plataforma de podcasts escolares</p>
          <img src={titleImg} alt="title" id="primary-question" />
        </a>
        <div id="image-stack" href="#location">
          <img src={playpod1} alt="title" />
          <img src={playpod2} alt="title" />
          <img src={playpod3} alt="title" />
          <img src={playpod4} alt="title" />
        </div>
      </div>
      {/* register login or password pgage */}

      {page === "Registrar" ? (
        <Register page={page} setPage={setPage} />
      ) : page === "Iniciar sesión" ? (
        <Login page={page} setPage={setPage} />
      ) : (
        <Password page={page} setPage={setPage} />
      )}
    </div>
  );
};

export default Auth;
