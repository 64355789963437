import { Box, IconButton, Modal } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

import React, { useContext } from "react";
import { UserContext } from "../../contexts";

const PremiumFeatureModal = () => {
  const navigate = useNavigate();
  const { premiumModal, setPremiumModal, user, plan } = useContext(UserContext);
  const handleClick = (event) => {
    event.preventDefault(); // Prevents default link behavior
    event.stopPropagation(); // Stops the event from propagating to modal
    navigate("suscripción");
    setPremiumModal(false);
  };

  return (
    <Modal open={premiumModal} onClose={() => setPremiumModal(false)}>
      <Box className="modal-content">
        <IconButton
          className="modal-close-icon"
          onClick={() => setPremiumModal(false)}
        >
          <CloseIcon />
        </IconButton>
        {plan["podcastDocFeature"] && !user.isClassAdmin ? (
          <p>
            Tu clase tiene suscripción, pero solo los profesores de la clase
            puede subir documents con un podcast
          </p>
        ) : (
          <p>
            Esta es una función premium para usuarios suscritos.
            {user?.isClassAdmin ? (
              <>
                {" "}
                ¡haz clic <Link onClick={handleClick}>aquí</Link> para
                suscribirse!
              </>
            ) : (
              <>
                <br></br>
                Si eres estudiante, tienes que contactar con tu profesor.
                <br></br>
                <br></br>
                Se eres profesor/a, hay que{" "}
                <Link onClick={() => setPremiumModal(false)} to="suscripción">
                  suscribirse
                </Link>
                . (y <Link to="/clase">crear una clase</Link> para que tus
                estudiantes puedan usar tus funciónes premium) <br></br>
              </>
            )}
          </p>
        )}
      </Box>
    </Modal>
  );
};

export default PremiumFeatureModal;
