import React, { useContext, useState } from "react";
import { PodcastContext, UserContext } from "../../contexts";
import Card from "../../components/card";
import { Skeleton } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "./index.css";
import { CardContext } from "../../contexts/cardContext";
import { useEffect } from "react";
import axios from "axios";

const Library = () => {
  let {
    likedPodcastData,
    setLikedPodcastData,
    savedPodcastData,
    setSavedPodcastData,
  } = useContext(PodcastContext);
  let { user } = useContext(UserContext);

  const [callMade, setCallMade] = useState(false);
  useEffect(() => {
    // go get podcast data for all likedPodcasts
    axios({
      method: "PUT",
      url: "/get_liked_saved_podcast_data",
      data: { id: user.id },
    }).then((res) => {
      setLikedPodcastData(res.data.liked);
      setSavedPodcastData(res.data.saved);
      setCallMade(true);
    });
  }, []);
  // make this depending on

  const returnCards = (dataArray) => {
    if (callMade && dataArray.length < 1) {
      return [];
    }
    // let podcasts = dataArray?.length
    let podcasts = dataArray?.length
      ? dataArray.map((data) => <Card key={JSON.stringify(data)} data={data} />)
      : [1, 2, 3, 4, 5].map((number) => (
          <Skeleton
            key={number}
            variant="rectangular"
            width={180}
            height={208}
          />
        ));
    return podcasts;
  };

  // const responsiveValues = {
  //   1000: {items: 1},

  // };
  const responsiveValues = {
    530: { items: 2 },
    915: { items: 3 },
    1150: { items: 4 },
    1500: { items: 4 },
    1700: { items: 5 },
  };
  const carouselRows = [
    {
      title: "Tus podcasts favoritos",
      podcastArray: returnCards(likedPodcastData),
      data: likedPodcastData,
    },
    {
      title: "Podcasts guardados",
      podcastArray: returnCards(savedPodcastData),
      data: savedPodcastData,
    },
    // { title: 'Following', podcastArray: returnCards(podcastCache.random), data: podcastCache.random },
  ];
  // if call made & empty, show message
  // if call made and not empty, show stuff
  // if not call made, show loaders
  const returnMsg = (ifFavorites) => {
    if (ifFavorites) {
      return (
        <div className="information-block">
          Todavía no has añadido ningún podcast a favoritos.
        </div>
      );
    } else {
      return (
        <div className="information-block">
          Todavía no has guardado ningún podcast.
        </div>
      );
    }
  };
  return (
    <div className=" w-100">
      <div id="upper-text-container">
        <h1>Tus podcasts guardados y favoritos</h1>
        {/* <div className="sub-heading">Últimos podcasts</div> */}
      </div>
      <div className="card-container">
        {carouselRows.map((rowObj, index) => {
          return (
            <div className="single-carousel" key={rowObj.title}>
              <h3>{rowObj.title}</h3>
              {callMade && rowObj.data.length < 1 ? (
                returnMsg(index == 0)
              ) : (
                <AliceCarousel
                  mouseTracking
                  items={rowObj.podcastArray}
                  responsive={responsiveValues}
                  // autowidth true causing responsiveness issues
                  // autoWidth={true}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Library;
