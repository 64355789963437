import React from "react";

import { PodcastContextProvider } from "./podcastContext";
import { UserProvider } from "./userContext";
import { AudioProvider } from "./audioContext";
import { LoaderContextProvider } from "./loaderContext";
import { BreadcrumbContextProvider } from "./breadcrumbContext";
import { CardProvider } from "./cardContext";

export { UserContext } from "./userContext";
export { PodcastContext } from "./podcastContext";
export { AudioContext } from "./audioContext";
export { LoaderContext } from "./loaderContext";
export { BreadcrumbContext } from "./breadcrumbContext";

export function ContextProvider({ children }) {
  return (
    <UserProvider>
      <LoaderContextProvider>
        <BreadcrumbContextProvider>
          <PodcastContextProvider>
            <AudioProvider>
              <CardProvider>{children}</CardProvider>
            </AudioProvider>
          </PodcastContextProvider>
        </BreadcrumbContextProvider>
      </LoaderContextProvider>
    </UserProvider>
  );
}
