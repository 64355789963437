import React, { useState, createContext, useContext, useEffect } from "react";
import axios from "axios";
import { UserContext } from "./userContext";

export const CardContext = createContext();

export function CardProvider(props) {
  const [likedPodcasts, setLikedPodcasts] = useState(null);
  const [savedPodcasts, setSavedPodcasts] = useState(null);
  const { user, token } = useContext(UserContext);

  useEffect(() => {
    if (user) {
      // get liked pods
      axios({
        method: "get",
        url: `/get_likes_and_saves`,
        headers: {
          Authorization: token,
        },
      }).then((res) => {
        setLikedPodcasts(res.data.liked);
        setSavedPodcasts(res.data.saved);
      });
    } else {
      setLikedPodcasts([]);
      setSavedPodcasts([]);
    }
  }, [user]);

  return (
    <CardContext.Provider
      value={{
        likedPodcasts,
        setLikedPodcasts,
        savedPodcasts,
        setSavedPodcasts,
      }}
    >
      {props.children}
    </CardContext.Provider>
  );
}
