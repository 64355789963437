import { useState, createContext, useEffect, useRef } from "react";
import "@aws-amplify/ui-react/styles.css";
import axios from "axios";
import avatar_blue from "../assets/user_avatars/avatar_blue.png";
import avatar_grey from "../assets/user_avatars/avatar_grey.png";
import avatar_orange from "../assets/user_avatars/avatar_orange.png";

export const UserContext = createContext();

export function UserProvider(props) {
  const avatarsMap = {
    1: avatar_blue,
    2: avatar_grey,
    3: avatar_orange,
  };
  let seenTutorial = localStorage.getItem("seen_tutorial");
  const [user, setUser] = useState(null);
  const [userAvatar, setUserAvatar] = useState(null);
  const [isGettingData, setIsGettingData] = useState(true);
  const [token, setToken] = useState(null);
  const [podcastCallWasMade, setPodcastCallWasMade] = useState(false);
  const [profilePods, setProfilePods] = useState([]);
  const [followed, setFollowed] = useState([]);
  const [customerData, setCustomerData] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [plan, setPlan] = useState(false);
  const [premiumModal, setPremiumModal] = useState(false);
  const [preferences, setPreferences] = useState({
    emails: {
      boolean: true,
      displayDesc: false,
      label: "Correos electrónicos",
      description:
        "Recibe correos electrónicos sobre nuevas características, actividades de la cuenta y actualizaciones.",
    },
    allNotifications: {
      boolean: true,
      displayDesc: false,
      label: "Todas las notificaciones",
      description: "Recibe todas las notificaciones.",
    },
    likesNotifications: {
      boolean: true,
      displayDesc: false,
      label: "Me gusta",
      description:
        "Recibe notificaciones cuando a alguien le gustan tus podcasts.",
    },
    savesNotifications: {
      boolean: true,
      displayDesc: false,
      label: "Guardados",
      description: "Recibe notificaciones cuando alguien guarda tus podcasts.",
    },
    classNotifications: {
      boolean: true,
      displayDesc: false,
      label: "Clase",
      description: "Recibe notificaciones sobre tu clase.",
    },
  });
  const [runTour, setRunTour] = useState(seenTutorial !== "true");
  const [stepsIndex, setStepsIndex] = useState(0);
  console.log(user);
  const resetTour = () => {
    setRunTour(true);
    setStepsIndex(0);
  };
  console.log({ profilePods });

  const buttonsContainerRef = useRef(null);
  useEffect(() => {
    if (!user || !user.preferences) return;
    // if user has preferences, set them in state

    let preferenceKeys = Object.keys(user.preferences);
    let newPreferences = { ...preferences };
    preferenceKeys?.map((key) => {
      if (typeof user.preferences[key].boolean == "undefined") {
        newPreferences[key].boolean = user.preferences[key];
      } else {
        newPreferences[key].boolean = user.preferences[key].boolean;
      }
    });
    // setPreferences(newPreferences);
  }, [user]);
  useEffect(() => {
    if (!user) return;
    // make request to get user's avatar and add it to the user object.
    axios({
      method: "PUT",
      url: "/get_avatar_and_follows",
      data: { id: user.id },
    }).then((res) => {
      setIsGettingData(false);
      setUserAvatar(avatarsMap[res.data.avatar[0]?.avatar]);
      setFollowed(res.data.followed);
      // should have avatar here and set user if avatar
    });
    // setUserAvatar(avatarsMap[user.avatar]);
  }, [user]);

  const signOut = async () => {
    axios("/logout").then((res) => {
      if (res.data.loggedOut === true) {
        setUser(false);
      }
    });
  };
  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        customerData,
        setCustomerData,
        premiumModal,
        setPremiumModal,
        runTour,
        setRunTour,
        userAvatar,
        setUserAvatar,
        avatarsMap,
        paymentSuccess,
        setPaymentSuccess,
        resetTour,
        isGettingData,
        setIsGettingData,
        preferences,
        setPreferences,
        token,
        setToken,
        stepsIndex,
        setStepsIndex,
        signOut,

        followed,
        setFollowed,

        profilePods,
        setProfilePods,
        buttonsContainerRef,
        podcastCallWasMade,
        setPodcastCallWasMade,
        plan,
        setPlan,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
}
