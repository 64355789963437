import React from "react";
import "./index.css";
const NotPublished = () => {
  return (
    <div
      title="Este podcast debe ser publicado por un profesor de tu clase antes de que otros usuarios puedan verlo o escucharlo."
      className="not-pub mb-3"
    >
      ***no publicado***
    </div>
  );
};

export default NotPublished;
