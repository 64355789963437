import React, { useContext } from "react";
import icon from "../../assets/earbud.jpg";
import { LoaderContext, PodcastContext, UserContext } from "../../contexts";
import Card from "../../components/card";
import "./index.css";
import { useEffect } from "react";
import { authReq, sendErrorReport } from "../../utilities/requests";
import { useState } from "react";
import { Button, Modal, Paper } from "@mui/material";
import { ArrowDropDown, ArrowRight } from "@mui/icons-material";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import AddClassMembers from "../../components/addClassMembers";
import PremiumIcon from "../../components/premiumIcon";
import UserTable from "./table";
import { set } from "lodash";

const ClassProfile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const { myClass, setMyClass, otherClass, setOtherClass, makeRequests } =
    useContext(PodcastContext);

  const { user, plan, setPremiumModal, premiumModal } = useContext(UserContext);
  const { setLoader, setMessageState } = useContext(LoaderContext);
  const [resentEmails, setResentEmails] = useState([]);
  const [resentErrors, setResentErrors] = useState([]);
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [inviteToggle, setInviteToggle] = useState(false);
  const [addMethod, setAddMethod] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [editName, setEditName] = useState(false);
  const [newName, setNewName] = useState("");
  let classState;
  let classSetState;
  let classCode;

  if (params.classCode && params.classCode !== user.classCode) {
    classState = otherClass;
    classSetState = setOtherClass;
    classCode = params.classCode;
  } else {
    classState = myClass;
    classSetState = setMyClass;
    classCode = user.classCode;
  }

  const handleMembersClick = () => {
    if (classState?.users) {
      navigate("/clase/miembros");
    }
  };
  const handlePodsClick = () => {
    if (classState?.users) {
      navigate("/clase");
    }
  };

  useEffect(() => {
    if (premiumModal) {
      setPremiumModal(false);
    }
  }, []);
  let isUsers = location.pathname === "/clase/miembros";
  useEffect(() => {
    if (!user.isClassAdmin || !isUsers) return;
    setLoader(true);
    authReq({
      url: "/get_pending_class_invitations",
      method: "GET",
    })
      .then((usersRes) => {
        setLoader(false);
        setInvitedUsers(usersRes.data);
      })
      .catch((err) => console.log(err));
  }, [isUsers, inviteToggle]);

  useEffect(() => {
    if (
      !classState?.audios?.length ||
      (params.classCode !== classState?.class.classCode && classCode)
    ) {
      makeRequests(classCode, classSetState);
    }
  }, [params.classCode]);

  // useeffect to set newName to the starting state ofthe class state name
  useEffect(() => {
    if (classState?.class?.name) {
      setNewName(classState.class.name);
    }
  }, [classState?.class?.name]);

  let teacherObject =
    classState?.users?.length == 1
      ? classState.users[0]
      : classState?.users?.filter((user) => user.teacherRole === "primary")[0];
  let teacherName;

  if (teacherObject) {
    console.log(teacherObject);
    let firstName = teacherObject?.firstName;
    let lastName = teacherObject?.lastName;
    // capitalize first of each

    firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
    lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);
    teacherName = `${firstName} ${lastName}`;
  }

  const handleToggle = (podcast, index) => {
    const newAudios = [...classState?.audios];
    newAudios[index].isPublished = !newAudios[index].isPublished;
    authReq({
      url: "/handle_publish",
      method: "PUT",
      data: {
        newValue: newAudios[index].isPublished,
        audioId: newAudios[index].id,
      },
    });

    // Update the myClass state with the new audios array
    classSetState((prevState) => ({
      ...prevState,
      audios: newAudios,
    }));
  };
  const handleRemoveUser = async (classUser) => {
    let res = await authReq({
      url: `/removeFromClass/${classUser.id}/${classCode}`,
      method: "PUT",
    });

    if (res.status > 199 && res.status < 300) {
      // remove user from local state
      let usersCopy = classState?.users.filter((singleUser) => {
        return classUser.id !== singleUser.id;
      });
      classSetState({ ...classState, users: usersCopy });
    }
  };

  const setRole = (classUser, isAdmin, isOwn) => {
    alert(
      "De momento no se puede cambiar el rol de los usuarios... Pronto habra una actualizacion"
    );
  };
  if (!params.classCode && !user.classCode) {
    return <Navigate to="/clase/crear" />;
  }
  console.log(classState);
  const resend = async (email) => {
    if (resentEmails.includes(email)) return;
    try {
      await authReq({
        url: `/resend_invite/${email}/${classState?.class?.classCode}`,
        method: "POST",
      });
      setResentEmails([...resentEmails, email]);
    } catch (error) {
      sendErrorReport("resend", error);

      setResentErrors([...resentErrors, email]);
    }
  };

  const handleSelect = async (option) => {
    if (!plan.privatePodsFeature) return setPremiumModal(true);
    setLoader(true);
    try {
      await authReq({
        url: "/change_class_privacy",
        method: "PUT",
        data: { newData: option },
      });
    } catch (error) {
      console.log(error);
      sendErrorReport("handleSelect", error);
    }
    classSetState({
      ...classState,
      class: { ...classState.class, podsArePrivate: option },
    });
    setLoader(false);
  };

  let adminOfClass = user?.isClassAdmin && user?.classCode === classCode;
  let formatNumber = (num) => {
    // take num (string of 7) and add a dash after 3rd character
    if (num) {
      return num.slice(0, 3) + "-" + num.slice(3);
    }
    return "cargando...";
  };
  const handleNameChange = async () => {
    setLoader(true);
    try {
      await authReq({
        url: "/change_class_name",
        method: "PUT",
        data: { newName },
      });
      // update the class state with the new name
      setLoader(false);
      setEditName(false);
      classSetState({
        ...classState,
        class: { ...classState.class, name: newName },
      });
    } catch (error) {
      setLoader(false);
      setEditName(false);
      console.log(error);
      sendErrorReport("handleNameChange", error);
    }
  };
  const publicPrivateHTML = (
    <div>
      <div style={{ marginBottom: "8px" }}>
        Los podcasts de tu clase estan en modo...
      </div>
      <div className="class-buttons-container">
        <Button
          variant={
            !classState?.class?.podsArePrivate ? "contained" : "outlined"
          }
          onClick={() => handleSelect(false)}
        >
          Público
        </Button>
        <Button
          variant={classState?.class?.podsArePrivate ? "contained" : "outlined"}
          onClick={() => handleSelect(true)}
          style={{ marginLeft: "20px !important" }}
        >
          Privado
        </Button>
        <PremiumIcon top="-5px" />
      </div>
    </div>
  );

  return (
    <div className="class-profile d-flex flex-column align-items-center">
      <img src={icon} alt={`${"my class"} Icon`} className="class-icon" />
      <div className="d-flex gap-3 position-relative">
        <h1 className="class-name">
          {editName ? (
            <input
              type="text"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
          ) : (
            classState?.class?.name || " cargando..."
          )}
        </h1>
        {user.isClassAdmin &&
          user.classCode === classState?.class?.classCode && (
            <i
              className={`bi ${
                editName ? "bi-check-lg bg-light " : "bi-pencil bg-light"
              } cursor-hover  p-1 rounded-circle name-edit-icon`}
              style={{ height: "fit-content" }}
              onClick={() =>
                editName ? handleNameChange() : setEditName(true)
              }
            ></i>
          )}
      </div>

      {teacherObject && (
        <Link
          to={`/perfil/${teacherObject?.id}/?clickedUser=${JSON.stringify(
            teacherObject
          )}`}
          className="teacher-name"
        >
          {teacherName}
        </Link>
      )}

      <div className="class-data flex-column flex-md-row my-3 my-md-0">
        <span
          className={`${adminOfClass ? "class-members" : ""} `}
          onClick={handlePodsClick}
        >
          {classState?.audios?.length} Podcast
          {classState.audios.length === 1 ? "" : "s"}
        </span>
        <div className="vl d-none d-md-block "></div>
        <span
          className={`${adminOfClass ? "class-members" : ""} `}
          onClick={handleMembersClick}
        >
          {classState?.numberOfUsers} Miembro
          {classState?.numberOfUsers === 1 ? "" : "s"}
        </span>
        {adminOfClass && (
          <>
            <div className="vl d-none d-md-block"></div>
            <span
              className={`${adminOfClass ? "class-members" : ""} `}
              onClick={() =>
                setMessageState({
                  text: (
                    <div>
                      <h1 style={{ width: "fit-content", margin: "auto" }}>
                        {formatNumber(classState?.class?.joinPassword)}
                      </h1>
                      Este es tu código de clase. Tus alumnos pueden unirse a tu
                      clase introduciendo este código cuando crean una cuenta en
                      Playpod.
                    </div>
                  ),
                  time: 3000000,
                })
              }
            >
              Código de clase
            </span>
          </>
        )}
      </div>
      {adminOfClass && publicPrivateHTML}
      {adminOfClass && isUsers ? (
        <div className="members-grid">
          <h3>Usuarios</h3>

          <UserTable
            users={classState?.users}
            user={user}
            resentEmails={resentEmails}
            resend={resend}
            setRole={setRole}
            setShowAddModal={setShowAddModal}
          />
          <Modal
            open={showAddModal}
            onClose={() => setShowAddModal(false)}
            // onClose={() => setLoader(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Paper
              className="responsive-modal-paper"
              style={{ gridColumn: "span 4" }}
            >
              <h3>Agregar más miembros de la clase</h3>
              <AddClassMembers
                classCode={classState?.class?.classCode}
                classState={classState}
                setInviteToggle={setInviteToggle}
                addMethod={addMethod}
              />
            </Paper>
          </Modal>

          {invitedUsers?.length ? (
            <>
              <h3>Invitados</h3>
              <UserTable
                users={invitedUsers}
                user={user}
                resentEmails={resentEmails}
                resend={resend}
                setRole={setRole}
                setShowAddModal={setShowAddModal}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div className="podcasts-card-grid mt-4">
          {classState?.audios?.map((podcast, index) => (
            <div key={JSON.stringify(podcast)} className="publish-parent">
              {user.isClassAdmin &&
                user.classCode === classState.class.classCode && (
                  <ToggleButton
                    isPublished={podcast.isPublished}
                    onToggle={() => handleToggle(podcast, index)}
                  />
                )}
              <Card data={podcast} key={JSON.stringify(podcast)} />
            </div>
          ))}
          {!classState?.audios?.length && (
            <div className="no-podcasts">
              Todavía no hay podcasts de{" "}
              {classState?.class?.classCode === user.classCode
                ? " tu "
                : " este "}{" "}
              clase.{" "}
              {classState?.class?.classCode === user.classCode && (
                <Link to="/Mi Estudio/subir" style={{ cursor: "pointer" }}>
                  ¡Ve a Mi estudio para crear!
                </Link>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export function ToggleButton({ isPublished, onToggle, noMargin }) {
  const [hover, setHover] = useState(false);
  const text = isPublished
    ? hover
      ? "Despublicar"
      : "Publicado"
    : hover
    ? "Publicar"
    : "No Publicado";
  const publishClass = isPublished
    ? hover
      ? "Unpublished"
      : "Published"
    : hover
    ? "Published"
    : "Unpublished";

  return (
    <button
      style={noMargin ? { marginRight: "0px" } : {}}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onToggle}
      className={`publish-button ${publishClass}`}
    >
      {text}
    </button>
  );
}

export default ClassProfile;
