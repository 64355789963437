import React, { useContext, useEffect, useState } from "react";
import "./index.css";
import { LoaderContext } from "../../contexts";
import { authReq } from "../../utilities/requests";
import Logo from "../../assets/PeerpodLogoRed.png";
import isStrongPassword from "validator/lib/isStrongPassword";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const ResetPassword = () => {
  // get token query from url
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [token, setToken] = useState(null);
  const [newPass, setNewPass] = useState("");
  const [newPass2, setNewPass2] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { setLoader } = useContext(LoaderContext);
  useEffect(() => {
    // Use the URLSearchParams API with window.location.search
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get("token");
    if (!token) window.location = "/";
    setToken(token);
  }, []);
  const sendNewPass = () => {
    if (
      !isStrongPassword(newPass, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
        returnScore: false,
      })
    )
      return setError(
        "La contraseña debe tener al menos 8 caracteres, 1 mayúscula, 1 minúscula, 1 número y 1 símbolo."
      );
    if (newPass !== newPass2) return setError("Las contraseñas no coinciden");
    setLoader(true);

    authReq({
      url: "/reset_password",
      method: "post",
      data: { token, newPass },
    })
      .then((res) => {
        setLoader(false);
        setSuccess(true);
        setError("");
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
        setError(
          "Hubo un error al cambiar la contraseña... Intenta de nuevo más tarde"
        );
      });
  };

  if (!token) return <div>Cargando...</div>;

  return (
    <div id="reset-password-form">
      <img
        src={Logo}
        className="reset-logo"
        alt="logotipo de la empresa - Playpod"
      />
      <h1>Playpod - cambiar la contraseña</h1>

      <p>Introduzca tu contraseña nueva</p>
      <p style={{ color: "red" }}>{error}</p>

      {success && (
        <>
          <p>
            Contraseña cambiada con éxito - ahora puedes{" "}
            <span
              className="login-link"
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => {
                window.location = "/";
              }}
            >
              iniciar sesión
            </span>
          </p>
        </>
      )}

      <FormControl required={true} className="input" variant="outlined">
        <InputLabel htmlFor="passwordlabel">Contraseña nueva</InputLabel>
        <OutlinedInput
          id="pass"
          label="passwordlael"
          name="pass"
          onChange={(e) => setNewPass(e.target.value)}
          value={newPass}
          type={showPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <FormControl required={true} className="input" variant="outlined">
        <InputLabel htmlFor="confirmPassword">Confirmar contraseña</InputLabel>
        <OutlinedInput
          id="confirmPassword"
          label="Confirm Password"
          name="confirmPassword"
          onChange={(e) => setNewPass2(e.target.value)}
          value={newPass2}
          type={showPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <button onClick={sendNewPass}>Enviar</button>
    </div>
  );
};

export default ResetPassword;
