import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LoaderContext, PodcastContext, UserContext } from "../../contexts";
import AccountMenu from "../accountMenu";
import { Avatar, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import "./index.css";
import SearchIcon from "@mui/icons-material/Search";
import { authReq, sendErrorReport } from "../../utilities/requests";

import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import MessagesContainer from "../messagesContainer";
import { returnUpperCase } from "../../utilities/text";
const Navbar = () => {
  const navigate = useNavigate();
  const {
    displayButtons,
    setDisplayButtons,
    displayNotifications,
    setDisplayNotifications,
    searchRef,
    search,
    setSearch,
    isMobile,
  } = useContext(LoaderContext);

  const { user, userAvatar, isGettingAvatar, setPlan } =
    useContext(UserContext);
  const { usersPodcasts, setUserSeries, messages } = useContext(PodcastContext);
  // check if an object with type "invitation" is present in messages
  let { firstName, lastName } = user;

  const firstInitial = firstName[0];
  const lastInitial = lastName[0];
  // useEffect(() => {});
  useEffect(() => {
    authReq({ url: "/get_user_series", method: "GET" }).then((res) => {
      setUserSeries(res.data);
    });
  }, [usersPodcasts]);
  //* sets state of accountMenu buttons on navbar dismount (when user logs out)
  useEffect(() => {
    return () => {
      setDisplayButtons(false);
    };
  }, []);
  useEffect(() => {
    if (user) {
      try {
        (async function () {
          if (user) {
            let planRes = await authReq({ url: "/get_user_plan" });
            localStorage.setItem("plan", planRes?.data?.planToken);
            setPlan(planRes?.data?.plan);
          }
        })();
      } catch (error) {
        console.log(error);
        sendErrorReport("Navbar - effect", error);

        localStorage.removeItem("plan");
      }
    }
  }, [user]);
  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  return (
    <div className="navbar-container">
      <div className="search-container">
        <SearchIcon className="search-icon" />
        <input
          placeholder="Buscar por título..."
          className="search-input"
          onChange={(e) => handleChange(e)}
          onKeyUp={(e) =>
            e.key === "Enter" ? navigate(`/search/${search}`) : null
          }
          ref={searchRef}
          value={search}
        />
      </div>
      <div
        id="notifications-container"
        className={
          isMobile
            ? " mobile-notifications-container mobile-styles-hamburger"
            : ""
        }
      >
        <IconButton
          id="notifications"
          className={`close-buttons-notifications `}
          onClick={() => {
            if (isMobile) {
              setDisplayNotifications(false);
              navigate("/notificaciones");
            } else {
              setDisplayNotifications(!displayNotifications);
            }
          }}
          sx={{
            border: "1px solid #424242",
            width: 64,
            height: 64,
            fontSize: 64 / 2,
          }}
          alt={firstName}
          src={userAvatar}
        >
          {messages.length > 0 ? (
            <NotificationImportantIcon
              style={{ fontSize: "larger", color: "var(--themeRed)" }}
            />
          ) : (
            <CircleNotificationsIcon style={{ fontSize: "larger" }} />
          )}
        </IconButton>

        <MessagesContainer
          displayNotifications={displayNotifications}
          setDisplayNotifications={setDisplayNotifications}
        />
      </div>
      <div
        id="account-container"
        className={isMobile ? "mobile-styles-hamburger" : ""}
      >
        <Avatar
          id="user-img"
          className="themeTeal"
          onClick={() => {
            setDisplayButtons(!displayButtons);
          }}
          sx={{
            width: 64,
            height: 64,
            fontSize: 64 / 2,
          }}
          alt={firstName}
          src={userAvatar}
        >
          {returnUpperCase(firstInitial, lastInitial)}
        </Avatar>
        <MenuIcon
          id="hamburger"
          className={isMobile ? "hamburger-mobile" : ""}
          onClick={() => {
            setDisplayButtons(!displayButtons);
          }}
          fontSize="medium"
        />
        <AccountMenu
          displayButtons={displayButtons}
          setDisplayButtons={setDisplayButtons}
        />
      </div>
    </div>
  );
};
export default Navbar;
