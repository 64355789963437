import React, { useContext, useEffect, useRef, useState } from "react";
import { AudioContext, LoaderContext } from "../../contexts";
import "./index.css";
import { useNavigate } from "react-router-dom";
import ReactAudioPlayer from "react-audio-player";
import podcastImage from "../../assets/earbud.png";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { authReq } from "../../utilities/requests";
import CloseIcon from "@mui/icons-material/Close";

import axios from "axios";

const AudioPlayer = () => {
  const navigate = useNavigate();
  const { currentAudio, setCurrentAudio, setPlayer } = useContext(AudioContext);

  const [showText, setShowText] = useState(false);
  const [transcription, setTranscription] = useState("");
  const [transcriptionJobName, setTranscriptionJobName] = useState(null);
  const [isFullText, setIsFullText] = useState(false);

  const [currentTime, setCurrentTime] = useState(0);
  const [spokenWords, setSpokenWords] = useState([]);
  const [fullWords, setFullWords] = useState(null);
  const { isMobile } = useContext(LoaderContext);

  useEffect(() => {
    const audioElement = document.querySelector(".player");
    if (!currentAudio) return;
    const timeUpdateHandler = () => {
      setCurrentTime(audioElement.currentTime);
    };

    audioElement.addEventListener("timeupdate", timeUpdateHandler);

    return () => {
      audioElement.removeEventListener("timeupdate", timeUpdateHandler);
    };
  }, [currentAudio]);

  useEffect(() => {
    if (!currentAudio) return;

    if (
      currentAudio?.transcriptData &&
      typeof currentAudio.transcriptData == "object"
    ) {
      const timeWords = currentAudio.transcriptData
        .filter((item) => {
          return (
            item.type === "pronunciation" &&
            parseFloat(item.start_time) <= currentTime
          );
        })
        .map((item) => item.alternatives[0].content);

      const allWords = currentAudio.transcriptData
        .filter((item) => {
          return item.type === "pronunciation";
        })
        .map((item) => {
          if (parseFloat(item.start_time) <= currentTime) {
            return (
              <span style={{ backgroundColor: "lightyellow" }}>
                {item.alternatives[0].content}{" "}
              </span>
            );
          } else {
            return <span>{item.alternatives[0].content} </span>;
          }
        });

      setSpokenWords(timeWords);
      setFullWords(allWords);
    }
  }, [currentTime, currentAudio]);

  useEffect(() => {
    let selectedPlayer = document.querySelector(".player");
    setPlayer(selectedPlayer);
  }, []);

  useEffect(() => {
    let selectedPlayer = document.querySelector(".player");

    if (currentAudio?.playing) {
      selectedPlayer?.play();
    } else if (currentAudio && !currentAudio.playing) {
      selectedPlayer?.pause();
    }
  }, [currentAudio]);

  const handleToggle = () => {
    setShowText(!showText);
  };

  const handleClick = () => {
    navigate(`/podcast/${currentAudio.id}`);
    setShowText(false);
  };

  const startY = useRef(null);

  const handleTouchStart = (e) => {
    startY.current = e.touches[0].clientY;
  };

  const handleTouchMove = (e) => {
    if (!startY.current) {
      return;
    }

    const touchY = e.touches[0].clientY;
    const deltaY = startY.current - touchY;

    if (Math.abs(deltaY) < 50) {
      // Threshold for minimal swipe distance
      return;
    }

    if (deltaY > 0) {
      setShowText(true);
    } else {
      setShowText(false);
    }

    startY.current = null; // Reset startY
  };

  const handleClose = () => {
    setCurrentAudio(null);
    setShowText(false);
  };

  let playerJSX = (
    <div className={`player-inner-container `}>
      <div className="audio-container-left" onClick={handleClick}>
        <img src={podcastImage} alt="podcast banner" />
        <div>
          {/* <div className="audio-title">{currentAudio?.title}</div>
          <div id="colon" className="audio-title">:</div> */}
          <div className="audio-title">
            {currentAudio?.title +
              " por " +
              currentAudio?.owner?.firstName +
              " " +
              currentAudio?.owner?.lastName}
          </div>
        </div>
      </div>
      <ReactAudioPlayer
        src={`/audio_file/${currentAudio?.id}`}
        controls
        controlsList="nodownload"
        className="player"
        onPlay={() => {
          setCurrentAudio({ ...currentAudio, playing: true });
        }}
        onPause={() => {
          setCurrentAudio({ ...currentAudio, playing: false });
        }}
        onTouchStart={() => {}} // Added this line
      />
      <div className="audio-container-right">
        {/* <IconButton aria-label="share" onClick={(e) => {}}>
          {true ? <FavoriteBorder /> : <Favorite />}
        </IconButton> */}
        {/* <IconButton aria-label="share" onClick={(e) => {}}>
                    <Share />
                </IconButton> */}
      </div>
      <div className="audio-toggle">
        {showText ? (
          <ExpandMore className="expand-icon" onClick={handleToggle} />
        ) : (
          <ExpandLess className="expand-icon" onClick={handleToggle} />
        )}
        <CloseIcon id="audio-close" onClick={handleClose} />
      </div>
    </div>
  );
  if (!currentAudio) return <></>;
  return (
    <div
      className={`player-container ${
        isMobile ? "mobile-player" : "normal-player"
      } ${showText ? "show-transcript" : ""}`}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      {currentAudio && playerJSX}
      <div className="audio-text">
        <div>
          <div className="mb-4">
            <button
              className={isFullText ? "Published" : ""}
              onClick={() => setIsFullText(true)}
            >
              Texto completo
            </button>
            <button
              className={!isFullText ? "Published" : ""}
              onClick={() => setIsFullText(false)}
            >
              Transcripción en vivo
            </button>
          </div>
          {currentAudio?.transcriptData
            ? isFullText
              ? fullWords // Display full text if isFullText is true
              : spokenWords.join(" ") // Display spoken words if isFullText is false
            : "La transcripción de este podcast aún no ha sido procesada."}{" "}
          {/* Initially, it will show "Transcribing..." until the transcription is completed. */}
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;
