import React, { useState, createContext } from 'react';

export const BreadcrumbContext = createContext();

export function BreadcrumbContextProvider(props) {
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  return (
    <BreadcrumbContext.Provider
      value={{
        breadcrumbs,
        setBreadcrumbs,
      }}
    >
      {props.children}
    </BreadcrumbContext.Provider>
  );
}
