import { useState, useContext } from "react";
import { LoaderContext, UserContext } from "../../../contexts";
import Title from "../Title";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { authReq } from "../../../utilities/requests";

const Password = ({ page, setPage }) => {
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const handleSubmit = (e) => {
    authReq({ url: "/request_pass_reset", method: "post", data: { email } })
      .then((res) => {
        setSuccess("Correo electrónico enviado - revisa tu bandeja de entrada");
        setError("");
      })
      .catch((err) => {
        console.log(err);
        setError(
          "Correo electrónico no encontrado o hubo un error - puedes contactar a tech@playpod.education"
        );
        setSuccess("");
      });
  };

  return (
    <div className="mini-form-container">
      <Grid
        className="grid-container"
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item className="inner-container">
          <Title page={page} setPage={setPage} />
          <div className="session-msg">
            Entra tu correo electrónico para restablecer tu contraseña.
          </div>
          {success && <div style={{ color: "green" }}>{success}</div>}
          {error && <div style={{ color: "red" }}>{error}</div>}
          <div className="inputs-container" elevation={3}>
            <FormControl required={true} className="input" variant="outlined">
              <InputLabel htmlFor="email">Correo electrónico</InputLabel>
              <OutlinedInput
                id="email"
                label="Correo electrónico"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                type="text"
              />
            </FormControl>

            <Button
              disabled={success.length > 0}
              variant="contained"
              id="auth-button"
              onClick={handleSubmit}
            >
              Enviar
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Password;
