import React, { useState, createContext, useRef, useEffect } from "react";
import { isMobile } from "react-device-detect";

export const LoaderContext = createContext();

export function LoaderContextProvider(props) {
  const [loader, setLoader] = useState(false);
  const [displayButtons, setDisplayButtons] = useState(false);
  const [displayNotifications, setDisplayNotifications] = useState(false);
  const [showCookies, setShowCookies] = useState(false);
  const [flashStudioAndClass, setFlashStudioAndClass] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const [messageState, setMessageState] = useState({ text: "", time: 3000 });
  // const isMobile = window.matchMedia("(max-width: 768px)").matches;
  let [search, setSearch] = useState("");
  let linkVisited = localStorage.getItem("linkVisited");
  const languages = [
    { code: "es-ES", language: "Español" },
    { code: "ca-ES", language: "Català" },
    { code: "eu-ES", language: "Euskara" },
    { code: "en-US", language: "English" },
  ];

  const searchRef = useRef(null);

  const handleSearchClick = () => {
    if (searchRef.current) {
      searchRef.current.focus();
    }
  };
  // let isMobile = true;
  const handleCookieLinkClick = () => {
    setShowCookies((prev) => !prev);
    localStorage.setItem("linkVisited", "true");
  };

  useEffect(() => {
    if (flashStudioAndClass) {
      setTimeout(() => {
        setFlashStudioAndClass(false);
      }, 15000);
    }
  }, [flashStudioAndClass]);

  return (
    <LoaderContext.Provider
      value={{
        loader,
        setLoader,
        handleCookieLinkClick,
        linkVisited,
        displayButtons,
        setDisplayButtons,
        displayNotifications,
        setDisplayNotifications,
        messageState,
        width,
        setWidth,
        setMessageState,
        searchRef,
        handleSearchClick,
        isMobile,
        search,
        setSearch,
        showCookies,
        setShowCookies,
        flashStudioAndClass,
        setFlashStudioAndClass,
        languages,
      }}
    >
      {props.children}
    </LoaderContext.Provider>
  );
}
