import { v4 as uuidv4 } from "uuid";
import React, { useState, useEffect, useContext } from "react";
import "./index.css";
import { authReq, sendErrorReport } from "../../utilities/requests";
import { LoaderContext } from "../../contexts/loaderContext";
import { UserContext } from "../../contexts/userContext";
import { TextField, IconButton, Button, Switch } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Responses from "../responses";
import { Add } from "@mui/icons-material";
import moment from "moment";

const Quiz = ({
  podcast,
  editQuestions,
  questions,
  setQuestions,
  setEditQuestions,
  types,
  questionTemplate,
  showQuestions,
  setAreUnsavedChanges,
  areUnsavedChanges,
  showResponses,
  setShowResponses,
  responses,
  setResponses,
  responsesRef,
}) => {
  const { setLoader, setMessageState } = useContext(LoaderContext);
  const { user } = useContext(UserContext);
  const [error, setError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [showSure, setShowSure] = useState(false);
  const [saved, setSaved] = useState(podcast.quizAnswers);
  const [showDelete, setShowDelete] = useState(false);
  const [published, setPublished] = useState(podcast.quizPublished);
  const [alreadySubmitted, setAlreadySubmitted] = useState(false);
  const [studentAnswers, setStudentAnswers] = useState([]);
  const [indexOfEmptyAnswer, setIndexOfEmptyAnswer] = useState(null);
  const [canRetake, setCanRetake] = useState(false);
  const [timeSubmitted, setTimeSubmitted] = useState(null);

  let questionNumber = 0;
  let makeRequest = async () => {
    if (questions[0].content !== "") return setLoader(false);
    if (!(user.classCode === podcast.owner.classCode)) return setLoader(false);
    try {
      authReq({
        url: `/get_quiz/${podcast.id}/${podcast.owner.classCode}`,
        method: "GET",
      }).then((res) => {
        let dataInLocal = localStorage.getItem(podcast.id);
        let dbData = res.data.quiz;

        if (!dbData.quiz) {
          if (!dataInLocal) return setLoader(false);
          // setQuestions(JSON.parse(dataInLocal));
          //  setError("Este cuestionario nunca se guardó. Termine, guarde y publique el cuestionario.")
          setLoader(false);
          return;
        }
        // setSubmitted(true);
        setLoader(false);
        let quiz = JSON.parse(dbData.quiz);
        if (!quiz) return;
        let { quizAnswers } = res.data.quiz;
        let { userAnswers } = res.data;
        let { timeSubmitted } = res.data;
        setTimeSubmitted(timeSubmitted);
        quizAnswers = quizAnswers ? JSON.parse(quizAnswers) : null;
        userAnswers = userAnswers ? JSON.parse(userAnswers) : null;
        if (userAnswers) setAlreadySubmitted(true);
        let dbQuestions = quiz.map((question, index) => {
          let questionWithAnswers = {
            ...question,
            selectedAnswer: quizAnswers ? quizAnswers[index] : null,
            checkboxValues: question.answers.map((ans, i) =>
              quizAnswers
                ? i === quizAnswers[index]
                : userAnswers
                ? i === userAnswers[index]
                : false
            ),
          };
          return questionWithAnswers;
        });
        setQuestions(dbQuestions);
        if (res.data.canRetake === false) {
          setCanRetake(false);
        } else {
          setCanRetake(true);
        }
        setLoader(false);
      });
    } catch (error) {
      console.log(error);
      sendErrorReport("Quiz - makeRequest", error);

      setLoader(false);
      setError("No tienes permiso para ver este cuestionario.");
    }
  };

  useEffect(() => {
    // go get test info - ! AUTH because only teachers can do it
    if (
      !user.classCode === podcast.owner.classCode ||
      questions[0].content !== ""
    )
      return;
    setLoader(true);
    makeRequest();
  }, []);

  const setStorageAndState = (newState) => {
    localStorage.setItem(podcast.id, JSON.stringify(newState));
    setQuestions(newState);
    if (editQuestions) {
      setAreUnsavedChanges(true);
    }
  };

  // Function to handle changes in the inputs for creating a new question
  const handleNewInputChange = (e, questionIndex) => {
    let questionsCopy = [...questions];
    if (e.target.name === "type" && e.target.value === "Verdadera / Falsa") {
      questionsCopy[questionIndex] = {
        ...questionsCopy[questionIndex],
        [e.target.name]: e.target.value,
        answers: ["verdadera", "falsa"],
        checkboxValues: [false, false],
      };
    } else if (
      e.target.name === "type" &&
      e.target.value === "Opción multiple"
    ) {
      questionsCopy[questionIndex][e.target.name] = e.target.value;
      questionsCopy[questionIndex].answers = [""];
      questionsCopy[questionIndex].checkboxValues = [false];
    } else {
      questionsCopy[questionIndex] = {
        ...questionsCopy[questionIndex],
        [e.target.name]: e.target.value,
      };
    }
    setStorageAndState(questionsCopy);
  };

  // Function to handle changes in the multiple-choice answers for the new question
  const handleNewAnswerChange = (questionIndex, index, value, isTrueFalse) => {
    if (isTrueFalse) return;
    const updatedAnswers = [...questions[questionIndex].answers];
    updatedAnswers[index] = value;
    let questionsCopy = [...questions];
    questionsCopy[questionIndex] = {
      ...questionsCopy[questionIndex],
      answers: updatedAnswers,
    };
    setStorageAndState(questionsCopy);
  };

  // Function to add a new answer field for multiple-choice questions
  const addAnswerField = (questionIndex) => {
    let newQ = questions[questionIndex];
    let questionsCopy = [...questions];
    questionsCopy[questionIndex] = {
      ...questions[questionIndex],
      answers: [...newQ.answers, ""],
      checkboxValues: [...newQ.checkboxValues, false],
    };

    setStorageAndState(questionsCopy);
  };

  // Function to add a new question to the questions array
  const addNewQuestion = () => {
    let newQ = questions[questions.length - 1];
    if (!newQ.content || newQ.answers.some((answer) => answer.trim() === "")) {
      alert(
        "Por favor, rellene todos los campos antes de crear una nueva pregunta."
      );
      return;
    }
    setStorageAndState([...questions, questionTemplate]);
  };
  const handleDelete = async (e) => {
    //* MODAL DOUBLE CHECK! (cannot be undone!)
    let response = await authReq({
      method: "DELETE",
      url: `/delete_quiz/${podcast.id}`,
    });
    if (response.data === "success" || response.status === 200) {
      // deletion success
      setShowDelete(false);
    }
  };
  const handleModalSubmit = (e) => {
    e.preventDefault();
    handleDelete();
    setShowDelete(false);
  };
  const handleCheckboxChange = (questionIndex, checkboxIndex) => {
    let newselectedAnswer;
    let correctnessCopy = questions[questionIndex].checkboxValues.map(
      (boolean, indexOf) => {
        if (indexOf === checkboxIndex) {
          newselectedAnswer = indexOf;
          return true;
        } else {
          return false;
        }
      }
    );

    let questionsCopy = [...questions];
    questionsCopy[questionIndex] = {
      ...questions[questionIndex],
      checkboxValues: correctnessCopy,
      selectedAnswer: newselectedAnswer,
    };
    setStorageAndState(questionsCopy);
  };

  const deleteQuiz = () => {
    authReq({
      url: "/delete_quiz",
      method: "DELETE",
      data: {
        podcast,
      },
    }).then((res) => {
      if (res.status === 200) {
        setStorageAndState([questionTemplate]);
        setShowDelete(false);
        setPublished(false);
        setSaved(false);
        setSubmitted(false);
      }
    });
  };
  const save = (shouldPublish) => {
    // const everyObjectHasAnswer = questions.every(
    //   (obj) => typeof obj.selectedAnswer === "number"
    //   // obj.checkboxValues.some(val => val === true)
    // );
    const indexOfEmptyAnswer = questions.findIndex(
      (obj) => typeof obj.selectedAnswer !== "number"
    );

    // if (indexOfEmptyAnswer !== -1) {
    //   setIndexOfEmptyAnswer(indexOfEmptyAnswer);
    //   return setError("todas las preguntas deben tener una respuesta");
    // }
    try {
      authReq({
        url: editQuestions
          ? `/save_or_publish_quiz/${shouldPublish}`
          : "/submit_quiz",
        method: "POST",
        data: {
          questions,
          podcast,
        },
      }).then((res) => {
        if (!editQuestions) {
          setSubmitted(true);
          setShowSure(false);
        } else {
          setSaved(true);
          setMessageState({
            text: shouldPublish
              ? "¡Enhorabuena 🎉 Has guardado tu revisión de apredizaje."
              : "¡Enhorabuena 🎉 Has guardado tu revisión de apredizaje.",
            time: 6000,
          });
        }
        setAreUnsavedChanges(false);
        setPublished(shouldPublish);

        if (!editQuestions) setAlreadySubmitted(true);

        let date = new Date(Date.now()).toLocaleString();
        let msg = editQuestions
          ? `Guardado a las ${date}`
          : `Entregado a las ${date}`;
        setSuccessMsg(msg);
        setError("");
      });
    } catch (error) {
      console.log(error);
      sendErrorReport("Quiz - Save", error);

      setError("Algo salió mal, vuelva a intentarlo más tarde");
    }
  };

  const getResponses = () => {
    authReq({
      url: `/get_quiz_responses/${podcast.id}`,
      method: "GET",
    }).then((res) => {
      if (res.status === 200) {
        let { quizResponses } = res.data;
        quizResponses = JSON.parse(quizResponses);
        let questionsCopy = [...questions];
        questionsCopy = questionsCopy.map((question, index) => {
          let questionWithAnswers = {
            ...question,
            selectedAnswer: quizResponses[index],
            checkboxValues: question.answers.map((ans, i) =>
              i === quizResponses[index] ? true : false
            ),
          };
          return questionWithAnswers;
        });
        setQuestions(questionsCopy);
      }
    });
  };

  const deleteQuestion = (indexToDelete) => {
    // replace in state array with null
    let questionsCopy = questions.map((q, qIndex) =>
      qIndex === indexToDelete ? null : q
    );
    setQuestions(questionsCopy);
    setAreUnsavedChanges(true);
  };
  if (
    showQuestions &&
    questions[0].content === "" &&
    !editQuestions &&
    !user.isClassAdmin
  )
    return (
      <div className="no-questions">
        <h3>No hay cuestionario para este podcast</h3>
      </div>
    );
  return (
    <div className="quiz-container">
      {/* Existing questions */}

      {/* Form for new question */}
      <div className="inner-quiz-container">
        <div className="feedback-container">
          {user.isClassAdmin && user.classCode === podcast.owner.classCode && (
            <div
              className="buttons center-buttons switch-container"
              style={{ marginBottom: "12px" }}
            >
              Editar
              <Switch
                disabled={!user.isClassAdmin}
                checked={editQuestions}
                onChange={(e) => setEditQuestions(e.target.checked)}
              />
            </div>
          )}

          {successMsg && <div style={{ color: "green" }}>{successMsg}</div>}
        </div>

        {error && <div style={{ color: "red" }}>{error}</div>}
        {showResponses && (
          <Responses
            showResponses={showResponses}
            setShowResponses={setShowResponses}
            responses={responses}
            setResponses={setResponses}
            responsesRef={responsesRef}
            podcast={podcast}
            studentAnswers={studentAnswers}
            setStudentAnswers={setStudentAnswers}
          />
        )}
        {timeSubmitted && (
          <div className="time-submitted">
            <div>Ya entregado a las {moment(timeSubmitted).format("LLLL")}</div>
          </div>
        )}
        {questions.map((newQ, qIndex) => {
          if (newQ !== null) questionNumber++;
          return newQ === null ? null : (
            <React.Fragment key={newQ.id || Date.now()}>
              <div className="question-header">
                <h3>Pregunta {questionNumber}</h3>
                {editQuestions && (
                  <Button
                    onClick={() =>
                      handleNewInputChange(
                        { target: { name: "type", value: types[0] } },
                        qIndex
                      )
                    }
                    className={`podcast-button ${
                      newQ.type === types[0] && "active-button"
                    }`}
                  >
                    Verdadera / Falsa
                  </Button>
                )}
                {editQuestions && (
                  <Button
                    onClick={() =>
                      handleNewInputChange(
                        { target: { name: "type", value: types[1] } },
                        qIndex
                      )
                    }
                    className={`podcast-button ${
                      newQ.type === types[1] && "active-button"
                    }`}
                  >
                    Opción multiple
                  </Button>
                )}
                {editQuestions && qIndex !== 0 ? (
                  <IconButton
                    className="modal-close-button"
                    onClick={() => deleteQuestion(qIndex)}
                  >
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <div style={{ marginRight: "auto" }}></div>
                )}
              </div>
              {/* {editQuestions && (
                <TextField
                  select
                  className="type-select"
                  SelectProps={{
                    native: true,
                  }}
                  name="type"
                  label="tipo de pregunta"
                  variant="standard"
                  value={newQ.type}
                  InputProps={{ readOnly: !editQuestions }}
                  onChange={(e) => handleNewInputChange(e, qIndex)}
                >
                  <option value={types[0]}>{types[0]}</option>
                  <option value={types[1]}>{types[1]}</option>
                </TextField>
              )} */}
              {editQuestions ? (
                <TextField
                  id="outlined-multiline-flexible"
                  className="question-field"
                  multiline
                  // variant="contained"
                  variant="standard"
                  label="Escribe tu pregunta aquí"
                  name="content"
                  value={newQ.content}
                  onChange={(e) => handleNewInputChange(e, qIndex)}
                  maxRows={4}
                />
              ) : (
                // <TextField
                //   required
                //   className="quiz-field"
                //   name="content"
                //   label={`Question ${qIndex + 1} `}
                //   variant="standard"
                //   value={newQ.content}
                //   InputProps={{ readOnly: !editQuestions }}
                //   onChange={(e) => handleNewInputChange(e, qIndex)}
                //   // multiline={newQ.type === "multiple-choice"}
                // />
                <div className="quiz-question">{newQ.content}</div>
              )}
              {qIndex === indexOfEmptyAnswer && (
                <div style={{ color: "red" }}>Selecciona una respuesta</div>
              )}
              <div className="answers-grid">
                {newQ.answers.map((answer, index) => (
                  <React.Fragment key={index}>
                    <input
                      type="checkbox"
                      className="answer-checkbox"
                      checked={newQ.checkboxValues[index]}
                      style={{ borderColor: "red" }}
                      title={alreadySubmitted ? "Ya entregado" : ""}
                      onChange={() =>
                        canRetake
                          ? handleCheckboxChange(qIndex, index)
                          : alert("No puedes volver a tomar este cuestionario")
                      }
                    />
                    {editQuestions && newQ.type === types[1] ? (
                      <TextField
                        key={index}
                        required
                        // label={`Answer ${index + 1}`}
                        variant="standard"
                        value={answer}
                        InputProps={{
                          readOnly: newQ.type === types[0] || !editQuestions,
                        }}
                        onChange={(e) =>
                          handleNewAnswerChange(
                            qIndex,
                            index,
                            e.target.value,
                            newQ.type === types[0]
                          )
                        }
                      />
                    ) : (
                      <div>{answer}</div>
                    )}
                  </React.Fragment>
                ))}
                {newQ.type !== types[0] && editQuestions && (
                  <IconButton>
                    <Add onClick={() => addAnswerField(qIndex)} />
                  </IconButton>
                  // <Button
                  //   onClick={() => addAnswerField(qIndex)}
                  //   variant="contained"
                  // >
                  //   agregar respuesta
                  // </Button>
                )}
              </div>
            </React.Fragment>
          );
        })}
        <br></br>

        <div className="buttons">
          {editQuestions && (
            <Button onClick={addNewQuestion} className="podcast-button">
              Agregar pregunta
            </Button>
          )}
          {/* {!editQuestions && canRetake && ( */}
          {!editQuestions && canRetake && (
            <div title={alreadySubmitted ? "Ya entregado" : ""}>
              <Button
                onClick={() => setShowSure(true)}
                className="podcast-button enviar-button"
              >
                Enviar Respuestas
              </Button>
            </div>
          )}
          {editQuestions && (
            <Button
              onClick={() => save("false")}
              className="podcast-button teal-button"
            >
              Guardar Sin Publicar
            </Button>
          )}

          <br></br>
          {editQuestions ? (
            <Button
              onClick={() => save("true")}
              className="podcast-button  teal-button"
            >
              Publicar
            </Button>
          ) : (
            <></>
          )}
        </div>
        <br></br>
        <br></br>
        <br></br>
        {editQuestions && (
          <Button
            onClick={() => setShowDelete(true)}
            type="submit"
            variant="contained"
            color="errorRed"
            sx={{ fontWeight: "bold", mt: 1 }}
            fullWidth
            aria-label="delete account button"
          >
            Eliminar Cuestionario
          </Button>
        )}
      </div>

      <Modal
        open={showDelete}
        onClose={() => setShowDelete(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-box">
          <IconButton
            className="modal-close-button"
            onClick={() => setShowDelete(false)}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            ADVERTENCIA: ¡Estás eliminando este cuestionario!
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            El cuestionario de {podcast.title} será eliminado permanentemente.
            esto no se puede deshacer
          </Typography>

          <form onSubmit={handleModalSubmit}>
            <div
            //   className={`error ${
            //     deleteError ? 'display-block' : ''
            //   }`}
            >
              {/* Error: debes escribir: <b>eliminar podcast</b> */}
            </div>
            {/* <input
              type="text"
              value={deleteValue}
              onChange={(e) => {
                setDeleteError(false);
                setDeleteValue(e.target.value);
              }}
            /> */}
            <Button
              onClick={() => deleteQuiz()}
              type="submit"
              variant="contained"
              color="errorRed"
              sx={{ fontWeight: "bold", mt: 1 }}
              fullWidth
              aria-label="delete account button"
            >
              Eliminar Cuestionario
            </Button>
          </form>
        </Box>
      </Modal>
      <Modal
        open={showSure}
        onClose={() => setShowSure(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-box">
          <IconButton
            className="modal-close-button"
            onClick={() => setShowSure(false)}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Solo puede enviar una vez. ¿Estás seguro de que quieres enviar estas
            respuestas?
          </Typography>

          <div
          //   className={`error ${
          //     deleteError ? 'display-block' : ''
          //   }`}
          >
            {/* Error: debes escribir: <b>eliminar podcast</b> */}
          </div>
          {/* <input
              type="text"
              value={deleteValue}
              onChange={(e) => {
                setDeleteError(false);
                setDeleteValue(e.target.value);
              }}
            /> */}
          <Button
            type="submit"
            onClick={() => save(false)}
            className="podcast-button active-button"
            color="errorRed"
            sx={{ fontWeight: "bold", mt: 1, maxWidth: "200px" }}
            fullWidth
            aria-label="Submit Quiz Button"
          >
            Enviar
          </Button>
        </Box>
      </Modal>
    </div>
  );
};
export default Quiz;
