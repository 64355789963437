import { Box, IconButton, Modal, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { LoaderContext } from "../../contexts";
import CloseIcon from "@mui/icons-material/Close";

const MessageModal = () => {
  const { messageState, setMessageState } = useContext(LoaderContext);
  useEffect(() => {
    if (messageState.text.length > 0) {
      setTimeout(() => {
        setMessageState({ text: "", time: 3000 });
      }, messageState.time);
    }
  }, [messageState]);

  return (
    <div>
      {" "}
      <Modal
        open={!!messageState.text}
        onClose={() => setMessageState({ text: "", time: 3000 })}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="payment-modal"
      >
        <Box className="modal-box">
          <IconButton
            className="modal-close-button"
            onClick={() => setMessageState({ text: "", time: 3000 })}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="payment-desc"
          >
            <>{messageState.text}</>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default MessageModal;
