import { useContext, useEffect, useState } from "react";
import { Box, Breadcrumbs, Link, Typography } from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { BreadcrumbContext } from "../../contexts/breadcrumbContext";
import { PodcastContext } from "../../contexts/podcastContext";
import { UserContext } from "../../contexts";
import "./index.css";

function LinkRouter(props) {
  return <Link {...props} component={RouterLink} />;
}

export default function DynamicBreadcrumbs() {
  let { podcastCache } = useContext(PodcastContext);
  let { user } = useContext(UserContext);
  let { breadcrumbs, setBreadcrumbs } = useContext(BreadcrumbContext);

  const extractPathBeforeQuery = (url) => {
    let urlNoQuery = url.split("/?")[0];
    if (urlNoQuery.length <= 1) {
      return urlNoQuery;
    } else {
      return urlNoQuery.endsWith("/") ? urlNoQuery.slice(0, -1) : urlNoQuery;
    }
  };

  const [pathnames, setPathnames] = useState(
    extractPathBeforeQuery(window.location.pathname)
      .split("/")
      .slice(1)
      .map((p) => decodeURIComponent(p))
  );

  let location = useLocation();

  useEffect(() => {
    let currentPaths = extractPathBeforeQuery(location.pathname)
      .split("/")
      .slice(1)
      .map((p) => decodeURIComponent(p));

    if (pathnames.join("/") !== currentPaths.join("/")) {
      setPathnames(currentPaths);
    }
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    let breadcrumbsArray = [
      {
        text: "Panel",
        link: "/",
      },
    ];

    for (let index = 0; index < pathnames.length; index++) {
      const path = pathnames[index];

      if (path === "perfil") {
        breadcrumbsArray.push({
          text: "perfil",
          link: `/${pathnames.slice(0, index + 2).join("/")}`,
        });
        // Skip the next item (the actual ID) in the pathnames
        index++;
      } else if (path === "podcast") {
        breadcrumbsArray.push({
          text: "podcast",
          link: `/${pathnames.slice(0, index + 2).join("/")}`,
        });
        // Skip the next item (the actual podcastId) in the pathnames
        index++;
      } else {
        breadcrumbsArray.push({
          text: `${path}`,
          link: `/${pathnames.slice(0, index + 2).join("/")}`,
        });
      }
    }

    podcastCache?.random.map((podcast) => {
      let lastCrumb = pathnames[pathnames.length - 1];
      if (podcast.id === lastCrumb) {
        lastCrumb = podcast.title;
        breadcrumbsArray[breadcrumbsArray.length - 1].text = lastCrumb;
      }
    });

    let lastCrumb = pathnames[pathnames.length - 1];
    if (user.id === lastCrumb) {
      lastCrumb = user.firstName + " " + user.lastName;
      breadcrumbsArray[breadcrumbsArray.length - 1].text = lastCrumb;
    }

    setBreadcrumbs(breadcrumbsArray);
  }, [
    pathnames,
    setBreadcrumbs,
    podcastCache,
    user.id,
    user.firstName,
    user.lastName,
  ]);

  return (
    <Box p={1}>
      <Breadcrumbs
        className="mb-4"
        aria-label="breadcrumb"
        separator={<NavigateNext fontSize="small" />}
      >
        {pathnames[0].length === 0 ? (
          <Typography> Panel </Typography>
        ) : (
          breadcrumbs &&
          breadcrumbs.map((b, index) => {
            const last = index === pathnames.length;
            const to = `/${pathnames.slice(0, index + 1).join("/")}`;
            return last ? (
              <Typography key={to}>
                {b.text.charAt(0).toUpperCase() + b.text.slice(1)}
              </Typography>
            ) : (
              <LinkRouter underline="hover" to={b.link} key={to}>
                {b.text.charAt(0).toUpperCase() + b.text.slice(1)}
              </LinkRouter>
            );
          })
        )}
      </Breadcrumbs>
    </Box>
  );
}
