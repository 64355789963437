import React, { useEffect, useState } from "react";
import "./index.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { authReq } from "../../utilities/requests";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ClearIcon from "@mui/icons-material/Clear";
import { Link } from "react-router-dom";

const Responses = ({
  responses,
  setResponses,
  responsesRef,
  podcast,
  studentAnswers,
  setStudentAnswers,
}) => {
  const [pending, setPending] = useState(true);

  let quiz = JSON.parse(podcast.quiz);
  useEffect(() => {
    if (studentAnswers.length) return;
    authReq({ url: "/get_grades", method: "POST", data: podcast }).then(
      (res) => {
        setPending(false);
        if (res.data.length) {
          setStudentAnswers(res.data);
        }
      }
    );
  }, []);

  function countMatchingPositions(array1, array2) {
    // Find the length of the shorter array to avoid out-of-range errors
    var length = Math.min(array1.length, array2.length);

    // Initialize a counter for matches
    var matchingCount = 0;

    // Iterate through both arrays up to the length of the shorter one
    for (var i = 0; i < length; i++) {
      if (array1[i] === array2[i]) {
        // Increment the counter if elements at the same position match
        matchingCount++;
      }
    }

    return matchingCount;
  }

  if (!studentAnswers.length)
    return (
      <div>
        <strong>No hay resultados</strong>
      </div>
    );
  return (
    <div id="responses" ref={responsesRef}>
      <br></br>
      <br></br>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Estudiante</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Por ciento</TableCell>
              {quiz.map((question, index) => (
                <TableCell key={index} align="right">
                  Pregunta {index + 1}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="review-table">
            {studentAnswers.map((row, index) => {
              let studentAnswers = JSON.parse(row.answers);
              let quizAnswers = JSON.parse(row.quizAnswers);
              let correctNumber = countMatchingPositions(
                studentAnswers,
                quizAnswers
              );
              return (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Link
                      to={`/perfil/${row.id}/?clickedUser=${JSON.stringify(
                        row
                      )}`}
                    >
                      {row.firstName + " " + row.lastName}
                    </Link>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {correctNumber + "/" + quizAnswers.length}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {((correctNumber / quizAnswers.length) * 100).toFixed(1) +
                      "%"}
                  </TableCell>
                  {quizAnswers.map((answer, index) => (
                    <TableCell key={index} align="right">
                      {studentAnswers[index] === quizAnswers[index] ? (
                        <CheckBoxIcon checked color="success" />
                      ) : (
                        <ClearIcon />
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Responses;
