import { useState, useEffect, useContext, useRef } from "react";
import { LoaderContext, UserContext } from "../../../contexts";
import Title from "../Title";
import "./index.css";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  OutlinedInput,
  Typography,
} from "@mui/material";
import axios from "axios";
import { Close, Visibility, VisibilityOff } from "@mui/icons-material";
import { Select, MenuItem, FormHelperText } from "@mui/material";

import isEmail from "validator/lib/isEmail";
import {
  authReq,
  generateAlphanumericCode,
  sendErrorReport,
} from "../../../utilities/requests";
import isStrongPassword from "validator/lib/isStrongPassword";
import { set } from "lodash";

const Register = ({ page, setPage }) => {
  const { setLoader } = useContext(LoaderContext);
  const { setUser, setToken } = useContext(UserContext);

  const [isRegisterDisabled, setIsRegisterDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [joinPassword, setJoinPassword] = useState("");
  const [isMinor, setIsMinor] = useState(null);
  const [parentModal, setParentModal] = useState(false);
  const [modalChoiceIsTeacher, setModalChoiceIsTeacher] = useState(true);
  const [parentConfirm, setParentConfirm] = useState(false);
  const [schoolLevel, setSchoolLevel] = useState(null);
  const startingErrors = {
    noFirstName: null,
    shortFirstName: null,
    noLastName: null,
    shortLastName: null,
    noEmail: null,
    invalidEmail: null,
    noPassword: null,
    invalidPassword: null,
    noConfirmPassword: null,
    notMatchingPasswords: null,
    accountAlreadyExists: null,
    loginFailed: null,
    noAcceptedTerms: null,
    noAcceptedPrivacyPolicy: null,
    noJoinPassword: null,
    incorrectClassCode: null,
  };
  const [errors, setErrors] = useState(startingErrors);
  useEffect(() => {
    setErrors(startingErrors);
  }, [isMinor]);
  console.log(errors);
  const startingUserInfo = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    accountType: "",
    allContent: null,
    acceptedTerms: false,
    acceptedPrivacyPolicy: false,
    parentMadeAccount: null,
  };
  let [userInfo, setUserInfo] = useState({ ...startingUserInfo });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    setIsRegisterDisabled(
      !userInfo.firstName ||
        !userInfo.lastName ||
        !userInfo.email ||
        !userInfo.password ||
        !userInfo.confirmPassword ||
        !userInfo.accountType ||
        !userInfo.acceptedTerms ||
        !userInfo.acceptedPrivacyPolicy ||
        (userInfo.allContent === null && !userInfo.parentMadeAccount)
    );
  }, [userInfo]);

  const handleParentModalClose = () => {
    setParentModal(false);
    setModalChoiceIsTeacher(true);
    setUserInfo({ ...startingUserInfo, accountType: "student" });
  };

  const topRef = useRef(null);

  const handleSubmit = async () => {
    let { firstName, lastName, email, password, accountType, allContent } =
      userInfo;
    //1. check form valid
    let errorPresent = false;

    const formErrors = { ...startingErrors };
    if (!userInfo.accountType) {
      formErrors.noAccountType = true;
      errorPresent = true;
    }
    if (userInfo.accountType === "student" && schoolLevel === null) {
      formErrors.noSchoolLevel = true;
      errorPresent = true;
    }
    if (userInfo.accountType === "student" && isMinor === null) {
      formErrors.noAgeBool = true;
      errorPresent = true;
    }

    if (!userInfo.firstName) {
      formErrors.noFirstName = true;
      errorPresent = true;
    } else if (userInfo.firstName.length < 3) {
      formErrors.shortFirstName = true;
      errorPresent = true;
    }
    if (!userInfo.lastName) {
      formErrors.noLastName = true;
      errorPresent = true;
    } else if (userInfo.lastName.length < 3) {
      formErrors.shortLastName = true;
      errorPresent = true;
    }
    if (!userInfo.email) {
      formErrors.noEmail = true;
      errorPresent = true;
    } else if (!isEmail(userInfo.email)) {
      formErrors.invalidEmail = true;
      errorPresent = true;
    }
    if (!userInfo.password) {
      formErrors.noPassword = true;
      errorPresent = true;
    } else if (userInfo.password.length < 3) {
      formErrors.invalidPassword = true;
      errorPresent = true;
    }
    if (!userInfo.accountType) {
      formErrors.noAccountType = true;
      errorPresent = true;
    }
    if (userInfo.allContent === null) {
      formErrors.noContent = true;
      errorPresent = true;
    }
    if (!userInfo.acceptedTerms) {
      formErrors.noAcceptedTerms = true;
      errorPresent = true;
    }
    if (!userInfo.acceptedPrivacyPolicy) {
      formErrors.noAcceptedPrivacyPolicy = true;
      errorPresent = true;
    }

    if (
      !isStrongPassword(userInfo.password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
        returnScore: false,
      })
    ) {
      formErrors.invalidPassword = true;
      errorPresent = true;
    }
    if (!userInfo.confirmPassword) {
      formErrors.noConfirmPassword = true;
      errorPresent = true;
    } else if (userInfo.password !== userInfo.confirmPassword) {
      formErrors.notMatchingPasswords = true;
      errorPresent = true;
    }
    if (
      userInfo.accountType === "student" &&
      !parentModal &&
      isMinor === true
    ) {
      if (!joinPassword) {
        formErrors.noJoinPassword = true;
        errorPresent = true;
      }
    }
    if (errorPresent) {
      setErrors(formErrors);
      topRef.current.scrollIntoView({ behavior: "smooth" });
      return;
    }
    //2. create account if email not already in use
    function generateSixDigitNumberNoZeros() {
      let result = "";
      for (let i = 0; i < 6; i++) {
        // Generate a random digit between 1 and 9.
        const digit = Math.floor(Math.random() * 9) + 1;
        result += digit.toString();
      }
      return parseInt(result);
    }

    let code = generateSixDigitNumberNoZeros();

    const newUserData = {
      firstName,
      lastName,
      email,
      password,
      schoolLevel,
      emailCode: code,
      emailVerified: false,
      accountType,
      allContent,
      isMinor: accountType === "student" && !allContent,
      acceptedTerms: userInfo.acceptedTerms,
      acceptedPrivacyPolicy: userInfo.acceptedPrivacyPolicy,
      parentMadeAccount: userInfo.parentMadeAccount,
      joinPassword: +joinPassword, // number or null
    };
    try {
      setLoader(true);
      let createRes = await axios({
        method: "POST",
        url: "/create-account",
        data: newUserData,
      });

      let res = await axios({
        method: "POST",
        url: "/login",
        withCredentials: true,
        data: {
          email,
          password,
        },
      });
      setLoader(false);
      if (!res.data.user) {
        setErrors({
          ...errors,
          loginFailed: true,
        });
        return;
      }
      localStorage.setItem("jwt_user", res.data.token);
      await authReq({
        url: "/send_code",
        method: "POST",
        data: { code, token: res.data.token },
      });

      setUser(res.data.user);
      setToken(res.data.token);
    } catch (error) {
      setLoader(false);
      sendErrorReport("Register", error);
      if (error.response.status === 405) {
        setErrors({
          ...errors,
          incorrectClassCode: true,
        });
      } else {
        setErrors({
          ...errors,
          loginFailed: true,
        });
      }
    }

    setLoader(false);
  };
  const changeSchoolLevel = (e) => {
    setErrors({ ...errors, noSchoolLevel: null });
    setSchoolLevel(e.target.value);
  };
  const handleModalChoice = (isTeacher) => {
    setModalChoiceIsTeacher(isTeacher);
    setUserInfo({ ...userInfo, parentMadeAccount: !isTeacher });
  };
  const returnQuestions = () => {
    return (
      <div
        className={`register-question-container height-transition ${
          userInfo.accountType === "student"
            ? "active-question height-active"
            : ""
        }`}
      >
        <div className="d-flex flex-column mt-3 me-2">
          <div>¿En qué nivel de escuela te encuentras actualmente?</div>
          {errors.noSchoolLevel && (
            <div className="error">* Nivel de escuela requerido</div>
          )}
          <FormControl
            required={true}
            error={errors.noSchoolLevel}
            className="input"
          >
            <InputLabel id="school-level-select-label">
              Nivel Escolar
            </InputLabel>
            <Select
              labelId="school-level-select-label"
              id="school-level-select"
              value={schoolLevel}
              label="Nivel Escolar"
              onChange={(e) => changeSchoolLevel(e)}
            >
              <MenuItem value="primaria">Primaria</MenuItem>
              <MenuItem value="secundaria">Secundaria</MenuItem>
              <MenuItem value="bachillerato">Bachillerato</MenuItem>
              <MenuItem value="FP">FP</MenuItem>
              <MenuItem value="Universidad">Universidad</MenuItem>
              <MenuItem value="Otro">Otro</MenuItem>
            </Select>
            {errors.noSchoolLevel && (
              <FormHelperText>{errors.noSchoolLevel}</FormHelperText>
            )}
          </FormControl>
        </div>
        <div
        // className={`register-question-container height-transition flex-controls m-3 ${
        //   userInfo.accountType === "student" && schoolLevel != null
        //     ? "active-question height-active height-85"
        //     : ""
        // }`}
        >
          Tengo 16 años o más.
          {errors.noAgeBool && <div className="error">* Edad requerida</div>}
          <div
            className="options-container"
            style={{ justifyContent: "space-around" }}
          >
            <button
              className={isMinor == true ? "active-type" : ""}
              onClick={() => {
                setIsMinor(true);
              }}
            >
              No
            </button>
            <button
              className={isMinor == false ? "active-type" : ""}
              onClick={() => {
                setIsMinor(false);
              }}
            >
              Sí
            </button>
          </div>
        </div>
      </div>
    );
  };
  const handleChange = (e) => {
    let value = e.target.value;
    if (
      e.target.name === "acceptedTerms" ||
      e.target.name === "acceptedPrivacyPolicy"
    )
      value = e.target.checked;
    setUserInfo({
      ...userInfo,
      [e.target.name]: value,
    });
    setErrors({
      noFirstName: null,
      shortFirstName: null,
      noLastName: null,
      shortLastName: null,
      noEmail: null,
      invalidEmail: null,
      noPassword: null,
      invalidPassword: null,
      noConfirmPassword: null,
      notMatchingPasswords: null,
      accountAlreadyExists: null,
      loginFailed: null,
    });
  };
  const handleEnter = (e) => {
    if (e.code === "Enter") {
      handleSubmit();
    }
  };

  const getErrorMessages = () => {
    const errorMessages = [];

    if (errors.noFirstName) errorMessages.push("* Nombre requerido");
    if (errors.shortFirstName)
      errorMessages.push("* El nombre debe tener al menos 3 caracteres");
    if (errors.noLastName) errorMessages.push("* Apellidos requerido");
    if (errors.shortLastName)
      errorMessages.push("* El apellidos debe tener al menos 3 caracteres");
    if (errors.noEmail) errorMessages.push("* Correo electrónico requerido");
    if (errors.invalidEmail)
      errorMessages.push("* Correo electrónico inválido");
    if (errors.noJoinPassword)
      errorMessages.push("* Código de clase requerido");
    if (errors.noAcceptedTerms)
      errorMessages.push("* Hay que aceptar los términos de servicio");
    if (errors.noPassword) errorMessages.push("* Contraseña requerida");
    if (errors.invalidPassword)
      errorMessages.push(
        "* Contraseña debe tener al menos 8 caracteres, 1 mayúscula, 1 minúscula, 1 número y 1 símbolo."
      );
    if (errors.noConfirmPassword)
      errorMessages.push("* Confirmar contraseña requerida");
    if (errors.notMatchingPasswords)
      errorMessages.push("* Las contraseñas no coinciden");
    if (errors.accountAlreadyExists)
      errorMessages.push(
        "* La cuenta ya existe con este correo electrónico. Por favor, inicie sesión."
      );
    if (errors.loginFailed)
      errorMessages.push(
        "* Error al iniciar sesión. Por favor, inténtelo más tarde."
      );
    return errorMessages.map((errorMessage, index) => {
      return <div key={index}>{errorMessage}</div>;
    });
  };
  const labelText = (!isMinor ? "(Opcional) " : "") + "Código de clase";
  const returnCodePrompt = () => {
    return (
      <div
        className={`register-question-container px-2 height-transition flex-controls m-3 ${
          errors.incorrectClassCode ? "mb-5" : ""
        } ${
          userInfo.accountType === "student" && isMinor != null
            ? "active-question height-active height-85"
            : ""
        }`}
      >
        {errors.incorrectClassCode && (
          <div className="error mb-2">* Código de clase incorrecto</div>
        )}
        <FormControl
          required={true}
          variant="outlined"
          error={errors.noCode || errors.noJoinPassword ? true : false}
        >
          <InputLabel htmlFor="code">{labelText}</InputLabel>
          <OutlinedInput
            id="code"
            label={labelText}
            name="code"
            onChange={(e) =>
              setJoinPassword(e.target.value.replace(/[^0-9]/g, ""))
            }
            value={joinPassword}
            type="text"
          />
        </FormControl>
        {isMinor && (
          <span
            style={{ marginLeft: "2px" }}
            className="blue-link"
            onClick={() => setParentModal(true)}
          >
            No tengo código ...
          </span>
        )}
      </div>
    );
  };

  const returnRegistrationInputs = () => {
    return (
      <div className="flex-controls m-3 mt-0">
        <FormControl
          required={true}
          className="input"
          variant="outlined"
          error={errors.noFirstName || errors.shortFirstName ? true : false}
        >
          <InputLabel htmlFor="firstName">Nombre</InputLabel>
          <OutlinedInput
            id="firstName"
            label="Nombre"
            name="firstName"
            onChange={handleChange}
            onKeyUp={handleEnter}
            value={userInfo.firstName}
            type="text"
          />
        </FormControl>
        <FormControl
          required={true}
          className="input"
          variant="outlined"
          error={errors.noLastName || errors.shortLastName ? true : false}
        >
          <InputLabel htmlFor="lastName">Apellidos</InputLabel>
          <OutlinedInput
            id="lastName"
            label="Apellidos"
            name="lastName"
            onChange={handleChange}
            onKeyUp={handleEnter}
            value={userInfo.lastName}
            type="text"
          />
        </FormControl>
        <FormControl
          required={true}
          className="input"
          variant="outlined"
          error={
            errors.noEmail || errors.invalidEmail || errors.accountAlreadyExists
              ? true
              : false
          }
        >
          <InputLabel htmlFor="email">Correo electrónico</InputLabel>
          <OutlinedInput
            id="email"
            label="Correo electrónico"
            name="email"
            onChange={handleChange}
            onKeyUp={handleEnter}
            value={userInfo.email}
            type="text"
          />
        </FormControl>
        <FormControl
          required={true}
          className="input"
          variant="outlined"
          error={
            errors.noPassword ||
            errors.invalidPassword ||
            errors.notMatchingPasswords
              ? true
              : false
          }
        >
          <InputLabel htmlFor="password">Contraseña</InputLabel>
          <OutlinedInput
            id="password"
            label="Contraseña"
            name="password"
            onChange={handleChange}
            onKeyUp={handleEnter}
            value={userInfo.password}
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl
          required={true}
          className="input"
          variant="outlined"
          error={
            errors.noConfirmPassword || errors.notMatchingPasswords
              ? true
              : false
          }
        >
          <InputLabel htmlFor="confirmPassword">
            Confirma la contraseña
          </InputLabel>
          <OutlinedInput
            id="confirmPassword"
            label="Confirma la contraseña"
            name="confirmPassword"
            onChange={handleChange}
            onKeyUp={handleEnter}
            value={userInfo.confirmPassword}
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>

        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Lee atentamente nuestros{" "}
          <a href="/Terms_And_Conditions_Playpod.pdf" target="_blank">
            términos de servicio.
          </a>{" "}
          y{" "}
          <a href="/Privacy_Policy_Playpod.pdf" target="_blank">
            política de privacidad.
          </a>
        </Typography>
        <FormControl
          required={true}
          error={errors.noAcceptedTerms}
          className="input"
        >
          <FormControlLabel
            control={
              <Checkbox
                name="acceptedTerms"
                checked={userInfo.acceptedTerms}
                onChange={(e) => handleChange(e)}
              />
            }
            label="Acepto los términos de servicio
        "
          />
        </FormControl>

        <FormControl
          required={true}
          error={errors.noAcceptedTerms}
          className="input"
        >
          <FormControlLabel
            control={
              <Checkbox
                name="acceptedPrivacyPolicy"
                checked={userInfo.acceptedPrivacyPolicy}
                onChange={(e) => handleChange(e)}
              />
            }
            label="Acepto la política de privacidad
        "
          />
        </FormControl>
        <div className="error">{getErrorMessages()}</div>
        <Button
          variant="contained"
          id="auth-button"
          onClick={() => handleSubmit()}
          disabled={isRegisterDisabled}
        >
          Registrarse
        </Button>
      </div>
    );
  };
  return (
    <div className="mini-form-container" id="location">
      <Grid
        className="grid-container"
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item className="inner-container">
          <Title page={page} setPage={setPage} />
          <div className="session-msg">
            Si ya recibiste un código de acceso por correo electrónico, por
            favor inicia sesión primero. Puedes añadir tu código de acceso
            después de iniciar sesión.
          </div>

          <div className="inputs-container" elevation={3}>
            <div className="options-container" ref={topRef}>
              <button
                className={
                  userInfo.accountType === "teacher" ? "active-type" : ""
                }
                onClick={() =>
                  setUserInfo({
                    ...userInfo,
                    accountType: "teacher",
                    allContent: true,
                  })
                }
              >
                Profesor
              </button>
              <button
                className={
                  userInfo.accountType === "student" ? "active-type" : ""
                }
                onClick={() =>
                  setUserInfo({
                    ...userInfo,
                    accountType: "student",
                    allContent: false,
                  })
                }
              >
                Estudiante
              </button>
              <button
                className={
                  userInfo.accountType === "parent" ? "active-type" : ""
                }
                onClick={() =>
                  setUserInfo({
                    ...userInfo,
                    accountType: "parent",
                    allContent: true,
                  })
                }
              >
                Padre
              </button>
            </div>
            {returnQuestions()}
            {userInfo.accountType === "student" ? returnCodePrompt() : null}
            {returnRegistrationInputs()}
          </div>
        </Grid>
      </Grid>
      <Modal
        open={parentModal}
        // onClose={handleParentModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        // className="payment-modal"
      >
        <Box className="modal-choice">
          <IconButton
            className="modal-close-button"
            onClick={handleParentModalClose}
          >
            <Close />
          </IconButton>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="payment-desc"
          >
            <div className="parent-modal-title">
              Registro de estudiantes sin código de acceso
            </div>
            <div className="choices-grid gap-2 gap-md-0 d-flex flex-md-row flex-column">
              <button
                className={`${
                  modalChoiceIsTeacher ? "active-button-styles" : ""
                }`}
                onClick={() => handleModalChoice(true)}
              >
                Pídele a tu profesor
              </button>
              <button
                className={`${
                  !modalChoiceIsTeacher ? "active-button-styles" : ""
                }`}
                onClick={() => handleModalChoice(false)}
              >
                Pídele a tu padre o a tu tutor que registre tu cuenta
              </button>
            </div>
            {modalChoiceIsTeacher ? (
              <p>
                Pregúntale a tu profesor si tiene un código de Playpod que
                puedas usar. Para obtener un código, el profesor debe crear una
                cuenta y crear una clase en Playpod.
              </p>
            ) : (
              <form>
                <FormControl
                  required={true}
                  error={errors.noAcceptedTerms}
                  className="input parent-checkbox"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="parentConfirm"
                        checked={parentConfirm}
                        onChange={(e) => setParentConfirm(e.target.checked)}
                      />
                    }
                    label="Soy el padre o tutor legal del estudiante y confirmo que mi hijo/a puede usar Playpod."
                  />
                </FormControl>
                <h3>
                  Ingresa tu información como padre/tutor a continuación.{" "}
                </h3>
                {returnRegistrationInputs()}
              </form>
            )}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default Register;
