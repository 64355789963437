import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ProSidebarProvider } from "react-pro-sidebar";
import { ContextProvider } from "./contexts/index";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Elements } from "@stripe/react-stripe-js";
import "flag-icons/css/flag-icons.min.css";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Link,
} from "react-router-dom";
const root = ReactDOM.createRoot(document.getElementById("root"));

const { palette } = createTheme();
const { augmentColor } = palette;

const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

const theme = createTheme({
  palette: {
    themeBlue: createColor("#75cbfd"),
    themeOrange: createColor("#d6680b"),
    themeGrey: createColor("#424242"),
    themeLightGrey: createColor("#a8a8a8"),
    themeWhite: createColor("#f7f7f7"),
    themeGreen: createColor("#BBDFAD"),
    themeYellow: createColor("#FFDA00"),
    themeNotification: createColor("#fff5d4"),
    errorRed: createColor("#d32f2f"),
  },
});

root.render(
  <ThemeProvider theme={theme}>
    <ContextProvider>
      <ProSidebarProvider>
        <Router className="root-container">
          <App />
        </Router>
      </ProSidebarProvider>
    </ContextProvider>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
