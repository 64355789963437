import { useContext, useEffect, useState } from "react";
import {
  useNavigate,
  useSearchParams,
  useParams,
  Link,
} from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import { LoaderContext, UserContext } from "../../contexts";
import { PodcastContext } from "../../contexts";
import Card from "../../components/card";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button, IconButton, Typography } from "@mui/material";
// import Paper from '@mui/material/Paper';
// import { styled } from '@mui/material/styles';
import podcastImg from "../../assets/earbud.png";
import "./index.css";
import { authReq, sendErrorReport } from "../../utilities/requests";
import { v4 as uuidv4 } from "uuid";
import { ToggleButton } from "../ClassProfile";
import { useMemo } from "react";
import NotPublished from "../../components/NotPublished";
import { returnUpperCase } from "../../utilities/text";

//? use Item component for more in-depth styling in the future?
// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor:
//     theme.palette.mode === 'dark' ? '#424242' : '#f7f7f7',
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   justifyItems: 'center',
//   color: theme.palette.text.secondary,
// }));

const Profile = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  let {
    // usersPodcasts,
    setUsersPodcasts,
    myClass,
    setMyClass,
    otherClass,
    setOtherClass,
    messages,
    setMessages,
  } = useContext(PodcastContext);

  let {
    user,
    userAvatar,
    podcastCallWasMade,
    setPodcastCallWasMade,
    isGettingAvatar,
    profilePods,
    setProfilePods,
    followed,
    setFollowed,
    plan,
  } = useContext(UserContext);

  let { setMessageState } = useContext(LoaderContext);

  // if logged in user's id - good
  // else no

  const [userToDisplay, setUserToDisplay] = useState(null);
  const [isOwnProfile, setIsOwnProfile] = useState(false);
  const [profilePodCallMade, setProfilePodCallMade] = useState(false);
  const [selectedPodcastSeries, setSelectedPodcastSeries] = useState(null);
  const [shouldMakeCall, setShouldMakeCall] = useState(false);
  const [usersFollowedByProfile, setUsersFollowedByProfile] = useState([]);
  const [followingThisProfile, setFollowingThisProfile] = useState([]);

  const [fullFollowed, setFullFollowed] = useState([]);

  const [fullFollowing, setFullFollowing] = useState([]);
  const [isFollowed, setIsFollowed] = useState(false);

  let { id } = user;
  const { setLoader } = useContext(LoaderContext);
  useEffect(() => {
    if (followed.length) {
      let isInArray = followed.some((obj) => {
        return obj.followed === userToDisplay?.id;
      });
      setIsFollowed(isInArray);
    }
  }, [followed, userToDisplay]);

  // Access individual query parameters
  let params = useParams();
  const idParam = params.id;

  let isFollowedParam = params.isFollowed;

  useEffect(() => {
    if (!isFollowedParam) return;
    if (isFollowedParam === "true" && usersFollowedByProfile.length < 1) return;
    if (isFollowedParam === "false" && followingThisProfile.length < 1) return;
    authReq({
      url: `/followers/${isFollowedParam === "true"}`,
      method: "PUT",
      data: {
        entriesArray:
          isFollowedParam === "true"
            ? usersFollowedByProfile
            : followingThisProfile,
      },
    }).then((res) => {
      if (isFollowedParam === "true") {
        setFullFollowed(res.data);
      } else {
        setFullFollowing(res.data);
      }
    });
  }, [isFollowedParam]);
  // set the user (logged in user or clicked user)

  useEffect(() => {
    let stringifiedUser = searchParams.get("clickedUser");
    console.log("stringifiedUser", stringifiedUser);
    let userFromQuery = JSON.parse(stringifiedUser);

    if (userFromQuery && userFromQuery.id !== user.id) {
      // user other than self was clicked
      setUserToDisplay(userFromQuery);
      if (userToDisplay?.id !== userFromQuery.id) {
        setShouldMakeCall(true);
        setSelectedPodcastSeries(null);
      }
    } else {
      // we are viewing own profile
      setUserToDisplay(user);
      setIsOwnProfile(true);
      // next line
      if (userToDisplay?.id === user.id) return;
      setShouldMakeCall(true);
      setSelectedPodcastSeries(null);
    }
    //eslint-disable-next-line
  }, [idParam, id, user, searchParams]);

  useEffect(() => {
    let stringifiedSeries = decodeURIComponent(searchParams.get("series"));
    console.log("stringifiedSeries", stringifiedSeries);
    let seriesFromQuery = JSON.parse(stringifiedSeries);
    if (seriesFromQuery) {
      setSelectedPodcastSeries(seriesFromQuery);
    }
  }, []);

  useEffect(() => {
    if (userToDisplay) {
      setLoader(false);
      authReq({
        url: `/followers_num/${userToDisplay.id}`,
        method: "GET",
      }).then((res) => {
        setUsersFollowedByProfile(res.data.followedByProfile);
        setFollowingThisProfile(res.data.followingThisProfile);
      });
    }
  }, [userToDisplay]);

  useEffect(() => {
    const shouldMakePodcastCall = () => {
      if ((isOwnProfile && !podcastCallWasMade) || shouldMakeCall) return true;
      if ((!isOwnProfile && !profilePodCallMade) || shouldMakeCall) return true;
      return false;
    };

    const handlePodcastResponse = (data) => {
      if (isOwnProfile) {
        setPodcastCallWasMade(true);
        setUsersPodcasts(data);
      }
      setProfilePodCallMade(true);
      setProfilePods(data);
    };

    const fetchUserPodcasts = async () => {
      setLoader(true);
      try {
        const result = await authReq({
          url: `/get_users_podcasts/${userToDisplay.id}/${userToDisplay.classCode}`,
        });
        setLoader(false);
        handlePodcastResponse(result.data);
      } catch (error) {
        sendErrorReport("Profile", error);

        console.error("Error fetching user podcasts:", error);
        setLoader(false);
      }
    };
    let should = shouldMakePodcastCall();
    if (userToDisplay && should) {
      fetchUserPodcasts();
    }
    // eslint-disable-next-line
  }, [
    userToDisplay,
    isOwnProfile,
    profilePodCallMade,
    setUsersPodcasts,
    podcastCallWasMade,
    setPodcastCallWasMade,
  ]);

  const handleClassClick = (classCode) => {
    if (!classCode)
      return setMessageState({ text: "No afiliado con una clase", time: 3000 });
    navigate(`/clase/${classCode}`);
  };

  const returnUsers = () => {
    let usersArr = isFollowedParam === "true" ? fullFollowed : fullFollowing;
    return (
      <div>
        <div className="user-row">
          <div>Clase</div>
          <div>Nombre</div>
        </div>
        {usersArr.length < 1 && <div>No hay usuarios para mostrar</div>}
        {usersArr.map((userObj, index) => {
          return (
            <>
              <div key={JSON.stringify(userObj)} className="user-row">
                {userObj.classCode ? (
                  <button
                    onClick={() => handleClassClick(userObj.classCode)}
                    style={{ border: "1px solid black", maxWidth: "200px" }}
                  >
                    ver clase
                  </button>
                ) : (
                  <div className="flex-align-center">
                    No afiliado con una clase
                  </div>
                )}
                <div
                  onClick={() =>
                    navigate(
                      `/perfil/${userObj.id}/?clickedUser=${JSON.stringify(
                        userObj
                      )}`
                    )
                  }
                  className="name-div"
                >
                  {userObj.firstName} {userObj.lastName}
                </div>
              </div>
              <hr></hr>
            </>
          );
        })}
      </div>
    );
  };
  const getClassData = () => {
    if (
      (isOwnProfile && !myClass.class) ||
      (!isOwnProfile && !otherClass) ||
      (!isOwnProfile && otherClass.classCode !== userToDisplay.classCode)
    ) {
      const makeRequests = async () => {
        setLoader(true);
        let response = await authReq({
          url: `/get_class_podcasts/${userToDisplay.classCode}`,
          method: "POST",
        });
        setLoader(false);
        if (isOwnProfile) {
          setMyClass({
            ...response.data,
          });
        } else {
          setOtherClass({
            ...response.data,
          });
        }
      };
      makeRequests();
    }
  };
  useEffect(() => {
    if (userToDisplay) {
      getClassData();
    }
  }, [userToDisplay]);
  const handleFollow = () => {
    authReq({
      method: "PUT",
      url: "/follow",
      data: {
        idToFollow: userToDisplay.id,
        type: isFollowed ? "unfollow" : "follow",
      },
    }).then((response) => {
      let text = isFollowed ? "Ya no sigues a " : "Sigues a ";
      setMessages([
        ...messages,
        {
          type: "follow",
          content: text,
          user: userToDisplay,
          id: uuidv4(),
        },
      ]);
      let newFollowed;
      if (isFollowed) {
        // remove from follow array
        newFollowed = followed.filter(
          (followObj) => followObj.followed !== userToDisplay.id
        );
        setIsFollowed(false);
      } else {
        // add to followed array
        newFollowed = [...followed, response.data];
      }
      setFollowed(newFollowed);
    });
  };

  const returnClassName = () => {
    if (!userToDisplay.classCode) return "No afiliado a una clase";
    if (isOwnProfile) {
      return myClass?.class?.name ? (
        <Link className="profile-data" to={`/clase/${myClass.class.classCode}`}>
          {myClass?.class?.name}
        </Link>
      ) : (
        "cargando..."
      );
    } else {
      return otherClass?.class?.name ? (
        <Link
          className="profile-data"
          to={`/clase/${otherClass.class.classCode}`}
        >
          {otherClass?.class?.name}
        </Link>
      ) : (
        "cargando..."
      );
    }
  };

  const handleToggle = (podcast, index) => {
    const newAudios = [...profilePods];
    profilePods[index].isPublished = !profilePods[index].isPublished;
    authReq({
      url: "/handle_publish",
      method: "PUT",
      data: {
        newValue: profilePods[index].isPublished,
        audioId: profilePods[index].id,
      },
    });
    setProfilePods(newAudios);
  };
  const returnCards = () => {
    let podsToDisplay = profilePods;
    if (selectedPodcastSeries !== "View All Podcasts") {
      podsToDisplay =
        selectedPodcastSeries == null
          ? profilePods
          : profilePods.filter((p) => p.series === selectedPodcastSeries);
    }
    return podsToDisplay.map((podcast, index) => {
      podcast.owner = podcast.owner ? podcast.owner : userToDisplay;
      return (
        <div key={JSON.stringify(podcast)} className="pod-container">
          {user?.isClassAdmin && user.classCode === userToDisplay.classCode && (
            <ToggleButton
              noMargin={true}
              isPublished={podcast.isPublished}
              onToggle={() => handleToggle(podcast, index)}
            />
          )}
          {!user?.isClassAdmin && !podcast.isPublished ? (
            <NotPublished />
          ) : (
            <></>
          )}
          <Card data={podcast} />
        </div>
      );
    });
  };

  let accountTypeMap = {
    teacher: "Profesor",
    student: "Alumno",
    parent: "Padre",
  };
  return userToDisplay ? (
    <Box sx={{ flexGrow: 1 }} className="page-padding">
      <Grid container spacing={1}>
        <Grid
          item
          sm={12}
          md={4}
          lg={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
          }}
        >
          <Avatar
            id="avatar"
            sx={{
              border: "1px solid #424242",
              width: "125px",
              height: "125px",
              fontSize: 75,
            }}
            imgProps={{
              style: {
                objectFit: "cover",
                borderRadius: "50%",
              },
            }}
            src={userAvatar}
            alt={userToDisplay.firstName}
          >
            {returnUpperCase(
              userToDisplay.firstName[0],
              userToDisplay.lastName[0]
            )}
          </Avatar>
        </Grid>
        <Grid item sm={12} md={8} lg={10}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {userToDisplay.firstName}{" "}
                {user.isClassAdmin &&
                  user.classCode === userToDisplay.classCode &&
                  userToDisplay.lastName}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {accountTypeMap[userToDisplay.accountType]}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ fontWeight: "bold" }}
              >
                {returnClassName()}
              </Typography>
              <Grid item container spacing={2} alignItems={"center"}>
                {!isOwnProfile && (
                  <Grid item>
                    <Typography
                      onClick={handleFollow}
                      variant="body2"
                      gutterBottom
                      sx={{
                        backgroundColor: "#BBDFAD",
                        padding: "4px 20px",
                        borderRadius: "25px",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                    >
                      {/* //TODO: conditionally render text.
                        if current user is following the profile of user they are viewing,
                          Following,
                        if current user is not following the profile of user they are viewing,
                          Follow (backgroundColor: '#FFDA00') */}
                      {isFollowed ? "Unfollow" : "Follow"}
                    </Typography>
                  </Grid>
                )}
                <Grid item>
                  <Link
                    to={`/perfil/${
                      userToDisplay.id
                    }/follow/false?clickedUser=${JSON.stringify(
                      userToDisplay
                    )}`}
                  >
                    <Typography variant="body2" className="profile-data">
                      {followingThisProfile.length} Followers
                    </Typography>
                  </Link>
                  <Link
                    to={`/perfil/${
                      userToDisplay.id
                    }/follow/true?clickedUser=${JSON.stringify(userToDisplay)}`}
                  >
                    <Typography variant="body2" className="profile-data">
                      {usersFollowedByProfile.length} Following
                    </Typography>
                  </Link>
                  <Link
                    to={`/perfil/${
                      userToDisplay.id
                    }/?clickedUser=${JSON.stringify(userToDisplay)}`}
                  >
                    <Typography variant="body2" className="profile-data">
                      {profilePods.length} Podcasts
                    </Typography>
                  </Link>
                  {isOwnProfile && (
                    <Grid item>
                      <Button
                        variant="contained"
                        style={{ marginTop: "12px" }}
                        onClick={() => navigate(`/perfil/editar/`)}
                      >
                        Editar
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {userToDisplay.about && userToDisplay.about != "null" && (
            <Typography variant="body2" sx={{ marginTop: "5px" }}>
              {userToDisplay.about}
            </Typography>
          )}
        </Grid>
      </Grid>
      <hr id="horizontal-rule" />
      {/* 
  CUT HERE  
 */}
      {!isFollowedParam ? (
        <>
          <Grid
            container
            direction="column"
            spacing={2}
            className="podcasts-container"
          >
            {profilePods
              .reduce((uniquePodcasts, currentPodcast) => {
                if (uniquePodcasts.length === 0) {
                  uniquePodcasts.push({ series: "View All Podcasts" });
                }
                if (
                  !uniquePodcasts.some(
                    (podcast) => podcast.series === currentPodcast.series
                  )
                ) {
                  uniquePodcasts.push(currentPodcast);
                }
                return uniquePodcasts;
              }, [])
              .map((podcast, index) => {
                return (
                  <Grid
                    container
                    direction="row"
                    alignItems={"center"}
                    className={`podcast-item ${
                      selectedPodcastSeries?.toLowerCase() ==
                      podcast?.series?.toLowerCase()
                        ? "active-item"
                        : ""
                    }`}
                    key={index}
                    onClick={() => setSelectedPodcastSeries(podcast.series)}
                  >
                    <Grid item>
                      <img
                        //TODO: use dynamic image: podcast.image
                        src={podcastImg}
                        alt="podcast cover art"
                        style={{
                          width: "100%",
                          height: "auto",
                          maxHeight: "50px",
                          maxWidth: "50px",
                        }}
                      />
                    </Grid>
                    <Grid item>{podcast.series || "No Series"}</Grid>
                  </Grid>
                );
              })}
          </Grid>
          <div className="card-container">{returnCards()}</div>
        </>
      ) : (
        returnUsers()
      )}
    </Box>
  ) : (
    <></>
  );
};

export default Profile;
