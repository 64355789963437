import { useEffect, useState, useContext } from "react";
import { LoaderContext, UserContext } from "../../../contexts";
import Title from "../Title";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import axios from "axios";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import "./index.css";

const Login = ({ page, setPage }) => {
  const { setLoader } = useContext(LoaderContext);
  const { setUser, setToken } = useContext(UserContext);

  const [isLoginDisabled, setIsLoginDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({
    noEmail: null,
    noPassword: null,
    loginFailed: null,
  });

  let [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    setIsLoginDisabled(!formData.email || !formData.password ? true : false);
  }, [formData.email, formData.password]);

  const handleSubmit = (e) => {
    let { email, password } = formData;
    //1. check form valid
    let errorPresent = false;
    const formErrors = {
      noEmail: null,
      noPassword: null,
    };
    if (!formData.email) {
      formErrors.noEmail = true;
      errorPresent = true;
    }
    if (!formData.password) {
      formErrors.noPassword = true;
      errorPresent = true;
    }
    if (errorPresent) {
      setErrors(formErrors);
      return;
    }
    //2. log user in
    setLoader(true);

    axios({
      method: "POST",
      url: "/login",
      withCredentials: true,
      data: {
        email,
        password,
      },
    })
      .then((res) => {
        setLoader(false);
        if (!res.data.user) {
          setErrors({
            ...errors,
            loginFailed: true,
          });
          return;
        }
        localStorage.setItem("jwt_user", res.data.token);
        setUser(res.data.user);
        setToken(res.data.token);
        // here snap to top
        window.scrollTo({
          top: 0,
          behavior: "smooth", // For a smooth scroll
        });
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
        setErrors({ ...errors, loginFailed: true });
      });
  };

  //* temporary useEffect to make login faster *//
  // useEffect(() => {
  //   setFormData({ email: "", password: "" });
  // }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setErrors({
      noEmail: null,
      noPassword: null,
      loginFailed: null,
    });
  };

  const handleEnter = (e) => {
    if (e.code === "Enter") {
      handleSubmit();
    }
  };
  const getErrorMessages = () => {
    const errorMessages = [];

    if (errors.noEmail)
      // !Aina
      errorMessages.push("* Correo electrónico es obligatorio");
    if (errors.noPassword) errorMessages.push("* Contraseña es obligatorio");
    if (errors.loginFailed) errorMessages.push("* Error de inicio de sesión");

    return errorMessages.map((errorMessage, index) => {
      return <div key={index}>{errorMessage}</div>;
    });
  };

  return (
    <div className="mini-form-container" id="location">
      <Grid
        className="grid-container"
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item className="inner-container">
          <Title page={page} setPage={setPage} />
          <div className="session-msg">
            Si ya recibiste un código de acceso por correo electrónico, por
            favor inicia sesión primero. Puedes añadir tu código de acceso
            después de iniciar sesión.
          </div>
          <div className="inputs-container" elevation={3}>
            <FormControl
              required={true}
              className="input"
              variant="outlined"
              error={errors.noEmail || errors.loginFailed ? true : false}
            >
              <InputLabel htmlFor="email">Correo electrónico</InputLabel>
              <OutlinedInput
                id="email"
                label="Correo electrónico"
                name="email"
                onChange={(e) => handleChange(e)}
                onKeyUp={handleEnter}
                value={formData.email}
                type="text"
              />
            </FormControl>
            <FormControl
              required={true}
              className="input"
              variant="outlined"
              error={errors.noPassword || errors.loginFailed ? true : false}
            >
              <InputLabel htmlFor="password">Contraseña</InputLabel>
              <OutlinedInput
                id="password"
                label="Contraseña"
                name="password"
                onChange={(e) => handleChange(e)}
                onKeyUp={handleEnter}
                value={formData.password}
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <div className="error">{getErrorMessages()}</div>
            <Button
              variant="contained"
              id="auth-button"
              disabled={isLoginDisabled}
              onClick={handleSubmit}
            >
              Iniciar sesión
            </Button>

            <p>
              ¿Olvidaste tu contraseña?{" "}
              <span className="span-link" onClick={() => setPage("Password")}>
                Restablécela aquí.{" "}
              </span>{" "}
            </p>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Login;
