import "./App.css";

import { useContext, useEffect, useState } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import Joyride, { STATUS, ACTIONS, EVENTS, CallBackProps } from "react-joyride";
import "bootstrap-icons/font/bootstrap-icons.css";

import {
  ContextProvider,
  LoaderContext,
  PodcastContext,
  UserContext,
} from "./contexts";
import { Home, Search, Mic, Class } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";

import BugReportPage from "./pages/BugReport";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Link,
} from "react-router-dom";
import LoggedInRoute from "./routes/loggedInRoute";

// * COMPONENTS * //
import Navbar from "./components/navbar";
import Sidebar from "./components/sidebar";
// import PageWrapper from './components/PageWrapper';
import AudioPlayer from "./components/audioPlayer";
import LoaderModal from "./components/LoaderModal";
import DynamicBreadcrumbs from "./components/breadcrumbs";

// * PAGES * //
import Dashboard from "./pages/Dashboard";
import PodcastDetails from "./pages/PodcastDetails";
import SearchPage from "./pages/Search";
import Auth from "./pages/Auth";
import SelectEdit from "./pages/SelectEdit";
import Library from "./pages/Library";
import Profile from "./pages/Profile";
import EditProfile from "./pages/EditProfile";
import { authReq, sendErrorReport } from "./utilities/requests";
import EmailCode from "./pages/EmailCode";
import ClassProfile from "./pages/ClassProfile";
import { useRef } from "react";
import ClassCreate from "./pages/ClassCreate";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import FeatureComparisonTable from "./pages/FeatureComparisonTable";
import PremiumIcon from "./components/premiumIcon";
import PremiumFeatureModal from "./components/premiumFeatureModal";
import MessageModal from "./components/messageModal";
import PodcastEntryForm from "./pages/PodcastEntryForm";
import ResetPassword from "./pages/ResetPassword";
import Notifications from "./pages/Notifications";

// import '@aws-amplify/ui-react/styles.css';

// * TRANSLATIONS * //
// import { I18n } from 'aws-amplify';
// import { translations } from '@aws-amplify/ui-react';
// translations.es["Create Account"] = "Crear Cuenta";
// translations.es["Given Name"] = "Nombre";
// translations.es["Family Name"] = "Apellidos";
// I18n.putVocabularies(translations);
// I18n.setLanguage('es');
const stripePromise = loadStripe(
  "pk_live_51OIzSXC7yJCKtJG6bk4OyWg1eOQqO0yf5noD1QkTPPSItvZKwIgx0jrPUz8MVzBx70mjcnqmSjGzOneDyLnb5Jb900gtYZ2pRR"
);
// const stripePromise = loadStripe(
//   "pk_test_51OIzSXC7yJCKtJG6nuWmD2EokquDv1fMe5IIplnghdlgjWIu2T7ziplsMSZxF0YdO6VQlYY9eYjUdJI5AaP7xXek00yLcIECac",
//   { locale: "es" }
// );

function App() {
  const navigate = useNavigate();

  const {
    user,
    setUser,
    token,
    setToken,
    plan,
    setPlan,
    buttonsContainerRef,
    runTour,
    setRunTour,
    stepsIndex,
    setStepsIndex,
  } = useContext(UserContext);
  const [checkedForToken, setCheckedForToken] = useState(false);
  const [madeDashboardCall, setMadeDashboardCall] = useState(false);
  let { podcastCache, setPodcastCache, myClass } = useContext(PodcastContext);
  // change steps based on mobile or desktop and also teacher or student
  let buttonSteps = [];
  if (buttonsContainerRef?.current?.childNodes) {
    [...buttonsContainerRef.current?.childNodes].forEach((button) => {
      if (button.id === "home-button" && myClass?.audios?.length < 1) {
        return;
      }
      [...button.attributes].map((attr) => {
        if (attr.name === "data-step-description") {
          buttonSteps.push({
            target: `#${button.id}`,
            content: attr.value,
            placement: "bottom", // Position text to the right
            highlightPage: true,
          });
        }
        if (attr.name === "data-step-next") {
          buttonSteps.push(...JSON.parse(attr.value));
        }
      });
    });
  }
  const updateTutorialStatus = () => {
    authReq({
      method: "post",
      url: "/seen_tutorial",
    });
    localStorage.setItem("seen_tutorial", "true");
  };
  let steps = [
    {
      target: ".sidebar-logo",
      content:
        "Bienvenido a Playpod. Este tutorial te guiará por las funciones principales de la app.",
      placement: "center", // Position text to the right
    },

    ...buttonSteps,

    {
      target: ".search-container",
      content: "Busca podcasts por su nombre.",
      placement: "bottom-start", // Position text to the right
    },
    {
      target: "#notifications-container",
      content: "Aquí puedes ver tu perfil, preferencias, y cerrar sesión.",
      placement: "left", // Position text to the right
    },
    {
      target: "#contact-header",
      content: "Contacta con nosotros para cualquier error, duda o comentario.",
      placement: "left", // Position text to the right
    },
  ];

  useEffect(() => {}, [buttonsContainerRef]);
  // get current path and check if it includes /reset/
  const currentPath = window.location.pathname;
  const isReset = currentPath.includes("/reset");
  const isJoin = currentPath.includes("/unirse");
  const {
    displayButtons,
    setDisplayButtons,
    displayNotifications,
    setDisplayNotifications,
    handleSearchClick,
    isMobile,
    setLoader,
    showCookies,
    setShowCookies,
    width,
    setWidth,
  } = useContext(LoaderContext);
  // call to get user's liked podcasts
  // maybe check here if various buttons exist (if they do, push to the steps array)
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures this effect runs only once on mount

  useEffect(() => {
    let jwt = localStorage.getItem("jwt_user");
    if (jwt) {
      axios({
        url: "/check_token",
        method: "POST",
        headers: {
          Authorization: jwt,
        },
      })
        .then((res) => {
          if (res.data) {
            setUser(res.data);
            setToken(jwt);
            setCheckedForToken(true);
          }
        })
        .catch((err) => {
          setCheckedForToken(true);
        });
    } else {
      setCheckedForToken(true);
    }
  }, []);
  useEffect(() => {
    if (user && user.acceptedCookies === null) {
      setShowCookies(true);
    }
  }, [user]);
  const madeDashboardCallRef = useRef(false);
  const planCall = useRef(false);
  useEffect(() => {
    if (!madeDashboardCallRef.current && user) {
      madeDashboardCallRef.current = true;

      try {
        (async function () {
          let res = await authReq({ url: "/get_dashboard_podcasts" });
          let objectOfArrayPodcasts = res.data;
          setPodcastCache(objectOfArrayPodcasts);
        })();
      } catch (error) {
        console.error(error);
        sendErrorReport("App  - useEffect get podcasts", error);
      }
    }
  }, [user]);

  const createHighlight = (overlay, selector, highlightClassName) => {
    // let targetDiv = document.querySelector(steps[stepsIndex + 1]?.target);
    let targetDiv = document.querySelector(selector);

    if (!targetDiv) return;

    const rect = targetDiv.getBoundingClientRect();
    const styles = window.getComputedStyle(targetDiv);
    let div = document.createElement("div");
    div.setAttribute("data-test-id", "spotlight");
    div.className = "react-joyride__spotlight";

    div.classList.add(highlightClassName);

    div.style.borderRadius = styles.borderRadius;
    div.style.position = "absolute";
    div.style.backgroundColor = "gray";
    div.style.height = `${rect.height}px`;
    div.style.left = `${rect.left}px`;
    div.style.opacity = "1";
    div.style.pointerEvents = "auto";
    div.style.top = `${rect.top}px`;
    div.style.transition = "opacity 0.2s ease 0s";
    div.style.width = `${rect.width}px`;

    overlay.appendChild(div);
  };

  // useEffect(() => {
  //   let overlay = document.querySelector(".react-joyride__overlay");
  //   let pageHighlight = document.querySelector(".chase-spotlight-page");
  //   if (pageHighlight) {
  //     pageHighlight.remove();
  //   }
  //   if (overlay && steps[stepsIndex]) {
  //     console.log("In overlay", steps[stepsIndex], pageHighlight);
  //     if (steps[stepsIndex].highlightPage) {
  //       createHighlight(overlay, ".page", "chase-spotlight-page");
  //     }

  //     if (steps[stepsIndex].childOf) {
  //       let previousHighlight = document.querySelector(
  //         '[class$="-highlight-chase"]'
  //       );
  //       console.log("previousHighlight", previousHighlight);
  //       if (previousHighlight) {
  //         previousHighlight.remove();
  //       }
  //       let highlightClassName = `${steps[stepsIndex].childOf}-highlight-chase`;

  //       createHighlight(overlay, steps[stepsIndex].childOf, highlightClassName);
  //     }
  //   } else {
  //     // remove my spotlight if it exists
  //     //  if no next jumpTo, also remove my spotlight
  //   }
  // }, [stepsIndex]);

  const handleJoyrideCallback = (data) => {
    const { action, index, type, status } = data;
    if (action === "skip") {
      updateTutorialStatus();
    }
    if (data.lifecycle === "complete") {
      updateTutorialStatus();
    }
    // Update stepInde x on step change
    // scroll to __floater __floater__open class element when we are on a new step
    if (data.action === "update" && steps[stepsIndex]?.jumpTo) {
      navigate(steps[stepsIndex].jumpTo);
    }
    if (data.action === "next") {
      let target = document.querySelector(".__floater __floater__open");
      if (target) {
        target.scrollIntoView();
      }
      if (steps[stepsIndex + 1]?.jumpTo) {
        navigate(steps[stepsIndex + 1].jumpTo);
      }
    }
    if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      // Depending on the action, increment or decrement the stepIndex
      const newIndex = action === ACTIONS.NEXT ? index + 1 : index - 1;
      setStepsIndex(newIndex);
    }

    // Stop the Joyride tour if it's finished or skipped
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRunTour(false);
      setStepsIndex(0); // Optionally reset the step index to start from the beginning next time
    }
  };
  const handleAppClick = (e) => {
    const accountMenu = document.getElementById("account-container");
    const notifications = document.getElementById("notifications-container");
    if (!accountMenu.contains(e.target) && displayButtons === true) {
      setDisplayButtons(false);
    }
    if (!notifications.contains(e.target) && displayNotifications === true) {
      setDisplayNotifications(false);
    }
  };
  const handleAnswer = async (answer) => {
    setLoader(true);
    try {
      let response = await authReq({
        url: "/cookie_answer",
        method: "POST",
        data: { answer },
      });
      localStorage.setItem("jwt_user", response.data.token);
      setUser(response.data.user);
      setLoader(false);
      setShowCookies(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
      setShowCookies(false);
      alert(
        "Hubo un error al guardar tu respuesta. Por favor, intenta más tarde."
      );
      sendErrorReport("App - handleAnswer", error);
    }
  };
  const locale = {
    // Customize texts for other buttons as needed
    last: "Finalizar",
    skip: "Saltar",
    next: "Siguiente",
    back: "Atrás",
    close: "Cerrar",
  };
  const returnPages = () => {
    if (!checkedForToken) return <></>;
    if (isReset) return <ResetPassword />;
    if (isJoin) return <ClassCreate />;
    if (!user) return <Auth />;
    if (!user.emailVerified) return <EmailCode />;

    return (
      <>
        {width >= 1250 && (
          <Joyride
            run={runTour}
            steps={steps}
            continuous={true}
            locale={locale}
            scrollToFirstStep={true}
            disableScrolling={true}
            showProgress={true}
            showSkipButton={true}
            hideCloseButton={true}
            disableOverlay={stepsIndex !== 0}
            callback={handleJoyrideCallback}
            stepIndex={stepsIndex}
            styles={{
              options: {
                zIndex: 10000,
              },
            }}
          />
        )}

        <Sidebar />
        <div className={`app-content padding-bottom`} onClick={handleAppClick}>
          <Navbar />
          <div className="page page-padding">
            <div className="beta-msg">Versión Beta</div>
            <DynamicBreadcrumbs />
            {/* <MessagesContainer /> */}
            <Elements stripe={stripePromise}>
              <Routes>
                <Route
                  path="/"
                  element={<LoggedInRoute Component={Dashboard} />}
                />
                <Route
                  path="/Mi Estudio/Subir"
                  element={<PodcastEntryForm isUpload={true} />}
                />
                <Route
                  path="/Mi Estudio/editar"
                  element={<SelectEdit isUpload={false} />}
                />
                <Route
                  path="/Mi Estudio/editar/:id"
                  element={<PodcastEntryForm isUpload={false} />}
                />
                <Route
                  path="/libreria"
                  element={<LoggedInRoute Component={Library} />}
                />
                <Route
                  path="/perfil/:id/follow/:isFollowed"
                  element={<LoggedInRoute Component={Profile} />}
                />
                <Route
                  path="/perfil/:id"
                  element={<LoggedInRoute Component={Profile} />}
                />
                <Route
                  path="/perfil/editar/"
                  element={<LoggedInRoute Component={EditProfile} />}
                />
                <Route
                  path="/podcast/:podcastId/"
                  element={<LoggedInRoute Component={PodcastDetails} />}
                />
                <Route
                  path="/Search/:searchValue"
                  element={<LoggedInRoute Component={SearchPage} />}
                />
                {/* <Route
                    path="/edit"
                    element={<LoggedInRoute Component={SelectEdit} />}
                  />
                  <Route
                    path="/edit/:id"
                    element={<LoggedInRoute Component={EditPodcastPage} />}
                  /> */}
                <Route
                  path="/clase/miembros"
                  element={<LoggedInRoute Component={ClassProfile} />}
                />
                <Route
                  path="/clase"
                  element={<LoggedInRoute Component={ClassProfile} />}
                />
                <Route
                  path="/clase/crear"
                  element={<LoggedInRoute Component={ClassCreate} />}
                />
                <Route
                  path="/clase/unirse"
                  element={<LoggedInRoute Component={ClassCreate} />}
                />
                <Route
                  path="/clase/:classCode"
                  element={<LoggedInRoute Component={ClassProfile} />}
                />
                <Route
                  path="/suscripción"
                  element={<LoggedInRoute Component={FeatureComparisonTable} />}
                />
                <Route
                  path="/Informar error"
                  element={<LoggedInRoute Component={BugReportPage} />}
                />
                <Route
                  path="/contacto"
                  element={<LoggedInRoute Component={BugReportPage} />}
                />
                <Route path="/notificaciones" element={<Notifications />} />

                <Route path="/*" element={<Navigate to="/" />} />
              </Routes>
            </Elements>
          </div>
          {/* <RightBar /> */}
          <AudioPlayer />
          <MessageModal />
          <div className={`bottom-bar ${isMobile ? "enable-bottom" : ""}`}>
            <IconButton onClick={() => handleSearchClick()}>
              <Search className="icon-bottom" />
            </IconButton>
            <IconButton onClick={() => navigate("/")}>
              <Home className="icon-bottom" />
            </IconButton>
            <div style={{ width: "50px" }}></div>
            <div style={{ width: "50px" }}></div>
          </div>
        </div>
        {showCookies && (
          <div id="cookie-bar">
            <p>
              En Playpod valoramos tu privacidad. Por favor, lee nuestra{" "}
              <a target="_" href="/Política_de_Cookies_Web.pdf">
                Política de Cookies
              </a>{" "}
              para entender cómo las usamos y aceptalas para un mejor
              funcionamiento de la app.{" "}
            </p>

            <div id="cookie-buttons">
              <button
                className="teal-button"
                onClick={() => handleAnswer(true)}
              >
                Aceptar
              </button>
              <button onClick={() => handleAnswer(false)}>Rechazar</button>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <LoaderModal />
      <PremiumFeatureModal />
      {returnPages()}
    </>
  );
}

export default App;
