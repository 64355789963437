import { useState } from "react";
import "./index.css";
import { useParams, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { LoaderContext, PodcastContext } from "../../contexts";
import { useEffect } from "react";
import Card from "../../components/card";
import { Skeleton } from "@mui/material";
import { authReq } from "../../utilities/requests";

const Search = () => {
  let navigate = useNavigate();
  let { searchValue } = useParams();
  const { searchedPodcasts, setSearchedPodcasts, searchState, setSearchState } =
    useContext(PodcastContext);
  const [callMade, setCallMade] = useState(false);
  const { loader, setLoader, search, setSearch } = useContext(LoaderContext);

  if (!searchValue && searchState) {
    navigate(`/search/${searchState}`);
  }
  // setSearchState(searchValue);

  useEffect(() => {
    setCallMade(false);
    setLoader(true);
    authReq({ url: `/get_podcasts/search/?searchValue=${searchValue}` }).then(
      (res) => {
        setSearchedPodcasts(res.data);
        setCallMade(true);
        setLoader(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    setSearch("");
  }, []);

  const returnCards = () => {
    if (!callMade) {
      return [1, 2, 3, 4, 5].map((number) => (
        <Skeleton key={number} variant="rectangular" width={180} height={208} />
      ));
    } else if (searchedPodcasts?.length) {
      return searchedPodcasts.map((data) => (
        <Card search={true} key={JSON.stringify(data)} data={data} />
      ));
    }
  };

  return (
    <div className="">
      <div id="upper-text-container">
        <div id="greeting">Resultados de la búsqueda para "{searchValue}"</div>
        <div className="sub-heading">
          {callMade && !searchedPodcasts?.length && (
            <div>
              ¡Vaya! Nada coincide con tu búsqueda.
              <div>
                Si no encuentras tu podcast, puede ser que no esté publicado
                todavía. Tu pofesor publicará el podcast pronto. Puedes ver tus
                podcasts no publicados en tu perfil.
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="card-container">{returnCards()}</div>
    </div>
  );
};

export default Search;
