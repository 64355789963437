import React, { useContext } from "react";
import "./index.css";
import StarIcon from "@mui/icons-material/Star";
import { UserContext } from "../../contexts";
const PremiumIcon = ({ top }) => {
  const { setPremiumModal } = useContext(UserContext);
  return (
    <div
      style={{ position: "absolute", right: "-24px", top: top || "8px" }}
      onClick={(e) => setPremiumModal(true)}
    >
      <div className="premium" title="Solo función premium">
        <StarIcon className="star" />
      </div>
    </div>
  );
};

export default PremiumIcon;
