import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { LoaderContext, PodcastContext, UserContext } from "../../contexts";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Button } from "@mui/material";
// import { logDOM } from '@testing-library/react';
import "./index.css";
import { authReq } from "../../utilities/requests";

const MessagesContainer = ({
  displayNotifications,
  setDisplayNotifications,
}) => {
  const { messages, setMessages } = useContext(PodcastContext);
  const { setUser } = useContext(UserContext);
  const { isMobile, setLoader } = useContext(LoaderContext);

  const handleClose = (idToDelete) => {
    let newMessages = messages.filter((msg) => msg.id !== idToDelete);
    setMessages(newMessages);
  };
  // here check for new class invitations
  useEffect(() => {
    // sync messages with local storage but first check if there are any messages in local storage
  }, []);
  useEffect(() => {
    // self invoking async function
    const localMessages = localStorage.getItem("messages");
    let parsedMessages = [];
    if (localMessages) {
      let parsed = JSON.parse(localMessages);
      parsedMessages = [...parsed];
    }
    (async () => {
      let res = await authReq({
        method: "get",
        url: "/get_class_invitations",
      });
      if (res.data.length) {
        let newMessages = [...messages, ...parsedMessages];
        // filter for same id
        let filteredMessages = newMessages.filter(
          (msg) => !res.data.find((invitation) => invitation.id === msg.id)
        );
        newMessages = [...filteredMessages];
        res.data.forEach((invitation) => {
          // only push if oen with same id does not exist
          if (!newMessages.find((msg) => msg.id === invitation.id)) {
            console.log(invitation);
            newMessages.push({
              id: invitation.id,
              type: "invitation",
              content: `Has sido invitado a unirte a la clase ${invitation.name} `,
              nameOfClass: invitation.name,
              classCode: invitation.class,
            });
          }
        });
        // sort
        setMessages(newMessages);
        localStorage.setItem("messages", JSON.stringify(newMessages));
      }
    })();
  }, []);
  const returnProfileLink = (person) => {
    return (
      <Link to={`/perfil/${person?.id}`} className="podcast-creator">
        {person.firstName}
      </Link>
    );
  };

  const returnPodcastLink = (data) => {
    return (
      <Link to={`/podcast/${data.id}`} className="podcast-creator">
        {data.title}
      </Link>
    );
  };

  let messagesHTML = messages.map((msg) => {
    let uniqueHTML;
    switch (msg.type) {
      case "like":
        uniqueHTML = (
          <div className="message-text">
            Te gustó {returnPodcastLink(msg.podcast)} de
            {returnProfileLink(msg.person)}.
          </div>
        );
        break;
      case "unlike":
        uniqueHTML = (
          <div className="message-text">
            Eliminaste {returnPodcastLink(msg.podcast)} de
            {returnProfileLink(msg.person)} de tus favoritos.
          </div>
        );
        break;
      case "save":
        uniqueHTML = (
          <div className="message-text">
            Guardaste {returnPodcastLink(msg.podcast)} de
            {returnProfileLink(msg.person)}.
          </div>
        );
        break;
      case "unsave":
        uniqueHTML = (
          <div className="message-text">
            Eliminaste {returnPodcastLink(msg.podcast)} de
            {returnProfileLink(msg.person)} de tus guardados.
          </div>
        );
        break;
      case "delete_podcast":
        uniqueHTML = <div className="message-text">{msg.content}</div>;
        break;
      case "upload_podcast_no_classCode":
        uniqueHTML = (
          <div className="message-text">
            Tu podcast, {returnPodcastLink(msg.podcast)}, será publicado cuando
            seas parte de una clase. Pide el código de clase a tu profesor.
          </div>
        );
        break;

      case "upload_podcast_not_admin":
        uniqueHTML = (
          <div className="message-text">
            Tu podcast, {returnPodcastLink(msg.podcast)}, se publicará cuando tu
            profesor lo apruebe.
          </div>
        );
        break;
      case "upload_podcast_is_admin":
        uniqueHTML = (
          <div className="message-text">
            Tu podcast, {returnPodcastLink(msg.podcast)}, ha sido publicado.
          </div>
        );
        break;
      case "follow":
        uniqueHTML = (
          <div className="message-text">
            <div>
              {msg.content}{" "}
              <Link
                style={{ marginLeft: "4px" }}
                to={`/perfil/${msg.user.id}/?clickedUser=${JSON.stringify(
                  msg.user
                )}`}
              >
                {" "}
                {msg.user.firstName}
              </Link>
            </div>
          </div>
        );
        break;
      case "invitation":
        uniqueHTML = (
          <div className="message-text">
            {msg.content}
            <Link to={`/clase/${msg.classCode}`}>{msg.name}</Link>
            <div className="d-flex flex-column flex-md-row gap-2 align-items-start">
              <button
                className="accept-button "
                onClick={() => handleResponse("accept", msg)}
              >
                Aceptar
              </button>
              <button
                className="standard-button"
                onClick={() => handleResponse("decline", msg)}
              >
                Declinar
              </button>
            </div>
          </div>
        );
        break;
      case "invitation_response":
        uniqueHTML = (
          <div className="message-text">
            {msg.content}
            <Link to={`/clase/${msg.classCode}`}>{msg.name}</Link>
          </div>
        );
        break;
      default:
        uniqueHTML = null;
        break;
    }

    return (
      <li className="message" key={JSON.stringify(msg)}>
        <>{uniqueHTML}</>
        <HighlightOffIcon
          className="close-notification"
          variant="text"
          color="themeOrange"
          size="medium"
          onClick={() => handleClose(msg.id)}
        />
      </li>
    );
  });
  async function handleResponse(type, msg) {
    setLoader(true);
    let response = await authReq({
      method: "post",
      url: `/respond_class_invitation/${type}`,
      data: {
        classCode: msg.classCode,
      },
    });
    if (type === "accept") {
      localStorage.setItem("jwt_user", response.data.userToken);
      setUser(response.data.user);
    }
    // set localStorage with new token here!
    // remove the message from messages and local storage
    let newMessages = messages.filter((message) => message.id !== msg.id);

    newMessages.push({
      id: msg.id,
      type: "invitation_response",
      content: `Has ${
        type === "accept" ? " aceptado " : " rechazado "
      } la invitación a unirte a la clase `,
      nameOfClass: msg.nameOfClass,
      classCode: msg.classCode,
    });

    setMessages(newMessages);
    setLoader(false);

    localStorage.setItem("messages", JSON.stringify(newMessages));
  }
  const deleteMessages = () => {
    setMessages([]);
    localStorage.setItem("messages", JSON.stringify([]));
    setDisplayNotifications(false);
  };
  return (
    <ul
      id="messages-container"
      className={`messages-container ${
        displayNotifications ? "" : "no-display"
      } ${isMobile ? "mobile-messages" : ""}`}
    >
      {messages.length ? (
        <div className="notifications-tools-container">
          {/* <div className="button-box">
            <Button onClick={handleSeen}>visto</Button>
          </div> */}
          <div className="button-box">
            <Button onClick={deleteMessages}>Quitar todo</Button>
          </div>
        </div>
      ) : (
        <div style={{ color: "black", width: "max-content", padding: "8px" }}>
          ¡No hay notificaciones!{" "}
        </div>
      )}
      {messagesHTML}
    </ul>
  );
};

export default MessagesContainer;
