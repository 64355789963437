import React from "react";
import { IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import "./index.css";

const Title = ({ page, setPage }) => {
  let target =
    page === "Registrar" || page === "Password"
      ? "Iniciar sesión"
      : "Registrar";
  return (
    <div className="mini-title">
      <button
        className="arrow-icon-button switch-container"
        onClick={() => setPage(target)}
      >
        <ArrowBackIosNewIcon />
        <div className="back-text">{target}</div>
      </button>

      <h2 id="auth-title">{page}</h2>
    </div>
  );
};

export default Title;
