import React, { useContext, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Paper,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { authReq, sendErrorReport } from "../../utilities/requests";
import { LoaderContext, UserContext } from "../../contexts";

const CustomerForm = ({ selectedPlan }) => {
  const { setCustomerData, user } = useContext(UserContext);
  const { setLoader } = useContext(LoaderContext);
  const countries = [
    { label: "España", code: "ES" },
    { label: "Estados Unidos", code: "US" },
    { label: "México", code: "MX" },
  ];
  const [formData, setFormData] = useState({
    email: user.email,
    name: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    postalCode: "",
    country: countries[0].code,
  });

  const [error, setError] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoader(true);
    try {
      let res = await authReq({
        url: "/create_customer",
        method: "POST",
        data: { userInfo: formData, selectedPlan },
      });
      if (!res.data.success) return setError(res.status.message);
      setLoader(false);
      setCustomerData(res.data);
    } catch (error) {
      sendErrorReport("Customer Form Submit", error);
      setError(error.message);
      setLoader(false);
    }

    // Add API call or other submission logic here
  };

  return (
    <Paper style={{ padding: 16 }} component="form" onSubmit={handleSubmit}>
      {error && <div>{error}</div>}
      <Grid container alignItems="flex-start" spacing={2}>
        <Grid item xs={12}>
          <TextField
            name="email"
            label="Correo electrónico"
            fullWidth
            disabled={true}
            value={formData.email}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="name"
            label="Nombre y apellidos"
            fullWidth
            value={formData.name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="addressLine1"
            label="Dirección línea 1"
            fullWidth
            value={formData.addressLine1}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="addressLine2"
            label="Dirección línea 2"
            fullWidth
            value={formData.addressLine2}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="city"
            label="Ciudad"
            fullWidth
            value={formData.city}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="state"
            label="Estado o región"
            fullWidth
            value={formData.state}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="postalCode"
            label="Código postal"
            fullWidth
            value={formData.postalCode}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper
            style={{ padding: 16 }}
            component="form"
            onSubmit={handleSubmit}
          >
            {/* Existing code... */}
            <Grid item xs={12}>
              <InputLabel id="country-label">País</InputLabel>
              <Select
                labelId="country-label"
                name="country"
                fullWidth
                value={formData.country}
                onChange={handleChange}
              >
                {countries.map((country, index) => (
                  <MenuItem key={index} value={country.code}>
                    {country.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            {/* Existing code... */}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" type="submit">
            Enviar
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};
export default CustomerForm;
