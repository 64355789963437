import React, { useState, createContext, useContext } from "react";
import { useEffect } from "react";
import { authReq, sendErrorReport } from "../utilities/requests";
import { LoaderContext } from "./loaderContext";
import { UserContext } from "./userContext";

export const PodcastContext = createContext();

export function PodcastContextProvider(props) {
  // Cache is array of arrays (pages of 10). each page is a sub array.
  const [podcastCache, setPodcastCache] = useState(null);
  const [arrayOfAllPodcasts, setArrayOfAllPodcasts] = useState([]);
  const { setLoader } = useContext(LoaderContext);
  const { user } = useContext(UserContext);
  useEffect(() => {
    if (podcastCache) {
      const mergedArray = Object.values(podcastCache).reduce(
        (acc, currArray) => {
          return acc.concat(currArray);
        },
        []
      );

      setArrayOfAllPodcasts(mergedArray);
    }
  }, [podcastCache]);

  const [likedPodcastData, setLikedPodcastData] = useState([]);
  const [savedPodcastData, setSavedPodcastData] = useState([]);
  const [myClass, setMyClass] = useState({
    audios: [],
    numberOfUsers: null,
    user: [],
  });
  console.log({ myClass });

  const [otherClass, setOtherClass] = useState(null);
  // results of searched / filtered.
  // pages of 10. Each page is a sub array
  const [searchedPodcasts, setSearchedPodcasts] = useState([]);
  const [usersPodcasts, setUsersPodcasts] = useState([]);
  const [userSeries, setUserSeries] = useState([]);
  const [audio, setAudio] = useState(null);
  const [messages, setMessages] = useState([]);
  const [searchState, setSearchState] = useState(null);
  const makeRequests = async (classCode, classSetState) => {
    setLoader(true);
    try {
      let response = await authReq({
        url: `/get_class_podcasts/${classCode}`,
        method: "POST",
      });
      setLoader(false);
      classSetState({
        ...response.data,
      });
    } catch (error) {
      setLoader(false);
      sendErrorReport("ClassProfile - effect", error);

      console.log(error);
    }
  };

  useEffect(() => {
    // sync messages with local storage but first check if there are any messages in local storage
    if (messages.length > 0) {
      localStorage.setItem("messages", JSON.stringify(messages));
    }
  }, [messages]);
  useEffect(() => {
    if (user && user.classCode) {
      // sync messages with local storage but first check if there are any messages in local storage
      makeRequests(user.classCode, setMyClass);
    }
  }, [user]);

  // useEffect(() => {
  //   const seriesArray = usersPodcasts.map((podcast) => podcast.series);
  //   const unique = seriesArray.filter(
  //     (value, index, self) => self.indexOf(value) === index
  //   );
  //   setUsersSeries(unique);
  // }, [usersPodcasts]);

  return (
    <PodcastContext.Provider
      value={{
        podcastCache,
        setPodcastCache,
        myClass,
        setMyClass,
        otherClass,
        setOtherClass,
        arrayOfAllPodcasts,
        setArrayOfAllPodcasts,
        makeRequests,
        audio,
        setAudio,

        searchedPodcasts,
        setSearchedPodcasts,

        usersPodcasts,
        setUsersPodcasts,
        userSeries,
        setUserSeries,
        messages,
        setMessages,

        searchState,
        setSearchState,

        likedPodcastData,
        setLikedPodcastData,
        savedPodcastData,
        setSavedPodcastData,
      }}
    >
      {props.children}
    </PodcastContext.Provider>
  );
}
