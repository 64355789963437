import React, { useState } from "react";
import { authReq, sendErrorReport } from "../../utilities/requests";
// Importa aquí tu función authReq
// import { authReq } from './path_to_authReq';
import "./index.css";
const ReportBugPage = () => {
  // Estados para manejar la información del formulario
  // get path
  const currentPath = window.location.pathname;
  let isContactPage = currentPath.includes("/contacto");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");

  // Manejador para el envío del formulario
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Objeto con la información a enviar
      const bugReport = {
        email: email,
        description: description,
        isContactPage,
      };

      // Llamada a la función authReq para enviar el reporte

      await authReq({ url: "/report_bug", method: "post", data: bugReport });

      // Resetear el formulario o manejar el éxito como prefieras
      setEmail("");
      setDescription("");
      alert("Enviado exitosamente");
    } catch (error) {
      sendErrorReport("bugreport", error);

      // Manejar el error
      alert("Hubo un error al enviar.");
    }
  };

  return (
    <div className="report-container">
      <h1 className="report-title">
        {isContactPage
          ? "Póngate en contacto con Playpod"
          : "Informar de un error"}
      </h1>
      <form onSubmit={handleSubmit} className="report-form">
        <div className="form-group">
          <label htmlFor="email" className="form-label">
            Correo electrónico:
          </label>
          <input
            type="email"
            id="email"
            className="form-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="description" className="form-label">
            {isContactPage ? "Mensaje" : "Descripción del error:"}
          </label>
          <textarea
            id="description"
            className="form-textarea"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="submit-button">
          Enviar
        </button>
      </form>
    </div>
  );
};

export default ReportBugPage;
