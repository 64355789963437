import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { LoaderContext, PodcastContext } from "../../contexts";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Button } from "@mui/material";
// import { logDOM } from '@testing-library/react';
import "./index.css";

const Notifications = () => {
  const { messages, setMessages } = useContext(PodcastContext);
  const { displayNotifications, setDisplayNotifications, isMobile } =
    useContext(LoaderContext);
  const handleClose = (idToDelete) => {
    let newMessages = messages.filter((msg) => msg.id !== idToDelete);
    setMessages(newMessages);
  };

  useEffect(() => {
    setDisplayNotifications(false);
  }, [displayNotifications]);

  const returnProfileLink = (person) => {
    return (
      <Link to={`/perfil/${person?.id}`} className="podcast-creator">
        {person.firstName}
      </Link>
    );
  };

  const returnPodcastLink = (data) => {
    return (
      <Link to={`/podcast/${data.id}`} className="podcast-creator">
        {data.title}
      </Link>
    );
  };

  let messagesHTML = messages.map((msg) => {
    let uniqueHTML;
    switch (msg.type) {
      case "like":
        uniqueHTML = (
          <div className="message-text">
            Te gustó {returnPodcastLink(msg.podcast)}
            {!isMobile && (
              <>
                <span>por </span>
                {returnProfileLink(msg.person)}
              </>
            )}
          </div>
        );
        break;
      case "unlike":
        uniqueHTML = (
          <div className="message-text">
            Eliminaste {returnPodcastLink(msg.podcast)} de tus favoritos
            {!isMobile && (
              <>
                <span>por </span>
                {returnProfileLink(msg.person)}
              </>
            )}
          </div>
        );
        break;
      case "save":
        uniqueHTML = (
          <div className="message-text">
            Guardaste {returnPodcastLink(msg.podcast)}
            {!isMobile && (
              <>
                <span>por </span>
                {returnProfileLink(msg.person)}
              </>
            )}
          </div>
        );
        break;
      case "unsave":
        uniqueHTML = (
          <div className="message-text">
            Eliminaste {returnPodcastLink(msg.podcast)} de tus guardados
            {!isMobile && (
              <>
                <span>por </span>
                {returnProfileLink(msg.person)}
              </>
            )}
          </div>
        );
        break;
      case "delete_podcast":
        uniqueHTML = <div className="message-text">{msg.content}</div>;
        break;
      case "upload_podcast_no_classCode":
        uniqueHTML = (
          <div className="message-text">
            Tu podcast, {returnPodcastLink(msg.podcast)}, no se publicará hasta
            que formes parte de una clase.
          </div>
        );
        break;

      case "upload_podcast_not_admin":
        uniqueHTML = (
          <div className="message-text">
            Tu podcast, {returnPodcastLink(msg.podcast)}, no se publicará hasta
            su aprobación.
          </div>
        );
        break;
      case "upload_podcast_is_admin":
        uniqueHTML = (
          <div className="message-text">
            Tu podcast, {returnPodcastLink(msg.podcast)}, ha sido publicado.
          </div>
        );
        break;
      case "follow":
        uniqueHTML = <div className="message-text">{msg.content}</div>;
        break;

      default:
        uniqueHTML = null;
        break;
    }

    return (
      <li className="message" key={JSON.stringify(msg)}>
        <>{uniqueHTML}</>
        <HighlightOffIcon
          className="close-notification"
          variant="text"
          color="themeOrange"
          size="medium"
          onClick={() => handleClose(msg.id)}
        />
      </li>
    );
  });
  const deleteMessages = () => {
    setMessages([]);
    localStorage.setItem("messages", JSON.stringify([]));
  };
  return (
    <ul className="messages-container messages-page">
      {messages.length ? (
        <div className="button-box">
          <Button onClick={deleteMessages}>Quitar todo</Button>
        </div>
      ) : (
        <div style={{ padding: "15px", margin: "15px" }}>
          No hay notificaciones
        </div>
      )}
      {messagesHTML}
    </ul>
  );
};

export default Notifications;
