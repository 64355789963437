import React from "react";
import { useNavigate } from "react-router-dom";

const StudioHeader = ({ isUpload, subtitle }) => {
  const navigate = useNavigate();
  return (
    <div>
      <h1 className="mi-estudio">Mi estudio</h1>
      <div className="studio-buttons-container my-3 mb-5">
        <div
          className={isUpload ? "active-button" : ""}
          onClick={() => navigate("/Mi Estudio/subir")}
        >
          Crear
        </div>
        <div
          className={!isUpload ? "active-button" : ""}
          onClick={() => navigate("/Mi Estudio/editar")}
        >
          Editar
        </div>
      </div>
      <h3>{subtitle}</h3>
    </div>
  );
};

export default StudioHeader;
