import axios from "axios";
export const authReq = async (axiosObject) => {
  const userToken = localStorage.getItem("jwt_user");
  let planToken = localStorage.getItem("plan");
  return await axios({
    ...axiosObject,
    headers: {
      Authorization: userToken,
      PlanToken: planToken,
    },
  });
};
export const generateAlphanumericCode = () => {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let code = "";
  for (let i = 0; i < 8; i++) {
    code += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return code;
};

export const getClass = async (classCode, setMyClass, setLoader) => {
  setLoader(true);
  let response = await authReq({
    url: `/get_class_podcasts/${classCode}`,
    method: "POST",
  });
  setLoader(false);
  setMyClass({
    ...response.data,
  });
};
export const sendErrorReport = async (locationString, err) => {
  await authReq({
    url: "/record_error",
    method: "POST",
    data: { locationString, err },
  });
};
