import React, { useContext, useEffect, useRef, useState } from "react";
import { LoaderContext, UserContext } from "../../contexts";
import { authReq } from "../../utilities/requests";
import { Close, HighlightOff } from "@mui/icons-material";
import "./index.css";
import { IconButton } from "@mui/material";

const AddClassMembers = ({ classCode, setInviteToggle, classState }) => {
  const { user } = useContext(UserContext);
  const { setLoader } = useContext(LoaderContext);
  let contextUser = user;
  const [inviteSearch, setInviteSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [invitedUsers, setInvitedUsers] = useState([]); // This will store users and their roles
  const [isAddNewUser, setIsAddNewUser] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [status, setStatus] = useState(false);
  let maxUsers = 20;

  const [newUser, setNewUser] = useState({
    email: inviteSearch,
    firstName: "",
    lastName: "",
  });
  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    if (!status) return;
    setTimeout(() => {
      setStatus(false);
    }, 5000);
  }, [status]);

  useEffect(() => {
    if (users.length === 0 && isValidEmail(inviteSearch)) {
      setTimeout(() => {
        setIsAddNewUser(true);
      }, 500);
    } else {
      setIsAddNewUser(false);
    }
  }, [users, inviteSearch]);

  const debounceRef = useRef();

  useEffect(() => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = setTimeout(async () => {
      if (!inviteSearch) return setUsers([]);
      let response = await authReq({
        url: `/get_user_by_email/${inviteSearch}`,
        method: "POST",
      });

      if (response.data.id) {
        setUsers([{ ...response.data, email: inviteSearch }]);
      } else {
        setUsers([]);
      }
    }, 500);

    return () => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
    };
  }, [inviteSearch]);
  const failUsers = () => {
    setErrorMsg(
      `De Momento las clases no pueden tener más de ${maxUsers} miembros. Elimina a alguien antes de invitar a alguien nuevo. Si necesitas más plazas, por favor, ponte en contacto con nosotros.`
    );
  };

  const handleUserInvite = (user) => {
    if (classState.numberOfUsers + invitedUsers.length >= maxUsers) {
      return failUsers();
    }
    if (!invitedUsers.some((u) => u.id === user.id) || !user.id) {
      setInvitedUsers([...invitedUsers, { ...user, isClassAdmin: false }]);
    }
    setNewUser({
      email: "",
      firstName: "",
      lastName: "",
    });
    setIsAddNewUser(false);
    setInviteSearch("");
  };

  const setRole = (userEmail, isAdmin, userId) => {
    let newUsers;

    if (userEmail) {
      newUsers = invitedUsers.map((u) =>
        u.email === userEmail ? { ...u, isClassAdmin: isAdmin } : u
      );
    } else {
      newUsers = invitedUsers.map((u) =>
        u.id === userId ? { ...u, isClassAdmin: isAdmin } : u
      );
    }
    setInvitedUsers(newUsers);
    setErrorMsg("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // make sure there are users
    if (invitedUsers.length === 0)
      return setErrorMsg("No hay usuarios para invitar.");

    if (classState.numberOfUsers + invitedUsers.length > maxUsers)
      return failUsers();

    // Check if any user has isClassAdmin as undefined
    const hasUndefinedRole = invitedUsers.some(
      (user) => user.isClassAdmin === undefined
    );

    if (hasUndefinedRole)
      return setErrorMsg(
        "Por favor, asigne un rol a cada usuario invitado.   "
      );
    setLoader(true);
    let response = await authReq({
      url: "/add_class_member",
      method: "POST",
      data: {
        invitedUsers,
        classCode,
      },
    });
    setLoader(false);

    if (response.status > 199 && response.status < 300) {
      setStatus(true);
      setUsers([]);
      setInvitedUsers([]);
      setInviteToggle((prev) => !prev);
      // add to state array!!!!!
    }
  };

  const handleRemoveUser = (email) => {
    setInvitedUsers((prevUsers) => prevUsers.filter((u) => u.email !== email));
  };

  const handleSearchChange = (e) => {
    setInviteSearch(e.target.value);
    setErrorMsg("");
    setNewUser({
      ...newUser,
      email: e.target.value,
    });
  };

  return (
    <div>
      {/* ... other input elements ... */}

      <div className="class-form-inputs">
        <label>
          Añade los correos electrónicos de los alumnos y otros profesores de tu
          escuela:
          <input
            type="text"
            value={inviteSearch}
            onChange={handleSearchChange}
            placeholder="Buscar para invitar..."
          />
        </label>
        {errorMsg && (
          <div className="error-flex">
            {" "}
            <IconButton onClick={() => setErrorMsg("")}>
              <Close />
            </IconButton>{" "}
            <p style={{ color: "red" }}>{errorMsg}</p>
          </div>
        )}

        {isAddNewUser && (
          <div className="add-new-user-form">
            <h3>
              No se encontró ningún usuario con este correo electrónico.
              ¡Envíales una invitación!
            </h3>
            <p>{inviteSearch}</p>
            <button
              onClick={() => handleUserInvite(newUser)}
              style={{ backgroundColor: "lightgrey" }}
            >
              Agregar usuario
            </button>
          </div>
        )}

        <ul id="searched-ul">
          {users.map((user) => (
            <li key={user.id} onClick={() => handleUserInvite(user)}>
              {!user.firstName && user.classCode !== contextUser.classCode
                ? inviteSearch + " -  haz clic para invitar"
                : user.classCode === contextUser.classCode
                ? `${inviteSearch} ya ha sido invitada, pero aún no ha creado una cuenta.`
                : user.firstName +
                  " " +
                  user.lastName +
                  " - haz clic para invitar"}
            </li>
          ))}
        </ul>
      </div>

      <div>
        {/* {invitedUsers.length > 0 && <h4>Usuarios para invitar:</h4>} */}
        <ul id="users-ul">
          {invitedUsers.map((user) => (
            <li key={user.id || user.email} className={`selected-user`}>
              <div>
                <HighlightOff
                  className="close-button"
                  onClick={() => handleRemoveUser(user.email)}
                />
                <span className="user-name">{user.email}</span>
                <span>
                  {user.firstName ? (
                    <span>
                      {" "}
                      {user.firstName} {user.lastName}{" "}
                    </span>
                  ) : (
                    <></>
                  )}
                </span>
              </div>
              <div>
                <button
                  onClick={() =>
                    alert(
                      "De momento solo puede ser un profesor por clase (tu)"
                    )
                  }
                  className={`${user.isClassAdmin ? "active" : ""}`}
                >
                  Profesor
                </button>
                <button
                  onClick={() =>
                    alert(
                      "De momento no se puede cambiar el rol. Pronto habrá más opciones."
                    )
                  }
                  className={`${!user.isClassAdmin ? "active" : ""}`}
                >
                  Estudiante
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div>
        <button onClick={handleSubmit}>Enviar invitaciones</button>
      </div>
      {status && (
        <div className="success-msg">¡Los usuarios han sido invitados!</div>
      )}
    </div>
  );
};

export default AddClassMembers;
