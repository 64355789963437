import React, { useContext } from "react";
import "./index.css";
import { UserContext } from "../../contexts";
import { Navigate } from "react-router-dom";

const LoggedInRoute = ({ Component, ...rest }) => {
  const { user } = useContext(UserContext);
  return (
    <div id="loggedin-inner-container">
      <div className="component-container w-100">
        {user ? <Component {...rest} /> : <Navigate to="/" />}
      </div>
    </div>
  );
};

export default LoggedInRoute;
