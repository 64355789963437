import { useEffect, useState, useContext, useRef } from "react";
import "./index.css";
import { LoaderContext, PodcastContext, UserContext } from "../../contexts";
import { useNavigate, useParams } from "react-router-dom";
import ReactLoading from "react-loading";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import axios from "axios";
import { authReq, sendErrorReport } from "../../utilities/requests";
import { v4 as uuidv4 } from "uuid";
import {
  AudioFile,
  UploadFile,
  AddBox,
  Close,
  DisabledByDefault,
} from "@mui/icons-material";
import {
  TextField,
  InputLabel,
  IconButton,
  Button,
  Modal,
  Typography,
  Box,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import PremiumIcon from "../../components/premiumIcon";
import ImageIcon from "@mui/icons-material/Image";
import StudioHeader from "../../components/studioHeader";

const PodcastEntryForm = ({ isUpload }) => {
  // this component is either for uploading a podcast or editing it.
  const navigate = useNavigate();
  const params = useParams();

  const CHARACTER_LIMIT = 250;
  const { user, plan, setPremiumModal, userPodcasts } = useContext(UserContext);
  const { messageState, setMessageState, setLoader, languages } =
    useContext(LoaderContext);

  const {
    arrayOfAllPodcasts,
    setArrayOfAllPodcasts,
    usersPodcasts,
    setUsersPodcasts,
    setMessages,
    userSeries,
    podcastCache,
    setPodcastCache,
  } = useContext(PodcastContext);

  const [saved, setSaved] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteValue, setDeleteValue] = useState("");
  const [deleteError, setDeleteError] = useState(false);

  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [previousName, setPreviousName] = useState("");
  const [returnedUsers, setReturnedUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [audioId, setAudioID] = useState(false);
  const [pdfId, setPdfId] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showHover, setShowHover] = useState(true);
  const [showSubmit, setShowSubmit] = useState(false);
  const [podcastData, setPodcastData] = useState({
    title: "",
    subject: "",
    classLevel: "",
    series: "",
    newSeries: "",
    seriesId: "",
    description: "",
    childContent: true,
    languageCode: "es-ES", // Add this line
    idOfNewSeries: uuidv4(),
  });
  const [imageUploadSuccess, setImageUploadSuccess] = useState(false);
  const [errors, setErrors] = useState({
    noTitle: null,
    shortTitle: null,
    noSubject: null,
    noLevel: null,
    noSeries: null,
    noParticipants: null,
    shortDescription: null,
    noAudio: null,
  });
  const [modal, setModal] = useState(false);
  const subjects = [
    "Ciencia",
    "Historia",
    "Idiomas",
    "Eventos Actuales",
    "Arte y Cultura",
    "Tecnología y Innovación",
    "Salud y Bienestar",
    "Ciencias Ambientales y Sostenibilidad",
    "Política y Sociedad",
    "Economía y Negocios",
    "Educación y Aprendizaje",
    "Filosofía y Ética",
    "Matemáticas y Lógica",
    "Deportes y Recreación",
    "Viajes y Geografía",
    "Astronomía y Espacio",
  ];
  const uploaderRef = useRef(null); // img
  const uploaderRef2 = useRef(null); // pdf
  const uploaderRef3 = useRef(null); // audio
  useEffect(() => {
    if (isUpload) return;
    let podcastArray = usersPodcasts.filter((e) => e.id === params.id);
    if (podcastArray.length > 0) {
      const fixedObject = {
        ...podcastArray[0],
        classLevel: podcastArray[0].classLevel,
      };
      setPodcastData(fixedObject);
    } else {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    // get podcast data from local storage
    let podcastDataFromStorage = localStorage.getItem("podcastData");
    if (podcastDataFromStorage) {
      podcastDataFromStorage = JSON.parse(podcastDataFromStorage);
      setPodcastData(podcastDataFromStorage);
    }
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setSaved(false);
      setDeleted(false);
    }, 3000);
  }, [saved, deleted]);
  useEffect(() => {
    setTimeout(() => {
      setPreviousName(name);
    }, 500);
    if (!name.length) {
      setReturnedUsers([]);
    }
  }, [name]);
  useEffect(() => {
    let getUsers = async () => {
      if (previousName === name && name) {
        let response = await axios({
          method: "POST",
          url: `/search_participants_names/${name}`,
        });
        let otherUsers = response.data.filter(
          (arrayUser) => arrayUser.id !== user.id
        );
        setReturnedUsers(otherUsers);
      } else {
        setReturnedUsers([]);
      }
    };
    getUsers();
    // eslint-disable-next-line
  }, [previousName]);
  const handleDelete = async (e) => {
    //* MODAL DOUBLE CHECK! (cannot be undone!)
    let response = await axios({
      method: "DELETE",
      url: `/delete_podcast/${podcastData.id}`,
    });
    if (response.data === "success" || response.status === 200) {
      // deletion success
      let updatedPodcastCache = arrayOfAllPodcasts.filter(
        (el) => el.id !== podcastData.id
      );
      setArrayOfAllPodcasts(updatedPodcastCache);
      let updatedUsersPodcasts = usersPodcasts.filter(
        (el) => el.id !== podcastData.id
      );
      setUsersPodcasts(updatedUsersPodcasts);
      setMessages((prev) => [
        ...prev,
        {
          type: "delete_podcast",
          content: `
          Borraste tu podcast ${podcastData.title}`,
          id: uuidv4(),
        },
      ]);
      setMessageState({
        text: "Tu podcast ha sido borrado.",
        time: 4000,
      });
      navigate("/Mi Estudio/editar");
    }
  };

  const handleModalSubmit = (e) => {
    e.preventDefault();
    if (deleteValue.trim() === "eliminar podcast") {
      handleDelete();
      setOpen(false);
    } else {
      setDeleteError(true);
    }
  };
  // go get series of this user along with the id of the image

  // need to track current ref of this state because the cleanup function creates a closure with old values, so references help to get the values AT TIME OF DISMOUNT
  const audioIdRef = useRef(audioId);
  const successRef = useRef(success);
  useEffect(() => {
    audioIdRef.current = audioId;
    successRef.current = success;
  }, [audioId, success]);

  const getUploadParams = ({ file, meta }) => {
    return { url: "/upload_audio_S3" };
  };
  const getImageUploadParams = ({ file, meta }) => {
    return { url: `/upload_series_image_S3/${podcastData.idOfNewSeries}` };
  };
  const getUploadParamsPDF = ({ file, meta }) => {
    return { url: "/upload_pdf_S3" };
  };

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file, xhr }, status) => {
    let button = document.querySelector(".dzu-submitButtonContainer");
    if (button) {
      button.style.display = "none";
    }
    if (status === "removed") {
      // remove audio from S3
      setAudioID(null);
    } else {
      setErrors({
        ...errors,
        noAudio: false,
      });
      if (status === "done") {
        setShowSubmit(true);
        let idFromS3 = xhr.response;
        setAudioID(idFromS3);
      }
    }
  };
  const handleChangeStatusPDF = (
    { meta, file, xhr, cancel, remove },
    status
  ) => {
    let button = document.querySelector(".pdf .dzu-submitButtonContainer");
    if (button) {
      if (status === "done") {
        button.removeChild(button.firstChild);
        button.appendChild(document.createTextNode("¡Carga completa!"));
        button.style.display = "block";
      } else {
        button.style.display = "none";
      }
    }

    if (status === "removed") {
      // remove audio from S3
      setPdfId(null);
    } else {
      setErrors({
        ...errors,
        noAudio: false,
      });
      if (status === "done") {
        let idFromS3 = xhr.response;
        setPdfId(idFromS3);
      }
    }
  };
  const handleChangeStatusImg = (
    { meta, file, xhr, cancel, remove },
    status
  ) => {
    let button = document.querySelector(".img-dropzone");
    if (button) {
      if (status === "done") {
        // button.removeChild(button.firstChild);
        button.appendChild(document.createTextNode("¡Carga completa!"));
        button.style.display = "block";
        setImageUploadSuccess(true);
      } else {
        button.style.display = "none";
      }
    }

    if (status === "removed") {
      // remove audio from S3
    } else {
      if (status === "done") {
      }
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setModal(false);
    }, 8000);
  }, [modal]);

  useEffect(() => {
    if (messageState.text.length < 1) {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // For a smooth scroll
      });
    }
  }, [messageState]);

  const handleSubmit = async () => {
    //* 1. check form valid
    let errorPresent = false;
    const formErrors = {
      noTitle: null,
      shortTitle: null,
      noSubject: null,
      noLevel: null,
      noSeries: null,
      noParticipants: null,
      noSelect: null,
    };
    if (podcastData.title.length < 1) {
      formErrors.noTitle = true;
      errorPresent = true;
    } else if (podcastData.title.length < 3) {
      formErrors.shortTitle = true;
      errorPresent = true;
    }
    // if (!podcastData.subject) {
    //   formErrors.noSubject = true;
    //   errorPresent = true;
    // }
    if (podcastData.childContent === null) {
      formErrors.noSelect = true;
      errorPresent = true;
    }
    if (!podcastData.classLevel) {
      formErrors.noLevel = true;
      errorPresent = true;
    }
    if (
      !podcastData.seriesId.length ||
      (podcastData.seriesId.toLowerCase() === "new" &&
        !podcastData.newSeries?.length)
    ) {
      formErrors.noSeries = true;
      errorPresent = true;
    }
    if (podcastData.series.toLowerCase() === "new" && !imageUploadSuccess) {
      errorPresent = true;
      formErrors.noImg = true;
    }
    if (!podcastData.description) {
      formErrors.shortDescription = true;
      errorPresent = true;
    }
    if (podcastData.seriesId == "new" && uploaderRef.current.files.length < 1) {
      formErrors.noImg = true;
      errorPresent = true;
    }
    if (!audioId) {
      formErrors.noAudio = true;
      errorPresent = true;
    }

    if (errorPresent) {
      setErrors(formErrors);
      return;
    }

    //* 2. upload podcast
    setLoader(true);
    try {
      let data = {
        audioId,
        ...podcastData,
        selectedUsers,
        pdfId,
      };
      // if (
      //   podcastData.seriesId.toLowerCase() === "new" &&
      //   podcastData.newSeries
      // ) {
      //   // data.series = podcastData.newSeries;
      // } else {
      //   data.seriesId = podcastData.series;
      // }

      authReq({
        url: "/upload_audio_RDS",
        method: "POST",
        data,
      })
        .then((res) => {
          // ! productio
          authReq({
            url: "/transcribe",
            method: "POST",
            data: { audioId, languageCode: podcastData.languageCode },
          });
          setPodcastData({
            title: "",
            subject: "",
            classLevel: "",
            series: "",
            newSeries: "",
            seriesId: "",
            description: "",
            languageCode: "", // Add this line
          });
          localStorage.removeItem("podcastData");
          setSelectedUsers([]);

          let foundSeries = userSeries.find(
            (series) => series.id === podcastData?.series
          );
          let seriesName = foundSeries?.series;
          let uploadedPodcast = {
            ...res.data,
            series: seriesName || podcastData?.newSeries,
            owner: user,
            id: audioId,
            isPublished: user.isClassAdmin ? true : false,
          };
          // ! check this ^^^

          setArrayOfAllPodcasts((prev) => [...prev, uploadedPodcast]);
          setUsersPodcasts((prev) => [...prev, uploadedPodcast]);
          setName("");
          setLoader(false);
          // setSuccess(true);

          setError("");
          setMessageState({
            text: "¡Enhorabuena 🎉 tu podcast ha sido subido! Puedes ver tus podcasts en tu 'perfil' o en tu 'clase'. Es posible que no sea visible para los demás hasta que tu profesor lo publique.",
            time: 10000,
          });
          uploaderRef.current.files = [];
          uploaderRef2.current.files = [];
          uploaderRef3.current.files = [];

          if (!user.classCode) {
            setMessages((prev) => [
              ...prev,
              {
                type: "upload_podcast_no_classCode",
                podcast: { ...podcastData, id: audioId },
                id: uuidv4(),
              },
            ]);
          } else if (user.classCode && !user.isClassAdmin) {
            setMessages((prev) => [
              ...prev,
              {
                type: "upload_podcast_not_admin",
                podcast: { ...podcastData, id: audioId },
                id: uuidv4(),
              },
            ]);
          } else {
            setMessages((prev) => [
              ...prev,
              {
                type: "upload_podcast_is_admin",
                podcast: { ...podcastData, id: audioId },
                id: uuidv4(),
              },
            ]);
          }
          // setModal(true);
        })
        .catch((error) => {
          setLoader(false);
          localStorage.removeItem("plan");
          setError(
            "Hay un error en el servidor. Por favor, inténtalo de nuevo."
          );
          console.error(error);
        });
    } catch (error) {
      setAudioID(null);
      sendErrorReport("Podcast Entry Form", error);

      setSuccess(false);
      setLoader(false);
      console.error(error);
    }
  };
  const handleAddClick = (user) => {
    setErrors({
      ...errors,
      noParticipants: false,
    });
    setName("");
    let userAlreadyInArray = selectedUsers.find(
      (element) => element.id === user.id
    );
    if (!userAlreadyInArray) {
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  const handleRemoveClick = (user) => {
    let newArray = selectedUsers.filter((element, index, arr) => {
      return element.id !== user.id;
    });
    setSelectedUsers(newArray);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleTitleChange = (e) => {
    let title = e.target.value;
    if (errors.noTitle && title) setErrors({ ...errors, noTitle: false });
    if (errors.shortTitle && title) setErrors({ ...errors, shortTitle: false });
    setPodcastData({ ...podcastData, title });
  };

  const handleContentChange = (newValue) => {
    setPodcastData({ ...podcastData, childContent: newValue });
  };

  const handleSubjectChange = (e) => {
    let subject = e.target.value;
    if (errors.noSubject && subject) setErrors({ ...errors, noSubject: false });
    setPodcastData({ ...podcastData, subject });
  };

  const handleLevelChange = (e) => {
    let classLevel = e.target.value;
    if (errors.noLevel && classLevel) setErrors({ ...errors, noLevel: false });
    setPodcastData({ ...podcastData, classLevel });
  };
  const handleSeriesChange = (e) => {
    let seriesId = e.target.value;
    if (errors.noSeries && seriesId) setErrors({ ...errors, noSeries: false });
    setPodcastData({ ...podcastData, seriesId });
  };
  const handleNewSeriesChange = (e) => {
    let newSeries = e.target.value;
    if (errors.noSeries && newSeries) setErrors({ ...errors, noSeries: false });
    setPodcastData({ ...podcastData, newSeries });
  };
  const localSave = () => {
    localStorage.setItem("podcastData", JSON.stringify(podcastData));
    setSaved(true);
  };
  const handleSave = () => {
    // send updated info with podcast ID and edit it.
    let podData = { ...podcastData };
    let transcriptData = podData.transcriptData;
    delete podData.transcriptData;
    let correctData = { ...podData, series: podData.newSeries };
    delete correctData.newSeries;
    authReq({
      method: "PUT",
      url: "/update_podcast",
      data: correctData,
    }).then((res) => {
      if (res.status === 200) {
        // replace the old podcast in the cache with the updated version
        setSuccess(true);
        setMessageState({
          text: " ¡Enhorabuena! 🎉 Los cambios se han guardado - ahora puedes ver tu podcast en la página de inicio.",
          time: 3000,
        });

        let keys = Object.keys(podcastCache);
        podData.transcriptData = transcriptData;
        let newCache = {
          ...podcastCache,
        };
        keys.forEach((key) => {
          let indexOfPod = podcastCache[key].findIndex(
            (pod) => pod.id === podcastData.id
          );
          if ((indexOfPod && indexOfPod !== -1) || indexOfPod === 0) {
            let newPods = [...podcastCache[key]];
            newPods[indexOfPod] = correctData;
            newCache[key] = newPods;
          }
        });
        setPodcastCache(newCache);
        let indexInUserArray = userPodcasts.findIndex(
          (pod) => pod.id === podcastData.id
        );
        if (
          (indexInUserArray && indexInUserArray !== -1) ||
          indexInUserArray === 0
        ) {
          let newPods = [...userPodcasts];
          newPods[indexInUserArray] = podcastData;

          setUsersPodcasts(newPods);
          setPodcastData({
            title: "",
            subject: "",
            classLevel: "",
            description: "",
            series: "",
            newSeries: "",
            seriesId: "",
            childContent: false,
            languageCode: "",
          });
        }
      }
    });
  };

  const localDelete = () => {
    localStorage.removeItem("podcastData");
    setPodcastData({
      title: "",
      subject: "",
      classLevel: "",
      description: "",
      series: "",
      newSeries: "",
      seriesId: "",
      childContent: false,
      languageCode: "",
    });
    setDeleted(isUpload);
  };
  const handleLanguageChange = (e) => {
    setPodcastData({
      ...podcastData,
      languageCode: e.target.value,
    });
  };
  const handleDescriptionChange = (e) => {
    let description = e.target.value;
    if (errors.shortDescription && description)
      setErrors({ ...errors, shortDescription: false });
    if (description.length <= CHARACTER_LIMIT)
      setPodcastData({ ...podcastData, description });
  };
  const displaySelectedUsers = () => {
    if (selectedUsers.length) {
      return selectedUsers.map((user) => {
        const fullName = user.firstName + " " + user.lastName;
        return (
          <div className="users-container" key={JSON.stringify(user)}>
            <Button
              variant="text"
              className="user"
              id={user.userId}
              onClick={() => handleRemoveClick(user)}
            >
              {fullName} <DisabledByDefault />
            </Button>
          </div>
        );
      });
    }
  };
  const displayUsers = () => {
    let returnValue;
    if (returnedUsers.length) {
      returnValue = returnedUsers.map((user) => {
        const fullName = user.firstName + " " + user.lastName;
        return (
          <div className="users-container" key={JSON.stringify(user)}>
            <button
              variant="text"
              className="btn btn-outline-dark"
              onClick={() => handleAddClick(user)}
            >
              {fullName}
              <AddBox />
            </button>
          </div>
        );
      });
    } else if (name && name === previousName) {
      returnValue = <div>No hay un usuario con ese nombre...</div>;
    } else {
      returnValue = <div></div>;
    }
    return returnValue;
  };
  const checkPremium = () => {
    if (!plan["podcastDocFeature"] || !user.isClassAdmin) {
      // open the modal
      setPremiumModal(true);
    } else {
      let input = document.querySelector(".pdf .dzu-input");
      setShowHover(false);
      input.click();
    }
  };
  const removeFiles = () => {
    // uploaderRef.current.files = [];
    uploaderRef.current.handleRestart();
  };
  return (
    <div>
      {error && <div className="error">{error}</div>}
      <>
        <div className="flex-container-upload">
          {!isUpload && (
            <button id="delete-podcast-button" onClick={() => setOpen(true)}>
              Eliminar este podcast
            </button>
          )}

          <form className="upload-form row">
            <StudioHeader
              isUpload={isUpload}
              subtitle={"Detalles de tu podcast nuevo"}
            />
            <div>
              {isUpload && (
                <>
                  <button className="btn btn-primary my-2" onClick={localSave}>
                    Guardar para más tarde
                  </button>
                  <button
                    className="btn btn-primary my-2"
                    onClick={localDelete}
                  >
                    Borrar cambios
                  </button>
                </>
              )}
              {saved && <div>¡guardado!</div>}
              {deleted && <div>¡Borrado!</div>}
            </div>
            <div className="mt-3">
              Los campos marcados con{" "}
              <span className="text-danger fw-bold">*</span> son obligatorios.
            </div>
            {/* podcast details */}
            <div className="error">
              {errors.noTitle ? "* Título requerido" : ""}
            </div>
            <div className="error">
              {errors.shortTitle
                ? "* El título debe tener al menos 3 caracteres"
                : ""}
            </div>

            <div className="container mt-4">
              <form className="row podcast-inputs-form">
                {/* Series Selector */}
                <div className="form-group mb-3">
                  <label htmlFor="series-label" className="form-label">
                    <span className="text-danger">*</span> Serie{" "}
                    <span className="fw-normal">
                      (Puede haber múltiples episodios en una serie)
                    </span>
                  </label>
                  <label></label>
                  <select
                    className={`form-select ${
                      errors.noSeries ? "is-invalid" : ""
                    }`}
                    id="series-label"
                    value={podcastData?.seriesId}
                    onChange={handleSeriesChange}
                    disabled={!isUpload}
                    title={
                      !isUpload
                        ? "De momento no se puede cambiar la imagen de la serie."
                        : ""
                    }
                  >
                    <option value="">-</option>
                    <option value="new">Crear nueva serie</option>
                    {userSeries?.map((seriesObject) => (
                      <option key={seriesObject.id} value={seriesObject.id}>
                        {seriesObject.series}
                      </option>
                    ))}
                  </select>
                  {errors.noSeries && (
                    <div className="invalid-feedback">* Serie requerida</div>
                  )}
                </div>
                <div
                  className={`form-group mb-3 new-series-inputs bg-light col-lg-5  ${
                    podcastData?.seriesId?.toLowerCase() === "new"
                      ? "show-series"
                      : "hidden"
                  }`}
                >
                  <label htmlFor="new-series" className="form-label">
                    <span className="text-danger">*</span> Nueva Serie
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.noSeries ? "is-invalid" : ""
                    }`}
                    id="new-series"
                    value={podcastData.newSeries}
                    onChange={handleNewSeriesChange}
                  />
                  {errors.noSeries && (
                    <div className="invalid-feedback">* Serie requerida</div>
                  )}

                  <Dropzone
                    addClassNames={{
                      dropzone: errors.noImg
                        ? "errorBorder width-500 mt-3 img-dropzone"
                        : "dropzone-border width-500 mt-3 img-dropzone",
                    }}
                    ref={uploaderRef}
                    getUploadParams={getImageUploadParams}
                    onChangeStatus={handleChangeStatusImg}
                    maxFiles={1}
                    accept="image/jpeg"
                    inputContent={
                      <div className="upload-content-text" key="content1">
                        <ImageIcon fontSize="large" />* Imagen de esta serie:
                        Arrastra un archivo o haz clic para añadir una
                        desde tu ordenador.
                      </div>
                    }
                    // submitButtonDisabled={(files) => files.length < 1}
                  />
                  {errors.noImg && (
                    <div className="invalid-feedback">* Imagen requerida</div>
                  )}
                </div>

                {/* Episode Title */}
                <div className="form-group mb-3 mt-3">
                  <label htmlFor="podcast-title" className="form-label">
                    <span className="text-danger">*</span> Título del episodio
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.noTitle || errors.shortTitle ? "is-invalid" : ""
                    }`}
                    id="podcast-title"
                    value={podcastData.title}
                    onChange={handleTitleChange}
                  />
                  {(errors.noTitle || errors.shortTitle) && (
                    <div className="invalid-feedback">* Título requerido</div>
                  )}
                </div>

                {/* Language Selector */}
                <div className="form-group mb-3">
                  <label htmlFor="language-select" className="form-label">
                    <span className="text-danger">*</span> Idioma{" "}
                    <span className="fw-normal">
                      (Elige el idioma hablado en el podcast)
                    </span>
                  </label>
                  <select
                    className={`form-select ${
                      errors.noLanguage ? "is-invalid" : ""
                    }`}
                    id="language-select"
                    value={podcastData.languageCode}
                    onChange={handleLanguageChange}
                  >
                    {languages.map((language) => (
                      <option key={language.code} value={language.code}>
                        {language.language}
                      </option>
                    ))}
                  </select>
                  {errors.noLanguage && (
                    <div className="invalid-feedback">* Idioma requerido</div>
                  )}
                </div>

                <div className="form-group mb-3">
                  {errors.noSubject && (
                    <div className="text-danger">* Materia requerida</div>
                  )}
                  <label htmlFor="subject-select" className="form-label">
                    <span className="text-danger">*</span> Materia
                  </label>
                  <select
                    className={`form-select ${
                      errors.noSubject ? "is-invalid" : ""
                    }`}
                    id="subject-select"
                    value={podcastData.subject}
                    onChange={handleSubjectChange}
                  >
                    <option value="">-</option>
                    {subjects.map((subject) => (
                      <option key={subject} value={subject}>
                        {subject}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group mb-3">
                  {errors.noLevel && (
                    <div className="text-danger">* Etapa requerida</div>
                  )}
                  <label htmlFor="class-level-select" className="form-label">
                    <span className="text-danger">*</span> Etapa
                  </label>
                  <select
                    className={`form-select ${
                      errors.noLevel ? "is-invalid" : ""
                    }`}
                    id="class-level-select"
                    value={podcastData.classLevel}
                    onChange={handleLevelChange}
                  >
                    <option value="">-</option>
                    <option value="primaria">Primaria</option>
                    <option value="Secundaria">Secundaria</option>
                    <option value="Bachillerato">Bachillerato</option>
                    <option value={"FP"}>FP</option>
                    <option value={"Universidad"}>Universidad</option>
                    <option value={"Otro"}>Otro</option>
                  </select>
                </div>
                <div className="error desc-error">
                  {errors.shortDescription
                    ? "* La descripción requiere un mínimo de 5 caracteres."
                    : ""}
                </div>
                <div className="mb-3 col-md-5 col-sm-7">
                  <label htmlFor="description" className="form-label">
                    <span className="text-danger">*</span> Descripción (max 250
                    caracteres){" "}
                  </label>
                  <textarea
                    className="form-control ${errors.shortDescription ? 'is-invalid' : ''}"
                    id="description"
                    rows="5"
                    required
                    maxLength={CHARACTER_LIMIT}
                    value={podcastData.description}
                    onChange={handleDescriptionChange}
                  ></textarea>
                  <div id="descriptionHelp" className="form-text">
                    {podcastData.description.length}/{CHARACTER_LIMIT}
                  </div>

                  {errors.shortDescription
                    ? `<div className="invalid-feedback">
      ${errors.shortDescription}
    </div>`
                    : ""}
                </div>
              </form>
            </div>
          </form>

          {isUpload && (
            <div className="podcast-inputs-form my-4">
              <div className=" form-group mb-3 me-0">
                <label htmlFor="nameSearch" className="form-label">
                  Agregar participantes{" "}
                  <span className="fw-normal">
                    (Locutores o participantes del episodio)
                  </span>
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    errors && errors.noParticipants ? "is-invalid" : ""
                  }`}
                  id="nameSearch"
                  placeholder="Buscar un nombre"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div>{displayUsers()}</div>
              <div className="">
                {selectedUsers.length > 0 && (
                  <label className="form-label">Participantes</label>
                )}
                {displaySelectedUsers()}
              </div>
            </div>
          )}
        </div>
      </>

      <div className="error">
        {errors.noAudio ? "* Audio File Required" : ""}
      </div>
      <div className="row">
        {isUpload && (
          <div className="col-md-5" id="audio-upload-dropzone">
            {" "}
            <Dropzone
              addClassNames={{
                dropzone: errors.noAudio ? "errorBorder" : "dropzone-border",
              }}
              ref={uploaderRef3}
              getUploadParams={getUploadParams}
              onChangeStatus={handleChangeStatus}
              onSubmit={handleSubmit}
              maxFiles={1}
              accept="audio/mp3, audio/mpeg"
              inputContent={
                <div className="upload-content-text" key="content1">
                  <AudioFile fontSize="large" />
                  <span className="text-danger align-self-start">*</span>{" "}
                  Podcast audio: Arrastra un archivo .mp3 o haz clic para
                  explorar desde tu ordenador.
                </div>
              }
              submitButtonDisabled={(files) => files.length < 1}
            />
          </div>
        )}

        <div
          className="pdf col-md-5 mt-4 mt-md-0 position-relative"
          id="pdf-upload-dropzone"
        >
          <div
            className={`hover ${showHover && isUpload ? "show" : "hide"}`}
            onClick={checkPremium}
          >
            {" "}
            <PremiumIcon />
          </div>
          <Dropzone
            addClassNames={{
              dropzone: errors.noAudio ? "errorBorder" : "dropzone-border",
            }}
            ref={uploaderRef2}
            getUploadParams={getUploadParamsPDF}
            onChangeStatus={handleChangeStatusPDF}
            onSubmit={handleSubmit}
            accept="application/pdf"
            maxFiles={1}
            inputContent={
              <div className="upload-content-text" key="content2">
                <div className="d-flex flex-column align-items-center">
                  (Opcional)
                  <UploadFile fontSize="large" />
                </div>
                Podcast PDF: Arrastra un archivo o haz clic para explorar desde
                tu ordenador.
              </div>
            }
            submitButtonDisabled={true}
          />
          <div className="edit-buttons-container">
            {!isUpload && (
              <Button
                id="save-button"
                className="mt-3 p-3"
                aria-label="sae podcast button"
                onClick={handleSave}
              >
                enviar cambios
              </Button>
            )}

            {/* {!isUpload && (
              <button id="delete-podcast-button" onClick={() => setOpen(true)}>
                Eliminar este podcast
              </button>
            )} */}
          </div>
        </div>
      </div>

      {!showSubmit && isUpload !== false && (
        <p className="fs-6 mt-4 mb-2">
          * Rellena todos los campos y añade un audio antes de subir tu podcast.
        </p>
      )}
      {isUpload !== false && (
        <button
          variant="contained"
          className={`btn btn-primary mt-0 upload-submit-button ${
            showSubmit ? "mt-4" : "disabled"
          }`}
          onClick={handleSubmit}
          disabled={!showSubmit}
        >
          Subir
        </button>
      )}

      <Modal
        open={modal}
        onClose={() => setModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="payment-modal"
      >
        <Box className="modal-box">
          <IconButton
            className="modal-close-button"
            onClick={() => setModal(false)}
          >
            <Close />
          </IconButton>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="payment-desc"
          >
            <>
              ¡Enhorabuena 🎉 tu podcast ha sido subido! Es posible que no sea
              visible para los demás hasta que tu profesor lo publique.
            </>
          </Typography>
        </Box>
      </Modal>
      <Modal
        open={open}
        onHide={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="modal-modal-title">
              ADVERTENCIA: ¡Estás eliminando este podcast!
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => setOpen(false)}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <p>
              {podcastData.title} será eliminado permanentemente. esto no puede
              ser deshecho.
            </p>
            <p id="modal-modal-description">
              Escriba <i>eliminar podcast</i> para eliminarlo.
            </p>
            {deleteError && (
              <div className="alert alert-danger" role="alert">
                Error: debes escribir: <b>eliminar podcast</b>
              </div>
            )}
            <form onSubmit={handleModalSubmit}>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  value={deleteValue}
                  onChange={(e) => {
                    setDeleteError(false);
                    setDeleteValue(e.target.value);
                  }}
                />
              </div>
              <button type="submit" className="btn btn-primary">
                Borrar
              </button>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PodcastEntryForm;
