import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LoaderContext, PodcastContext, UserContext } from "../../contexts";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ErrorIcon from "@mui/icons-material/Error";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import SettingsIcon from "@mui/icons-material/Settings";
import { Home, AccountBalance, Mic, School } from "@mui/icons-material";
import "./index.css";

export default function AccountMenu({ displayButtons, setDisplayButtons }) {
  const navigate = useNavigate();

  let { user, setUser, setPodcastCallWasMade, setToken } =
    useContext(UserContext);

  let { setUsersPodcasts } = useContext(PodcastContext);
  const { isMobile } = useContext(LoaderContext);

  const handleProfileClick = () => {
    navigate(`/perfil/${user.id}`);
  };

  const handleLogOutClick = () => {
    setUser(false);
    setToken(false);
    setUsersPodcasts([]);
    setPodcastCallWasMade(false);
    localStorage.removeItem("jwt_user");
    localStorage.removeItem("plan");
    window.location.reload();
    navigate("/");
  };

  const closeButtons = () => {
    setDisplayButtons(false);
  };

  return (
    <Paper
      sx={{ width: 400 }}
      id="account-menu-container"
      className={`${displayButtons ? "show" : "hide"} ${
        isMobile ? "account-menu-container-mobile" : ""
      }`}
    >
      <MenuList onClick={closeButtons} className="menu-list">
        <MenuItem onClick={() => navigate("/")} className="account-menu-item">
          <ListItemIcon>
            <Home fontSize="small" />
          </ListItemIcon>
          <ListItemText>Inicio</ListItemText>
        </MenuItem>

        <MenuItem onClick={handleProfileClick} className="account-menu-item">
          <ListItemIcon>
            <AccountCircleIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Perfil</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => navigate("/Mi Estudio/subir")}
          className="account-menu-item"
        >
          <ListItemIcon>
            <Mic fontSize="small" />
          </ListItemIcon>
          <ListItemText>Mi estudio</ListItemText>
        </MenuItem>
        {/* </Link> */}

        {/* <Link to="/clase" className="menu"> */}
        <MenuItem
          onClick={() => navigate("/clase")}
          className="account-menu-item"
        >
          <ListItemIcon>
            <School fontSize="small" />
          </ListItemIcon>
          <ListItemText>Mi clase</ListItemText>
        </MenuItem>
        {/* </Link> */}

        {/* <Link to="/libreria" className="menu"> */}
        <MenuItem
          onClick={() => navigate("/libreria")}
          className="account-menu-item"
        >
          <ListItemIcon>
            <AccountBalance fontSize="small" />
          </ListItemIcon>
          <ListItemText>Mi libreria</ListItemText>
        </MenuItem>
        {/* </Link> */}
        {/* <Link to="/Informar error" className="menu"> */}
        {user.accountType !== "student" && (
          <MenuItem
            onClick={() => navigate("suscripción")}
            className="account-menu-item"
          >
            <ListItemIcon>
              <WorkspacePremiumIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Suscripción</ListItemText>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => navigate("/Informar error")}
          className="account-menu-item"
        >
          <ListItemIcon>
            <ErrorIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Informar de un error</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => navigate("/perfil/editar")}
          className="account-menu-item"
        >
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Preferencias</ListItemText>
        </MenuItem>
        {/* </Link> */}

        <MenuItem onClick={handleLogOutClick} className="account-menu-item">
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Cerrar sesión</ListItemText>
        </MenuItem>
      </MenuList>
    </Paper>
  );
}
