import React, { useContext, useEffect } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Skeleton } from "@mui/material";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Box,
  Typography,
  Modal,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import "./index.css";
import { useState } from "react";
import SubscriptionForm from "../../components/subscriptionForm";
import CustomerForm from "../../components/customerForm";
import { LoaderContext, UserContext } from "../../contexts";
import { authReq, sendErrorReport } from "../../utilities/requests";
import { Navigate, useNavigate } from "react-router-dom";

const FeatureComparisonTable = () => {
  const [plans, setPlans] = useState([]);
  const navigate = useNavigate();
  let features = {
    uploadPodcastsFeature: "Subir audios",
    createFeature: "Crear series de podcasts",
    publishingFeature: "Revision podcasts de alumnos",
    listeningFeature: "Escuchar podcasts",
    transcriptionFeature: "Transcripciones automáticas de cada episodio",
    quizFeature: "Crear tests de aprendizaje en cada episodio",
    resultsFeature: "Ver resultados de tests de aprendizaje",
    podcastDocFeature: "Añadir fichas PDF en cada episodio",
    privatePodsFeature: "Privatizar podcasts del grupo aula",
  };
  const [showModal, setShowModal] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [checkModal, setCheckModal] = useState(false);
  const { plan, setPlan, user } = useContext(UserContext);
  let contextPlan = plan;
  const { customerData, paymentSuccess, setPaymentSuccess } =
    useContext(UserContext);
  const [claimedFreeTrial, setClaimedFreeTrial] = useState(null);
  const { setLoader } = useContext(LoaderContext);
  useEffect(() => {
    if (user?.accountType !== "teacher") navigate("/");
    setLoader(true);
    authReq({ url: "/plans", method: "GET" }).then((res) => {
      setLoader(false);
      setPlans(res.data.plans);
      setClaimedFreeTrial(res.data.claimedPremium);
    });
  }, []);
  const handleClick = (plan) => {
    if (plan.planId === "111111") {
      setWarningModal(true);
    } else {
      setCheckModal(true);
      setSelectedPlan(plan);
    }
  };
  const handleFreeTrial = () => {
    // show modal asking if they are sure
    setLoader(true);
    setCheckModal(false);
    // update DB
    authReq({
      url: "/freeTrial",
      method: "POST",
      data: { plan: selectedPlan },
    }).then((res) => {
      setClaimedFreeTrial(true);
      setWarningModal(false);
      setPaymentSuccess(true);
      setShowModal(true);
      authReq({ url: "/get_user_plan" }).then((planRes) => {
        localStorage.setItem("plan", planRes?.data?.planToken);
        setPlan(planRes?.data?.plan);
        setLoader(false);
      });
    });
  };
  const handleFremium = () => {
    try {
      setLoader(true);
      authReq({ url: "/freemium", method: "POST", data: { plan } }).then(
        (res) => {
          setLoader(false);
          setPlan(plans[0]);
          setWarningModal(false);
          setClaimedFreeTrial(true);
          // set new user here
        }
      );
    } catch (error) {
      console.log(error);
      sendErrorReport("FeatureComparisonTable", error);
    }
  };

  if (
    (!user.classCode && user.accountType === "student") ||
    (user.classCode && !user.isClassAdmin)
  ) {
    return <Navigate to="/" />;
  }

  const returnPremiumButton = (plan) => {
    if (claimedFreeTrial || plan.planId === "111111") {
      return (
        <Button
          onClick={() => handleClick(plan)}
          // disabled={plan.planId === contextPlan.planId}
          disabled={plan.planId === contextPlan.planId ? false : true}
        >
          {plan.planId === contextPlan.planId
            ? "¡Ya es tu plan!"
            : "De momento, no disponible"}
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => {
            setCheckModal(true);
            setSelectedPlan(plan);
          }}
          disabled={plan.planId === contextPlan.planId}
        >
          {plan.planId === contextPlan.planId
            ? "¡Ya es tu plan!"
            : "Pruebalo Gratis"}
        </Button>
      );
    }
  };
  return (
    <div className="table-container">
      <h1 className=" mb-3">Elige tu plan</h1>
      <p>
        * Cuando te suscribas, todos los estudiantes de tu "clase" tendrán
        acceso a las funciones de tu plan.
      </p>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th></th>
              {plans.length ? (
                plans
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((plan, index) => (
                    <th
                      key={index}
                      className="text-center p-2"
                      title={
                        plan.planId === contextPlan.planId ? "¡Tu Plan!" : ""
                      }
                      id={plan.name === "Plus" ? "plus" : ""}
                    >
                      {plan.planId === contextPlan.planId && (
                        <div className="tu-plan-div mb-2">
                          <CheckCircleIcon fontSize="small" />
                          Tu plan actual
                        </div>
                      )}
                      {plan.name}
                    </th>
                  ))
              ) : (
                <>
                  <th>
                    <Skeleton width={100} height={20} />
                  </th>
                  <th>
                    <Skeleton width={100} height={20} />
                  </th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row"></th>
              {plans.length ? (
                plans.map((plan, i) => (
                  <td key={i} className="text-center">
                    {/* ? `€${(plan.price / 100).toFixed(2)} / mes` */}
                    {plan.planId === "111111"
                      ? "Gratuito"
                      : "Prueba gratis durante 90 días"}
                  </td>
                ))
              ) : (
                <>
                  <td>
                    <Skeleton width={100} height={20} />
                  </td>
                  <td>
                    <Skeleton width={100} height={20} />
                  </td>
                </>
              )}
            </tr>
            {Object.keys(features).map((featureKey, i) => (
              <tr
                className=""
                key={i}
                style={{ background: i % 2 === 1 ? "" : "#f2f2f2" }}
              >
                <th scope="row mt-2">{features[featureKey]}</th>
                {plans.map((plan, index) => (
                  <td key={index} className="text-center">
                    {plan[featureKey] ? (
                      <span className="text-success fs-5">✔</span>
                    ) : (
                      <span className="text-muted">✘</span>
                    )}
                  </td>
                ))}
              </tr>
            ))}
            <tr>
              <td></td>
              {plans.map((plan, index) => (
                <td key={index} className="text-center">
                  <button
                    className={`btn ${
                      plan.planId === contextPlan.planId ||
                      (!contextPlan && plan.planId === "111111")
                        ? "btn-danger"
                        : "btn-primary"
                    }`}
                    onClick={() =>
                      (!contextPlan && plan.planId === "111111") ||
                      contextPlan.planId === plan.planId
                        ? setWarningModal(true)
                        : handleClick(plan)
                    }
                    disabled={
                      contextPlan.planId === plan.planId &&
                      plan.planId === "111111"
                    }
                  >
                    {contextPlan.planId === plan.planId ||
                    (!contextPlan && plan.planId === "111111")
                      ? "Cancelar"
                      : "Seleccionar"}
                  </button>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="payment-modal"
      >
        <Box className="modal-box">
          <IconButton
            className="modal-close-button"
            onClick={() => setShowModal(false)}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="payment-desc"
          >
            {paymentSuccess ? (
              "¡Felicidades! ¡Su pago fue exitoso - disfrute de tu plan nuevo!"
            ) : (
              // <>
              //   {" "}
              //   Ha elegido el plan {selectedPlan?.name}, que costará{" "}
              //   <b>€{selectedPlan?.price / 100} / mes</b>.{" "}
              //   {contextPlan && contextPlan.planId !== "111111"
              //     ? `Esto cancelara tu plan actual de ${contextPlan.name}.`
              //     : ""}{" "}
              //   Ingrese tu información de pago a continuación para completar la
              //   suscripción.
              // </>
              <>
                {" "}
                Ha elegido el plan {selectedPlan?.name}, gratuito durante la
                versión Beta (90 días).{" "}
                {contextPlan && contextPlan.planId !== "111111"
                  ? `Esto cancelara tu plan actual de ${contextPlan.name}.`
                  : ""}{" "}
                Ingresa la información de pago a continuación para completar la
                suscripción.
              </>
            )}
          </Typography>

          <div
            style={{
              display: !customerData && !paymentSuccess ? "block" : "none",
            }}
          >
            <CustomerForm selectedPlan={selectedPlan} />
          </div>
          <div
            style={{
              display: customerData && !paymentSuccess ? "block" : "none",
            }}
          >
            <SubscriptionForm selectedPlan={selectedPlan} />
          </div>
        </Box>
      </Modal>
      {/* CHECK MODAL */}
      <Modal
        open={warningModal}
        onClose={() => setWarningModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="payment-modal"
      >
        <Box className="modal-box">
          <IconButton
            className="modal-close-button"
            onClick={() => setWarningModal(false)}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="payment-desc"
          >
            <>
              ¿Estás seguro de que quieres cambiarte a una suscripción{" "}
              <b>Basico?</b>
            </>
            <br></br>
            <div
              className="btn btn-secondary me-2"
              onClick={() => setWarningModal(false)}
            >
              No
            </div>
            <div className="btn btn-primary" onClick={handleFremium}>
              Sí
            </div>
          </Typography>
          {/* if there is no customer data AND no payment success, block */}
          {/* STEP 1: CREATE CUSTOMER PROFILE IN STRIPE  */}
        </Box>
      </Modal>
      <Modal
        open={checkModal}
        onClose={() => setCheckModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="payment-modal"
      >
        <Box className="modal-box">
          <IconButton
            className="modal-close-button"
            onClick={() => setCheckModal(false)}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="payment-desc"
          >
            <div>
              Playpod está en versión Beta. Durante la versión Beta, las
              funciones Premium son gratuitas. Al finalizar, te informaremos de
              los posibles cambios y precios y podrás elegir suscribirte.
            </div>
            <br></br>
            <div>
              Valoramos mucho tu interés y experiencia, por eso agradeceremos
              cualquier comentario y sugerencia a través de
              contact@playpod.education ¡Gracias!
            </div>

            <br></br>
            <>¿Quieres empezar la prueba gratuita Premium (90 días)?</>

            <br></br>
            <button
              className="btn btn-secondary"
              onClick={() => setCheckModal(false)}
            >
              No
            </button>
            <button
              className="btn btn-primary"
              onClick={() => handleFreeTrial()}
            >
              Sí
            </button>
          </Typography>
          {/* if there is no customer data AND no payment success, block */}
          {/* STEP 1: CREATE CUSTOMER PROFILE IN STRIPE  */}
        </Box>
      </Modal>
    </div>
  );
  // ! allow user to go back to previous step set_client_secret(null)
};

export default FeatureComparisonTable;
