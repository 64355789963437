import React, { useState } from "react";
import "./index.css";
import { useEffect } from "react";
import { useRef } from "react";
import { authReq, sendErrorReport } from "../../utilities/requests";
import { HighlightOff } from "@mui/icons-material";
import { useContext } from "react";
import { LoaderContext, UserContext } from "../../contexts";
import { Navigate } from "react-router-dom";

function ClassCreate() {
  const [activeTab, setActiveTab] = useState("create");
  const { user } = useContext(UserContext);
  if (user.classCode) return <Navigate to="/clase" />;
  return (
    <div className="row">
      <h2>Crear Clase</h2>
      <p>Tu perfil no está asociado actualmente con una clase.</p>
      <div className="col-10 offset-1 offset-md-3 offset-lg-4 col-md-6 col-lg-4  ">
        <div className="tab-content">
          {activeTab === "join" && <JoinClassTab />}
          {activeTab === "create" && user.accountType === "teacher" ? (
            <CreateClassTab />
          ) : (
            <p>De momento solo los profesores pueden crear clases</p>
          )}
        </div>
      </div>
    </div>
  );
}

function JoinClassTab() {
  const [inputValue, setInputValue] = useState("");
  const [classes, setClasses] = useState([]);
  const debounceRef = useRef();

  return (
    <div>
      <input
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder="Busca una clase..."
      />
      <ul>
        {classes.map((_class) => (
          <li key={_class.id}>{_class.name}</li>
        ))}
      </ul>
    </div>
  );
}

function CreateClassTab() {
  const { user, setUser } = useContext(UserContext);
  const { setLoader } = useContext(LoaderContext);
  let maxUsers = 20;

  const [_class, _setClass] = useState({
    className: "",
    schoolName: "",
    country: "es",
  });
  const [error, setError] = useState("");

  const [inviteSearch, setInviteSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [isAddNewUser, setIsAddNewUser] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [searchedSelf, setSearchedSelf] = useState(false);
  const [newUser, setNewUser] = useState({
    email: inviteSearch,
    firstName: "",
    lastName: "",
  });
  const resetFormData = () => {
    _setClass({
      className: "",
      country: "",
    });
    setErrorMsg("");
    setInviteSearch("");
    setUsers([]);
    setInvitedUsers([]);
    setIsAddNewUser(false);
    setNewUser({
      email: "",
      firstName: "",
      lastName: "",
    });
  };

  useEffect(() => {
    if (error.length > 0) {
      setTimeout(() => {
        setError("");
      }, [3000]);
    }
  }, [error]);

  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    if (users.length === 0 && isValidEmail(inviteSearch)) {
      setTimeout(() => {
        setIsAddNewUser(true);
      }, 500);
    } else {
      setIsAddNewUser(false);
    }
  }, [users, inviteSearch]);

  const handleAddNewUser = () => {
    if (invitedUsers.length >= maxUsers - 1)
      return setErrorMsg(
        `De Momento las clases no pueden tener más de ${maxUsers} miembros. Elimina a alguien antes de invitar a alguien nuevo. Si necesitas más plazas, por favor, ponte en contacto con nosotros.`
      );
    setInvitedUsers([...invitedUsers, { ...newUser, isClassAdmin: false }]);
    setNewUser({
      email: "",
      firstName: "",
      lastName: "",
    });
    setIsAddNewUser(false);
    setInviteSearch("");
  };

  const debounceRef = useRef();

  useEffect(() => {
    // clear error after 3 seconds
    if (errorMsg.length > 0) {
      setTimeout(() => {
        setErrorMsg("");
      }, 3000);
    }
  }, [errorMsg]);

  useEffect(() => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = setTimeout(async () => {
      if (inviteSearch) {
        let response = await authReq({
          url: `/get_user_by_email/${inviteSearch}`,
          method: "POST",
        });
        // axios.get('/api/users/search', { params: { query: inviteSearch } })
        //   .then(response => {
        //     setUsers(response.data);
        //   })
        //   .catch(error => {
        //     console.error('Error fetching users:', error);
        //   });
        if (response.data.id) {
          if (response.data.id === user.id) {
            setSearchedSelf(true);
          } else {
            setUsers([{ ...response.data, email: inviteSearch }]);
            setSearchedSelf(false);
          }
        } else {
          setUsers([]);
          setSearchedSelf(false);
        }
      } else {
        setUsers([]);
        setSearchedSelf(false);
      }
    }, 500);

    return () => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
    };
  }, [inviteSearch]);

  const handleUserInvite = (user) => {
    // Check if the user is already in the invited list
    setInviteSearch("");
    if (!invitedUsers.some((u) => u.email === user.email)) {
      setInvitedUsers([
        ...invitedUsers,
        { ...user, isClassAdmin: false, email: inviteSearch },
      ]);
    }
  };

  const setRole = (userEmail, isAdmin) => {
    setInvitedUsers((prevUsers) =>
      prevUsers.map((u) =>
        u.email === userEmail ? { ...u, isClassAdmin: isAdmin } : u
      )
    );
  };
  const handleProfesorClick = (user) => {
    alert("De momento so puede ser un profesor por clase (tu)");
    // setRole(user.email, true)
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (invitedUsers.length > maxUsers - 1) {
    //   return setErrorMsg(
    //     `De Momento las clases no pueden tener más de ${maxUsers} miembros. Elimina a alguien antes de invitar a alguien nuevo. Si necesitas más plazas, por favor, ponte en contacto con nosotros.`
    //   );
    // }
    setLoader(true);
    if (_class.className.length === 0) {
      setError("Por favor, introduce el nombre de la clase.");
      return setLoader(false);
    }
    // Check if any user has isClassAdmin as undefined
    const hasUndefinedRole = invitedUsers.some(
      (user) => user.isClassAdmin === undefined
    );

    if (hasUndefinedRole) {
      setErrorMsg("Asigne un rol a cada usuario invitado.");
      return setLoader(false);
    }
    let capitalizedClass = {
      ..._class,
      className: _class.className[0].toUpperCase() + _class.className.slice(1),
      schoolName:
        _class.schoolName[0].toUpperCase() + _class.schoolName.slice(1),
    };

    try {
      let response = await authReq({
        url: "/create_class",
        method: "POST",
        data: {
          ...capitalizedClass,
          invitedUsers,
        },
      });
      setLoader(false);
      if (response && response.status === 200) {
        // Assuming a 200 status indicates a successful request.
        resetFormData();
        localStorage.setItem("jwt_user", response.data.token);
        setUser(response.data.user);
      }
    } catch (error) {
      setLoader(false);

      setError(
        "Hubo un error al crear la clase. Por favor, inténtalo de nuevo."
      );
      sendErrorReport("ClassCreate - handleSubmit", error);
    }

    // Handle other form submission details...
  };
  const handleRemoveUser = (userId, userEmail) => {
    if (userId) {
      setInvitedUsers((prevUsers) => prevUsers.filter((u) => u.id !== userId));
    } else if (userEmail) {
      setInvitedUsers((prevUsers) =>
        prevUsers.filter((u) => u.email !== userEmail)
      );
    }
  };

  const handleSearchChange = (e) => {
    setInviteSearch(e.target.value);

    setNewUser({
      ...newUser,
      email: e.target.value,
    });
  };
  return (
    <div className="container mt-3">
      <div className="mb-3">
        <label htmlFor="className" className="form-label">
          Nombre de la clase:
        </label>
        <input
          type="text"
          className="form-control mt-0"
          id="className"
          value={_class.className}
          onChange={(e) => _setClass({ ..._class, className: e.target.value })}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="className" className="form-label">
          Nombre de la escuela:
        </label>
        <input
          type="text"
          className="form-control mt-0"
          id="className"
          value={_class.schoolName}
          onChange={(e) => _setClass({ ..._class, schoolName: e.target.value })}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="countrySelect" className="form-label">
          País:
        </label>
        <select
          className="form-select"
          id="countrySelect"
          value={_class.country}
          onChange={(e) => _setClass({ ..._class, country: e.target.value })}
        >
          <option value="es">España</option>
          <option value="mx">México</option>
          <option value="us">EEUU</option>
          <option value="ar">Argentina</option>
          <option value="bo">Bolivia</option>
          <option value="br">Brasil</option>
          <option value="cl">Chile</option>
          <option value="co">Colombia</option>
          <option value="cr">Costa Rica</option>
          <option value="cu">Cuba</option>
          <option value="do">República Dominicana</option>
          <option value="ec">Ecuador</option>
          <option value="sv">El Salvador</option>
          <option value="gt">Guatemala</option>
          <option value="hn">Honduras</option>
          <option value="ni">Nicaragua</option>
          <option value="pa">Panamá</option>
          <option value="py">Paraguay</option>
          <option value="pe">Perú</option>
          <option value="pr">Puerto Rico</option>
          <option value="uy">Uruguay</option>
          <option value="ve">Venezuela</option>
        </select>
      </div>
      <hr />
      {/* <div className="mb-3">
        <label htmlFor="emailInput" className="form-label">
          Añade los correos electrónicos de los alumnos y otros profesores de tu
          clase:
        </label>
        <input
          type="text"
          className="form-control"
          id="emailInput"
          value={inviteSearch}
          onChange={handleSearchChange}
        />
        {errorMsg && (
          <div className="alert alert-danger" role="alert">
            {errorMsg}
          </div>
        )}
      </div> */}
      {/* {isAddNewUser && !searchedSelf && (
        <div className="alert alert-info" role="alert">
          <h4 className="alert-heading">
            No se encontró ningún usuario con este correo electrónico. ¡Envíales
            una invitación!
          </h4>
          <p>{inviteSearch}</p>
          <button onClick={handleAddNewUser} className="btn btn-success">
            Agregar usuario
          </button>
        </div>
      )} */}
      {/* {searchedSelf && (
        <h3 className="text-center">¡Ya estarás parte de la clase!</h3>
      )} */}
      {/* <ul className="list-group mb-3" id="searched-ul">
        {users.map((user) => (
          <li
            className="list-group-item d-flex justify-content-between align-items-center"
            key={user.id}
            onClick={() => handleUserInvite(user)}
          >
            {user.firstName} {user.lastName}{" "}
            <span className="badge bg-primary rounded-pill">
              Haz clic para agregar a la lista
            </span>
          </li>
        ))}
      </ul> */}
      {/* 
      <h4>Usuarios para invitar:</h4> */}
      <p className="lead text-black fw-bold">
        *Una vez crees tu clase, obtendrás un código para que tus
        estudiantes pueden unirse.
      </p>
      {/* <ul className="list-group mb-3" id="users-ul">
        {invitedUsers.map((user) => (
          <li key={user.email} className="list-group-item">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <button
                  className="btn-close"
                  onClick={() => handleRemoveUser(user.id, user.email)}
                ></button>
                <span className="ms-2">{user.email}</span>
                {user.firstName && (
                  <span className="ms-2">
                    {user.firstName} {user.lastName}
                  </span>
                )}
              </div>
              <div>
                <button
                  onClick={() => handleProfesorClick(user)}
                  className="btn btn-outline-success me-2 {user.isClassAdmin ? 'active' : ''}
                >
                  Profesor
                </button>
                <button
                  onClick={() => setRole(user.email, false)}
                  className="btn btn-outline-secondary {user.isClassAdmin ? '' : 'active'}
                >
                  Estudiante
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul> */}

      {error.length > 0 && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}
      <button className="btn btn-primary" onClick={handleSubmit}>
        Crear clase
      </button>
    </div>
  );
}

// ... rest of the code ...

export default ClassCreate;
