import React, { useState, useRef, useEffect, useContext } from "react";

import { Card as MuiCard } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "moment/locale/es"; // Import the Spanish locale
import "./index.css";
import { AudioContext, UserContext } from "../../contexts";
import { PodcastContext } from "../../contexts";
import { authReq } from "../../utilities/requests";
import { CardContext } from "../../contexts/cardContext";
import defaultSeriesImage from "../../assets/earbud.jpg";
const uuidv4 = require("uuid").v4;

const Card = ({ data, edit, search }) => {
  moment.locale("es"); // Set moment to use the
  const navigate = useNavigate();
  let newDate = moment(data.date).format("MMMM YYYY");
  const [expanded, setExpanded] = useState(false);
  const [expandedCSS] = useState(false);
  const [liked, setLiked] = useState(false);
  const [saved, setSaved] = useState(false);
  const [randomInteger, setRandomInteger] = useState(
    Math.floor(Math.random() * 6)
  );
  const [url, setUrl] = useState("");
  const { currentAudio, player, handlePlay } = useContext(AudioContext);
  const { likedPodcasts, setLikedPodcasts, savedPodcasts, setSavedPodcasts } =
    useContext(CardContext);
  const { messages, setMessages } = useContext(PodcastContext);
  const { user } = useContext(UserContext);
  // on first render of card, check if
  useEffect(() => {
    if (likedPodcasts.find((pod) => pod.podcast === data.id)) {
      setLiked(true);
    }
    if (savedPodcasts.find((pod) => pod.podcast === data.id)) {
      setSaved(true);
    }
  }, [likedPodcasts, savedPodcasts]);

  const navigateToDetails = (e) => {
    if (edit) return navigate(`/Mi Estudio/editar/${data?.id}`);
    if (search) return navigate(`/podcast/${data?.id}`);
    navigate(`/podcast/${data?.id}`);
  };

  const onError = (event) => {
    // Prevent infinite callback loop if the fallbackSrc also fails
    if (event.target.src !== defaultSeriesImage) {
      event.target.src = defaultSeriesImage;
    }
  };

  let getURL = async () => {
    await axios("/img_url/" + data.seriesId).then((res) => setUrl(res.data));
  };
  useEffect(() => {
    getURL();
  }, []);
  const returnFlag = () => {
    // <span className="fi fi-ca"></span>
    // switch case for data.languageCode (for example "es-ES") reutnring the flag
    // english ,spanish, catalan, basque,
    switch (data.languageCode) {
      case "es-ES":
        return <span className="fi fi-es"></span>;
      case "en-US":
        return <span className="fi fi-us"></span>;
      case "ca-ES":
        return <span className="fi fi-es-ct"></span>;
      case "eu-ES":
        return <span className="fi fi-es-pv"></span>;
      default:
        return <span></span>;
    }
  };
  return (
    <MuiCard
      sx={{ maxWidth: 180 }}
      className={`individual-card ${expanded ? "expanded" : null} ${
        expandedCSS ? "z-index-5" : null
      }`}
      onClick={(e) => navigateToDetails(e)}
    >
      <CardMedia
        component="img"
        height="140"
        src={`https://peerpodimages-eu.s3.amazonaws.com/${data?.seriesId}.jpg`}
        // src={url}
        // src={images[randomInteger]}
        alt="Banner de la serie"
        className={`card-image ${expanded ? "expanded-img" : null}`}
        onError={onError}
      />

      <CardHeader
        onClick={(e) => navigateToDetails(e)}
        action={<div></div>}
        title={
          <div title={data.title}>
            {returnFlag()} {data.title}
          </div>
        }
        subheader={
          <div className="card-subheader">
            <div style={{ textWrap: "nowrap" }}>
              {data.owner?.firstName || " "}
            </div>
            <div>{newDate}</div>
          </div>
        }
        className="header-1"
      />
    </MuiCard>
  );
};

export default Card;
