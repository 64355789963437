/// CSS ///
import "./index.css";
/// MUI & IMAGES ///
import { Home, AccountBalance, Mic, School } from "@mui/icons-material";
import Logo from "../../assets/PeerpodLogoRed.png";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
/// COMPONENTS ///
import { Sidebar as ProSidebar } from "react-pro-sidebar";
import { Link, useNavigate } from "react-router-dom";

// import 'react-pro-sidebar/dist/css/styles.css';

import { LoaderContext, UserContext } from "../../contexts";
import { useContext, useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { authReq, sendErrorReport } from "../../utilities/requests";

const Sidebar = () => {
  const navigate = useNavigate();
  const { user, setUser, buttonsContainerRef, resetTour } =
    useContext(UserContext);
  const {
    showCookies,
    setShowCookies,
    linkVisited,
    handleCookieLinkClick,
    setLoader,
    setMessageState,
    flashStudioAndClass,
    setFlashStudioAndClass,
    width,
  } = useContext(LoaderContext);
  const [joinPassword, setJoinPassword] = useState("");
  const [error, setError] = useState("");
  const menuItems = [
    {
      title: "Inicio",
      component: Home,
      path: "/",
      id: "home-button",
      highlightPage: true,
      stepContent: "En la página de inicio verás los podcasts disponibles.",
      nextStepContent: JSON.stringify([
        {
          target: "#greeting",
          jumpTo: "/",
          childOf: "#home-button",
          highlightPage: true,
          content:
            "Haz clic en un podcast para escucharlo y ver más información. También puedes darle a 'Me gusta' o guardarlo en tu librería.",
          placement: "top-end",
        },
      ]),
    },
    {
      title: "Mi estudio",
      component: Mic,
      path: "/Mi Estudio/subir",
      id: "studio-button",
      stepContent: "En tu estudio puedes subir y editar tus podcasts.",
      nextStepContent: JSON.stringify([
        {
          target: ".upload-submit-button",
          jumpTo: "/Mi Estudio/subir",
          childOf: "#studio-button",
          highlightPage: true,
          content:
            "Al acabar de rellenar los campos puedes subir tu podcast para que otros lo disfruten.          ",
          placement: "top",
        },
      ]),
    },
    {
      title: "Mi clase",
      component: School,
      path: `/clase`,
      id: "class-button",
      stepContent:
        user.accountType === "teacher"
          ? "En tu clase puedes ver los podcasts subidos por tus estudiantes y aprobar su publicación."
          : "En tu clase puedes ver los podcasts subidos por tus compañeros.",
      nextStepContent: JSON.stringify([
        {
          target: ".page",
          jumpTo: "/clase",
          content:
            "Una vez que hayas creado una clase, obtendrás un código de clase que tus alumnos pueden usar para registrarse.",
          placement: "top",
        },
      ]),
    },
    {
      title: "Mi librería",
      component: AccountBalance,
      path: "/libreria",
      id: "library-button",
      jumpTo: "/libreria",
      stepContent:
        "En tu librería puedes ver tus podcasts guardados y favoritos.",
    },
    {
      title: "Suscripción",
      component: WorkspacePremiumIcon,
      path: "suscripción",
      id: "subscription-button",
      stepContent: "En suscripción puedes ver y cambiar tu plan.",
    },
  ];

  const limitedItems = [menuItems[0], menuItems[1], menuItems[3]];
  // remove subscription for certain users
  if (user.accountType === "student") {
    menuItems.pop();
  }
  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  }, [error]);
  const joinClass = async () => {
    if (!joinPassword) return setError("Hay que ingresar un código de clase");
    setLoader(true);
    try {
      let joinRes = await authReq({
        url: "/join_class",
        method: "POST",
        data: { joinPassword },
      });
      setJoinPassword("");
      setUser(joinRes.data.user);
      localStorage.setItem("jwt_user", joinRes.data.userToken);
      setMessageState({
        text: "¡Te has unido a la clase! ¡Ahora tienes acceso a tu estudio (donde puedes subir podcasts) y a la página de tu clase!",
        time: 5000,
      });
      setFlashStudioAndClass(true);
    } catch (error) {
      const { message, remainingAttempts } = error.response.data;
      setError(
        <div>
          {remainingAttempts > 0 && <div>{message}</div>}

          <div>
            {remainingAttempts > 0
              ? `Solo quedan ${remainingAttempts} intentos.`
              : "No quedan intentos. Inténtalo de nuevo mañana"}
          </div>
        </div>
      );
      console.log(error);
      sendErrorReport("sidebar - joinClass", error);
    }
    setLoader(false);
  };

  let displayItems =
    user.accountType === "teacher" ||
    (user.accountType === "student" && user.classCode)
      ? menuItems
      : limitedItems;

  const returnButtons = () => {
    let arrayOfButtons = displayItems.map((item) => {
      let flashingClass =
        (item.title === "Mi estudio" || item.title === "Mi clase") &&
        flashStudioAndClass
          ? "flashing-teal-background"
          : "";
      return (
        <div
          className={`sidebar-button-container w-100 no-select ${flashingClass}`}
          key={item.title}
          onClick={() => navigate(item.path)}
          id={item.id}
          data-step-description={item.stepContent}
          data-step-next={item.nextStepContent}
        >
          <item.component className="sidebar-button" />
          <div className="sidebar-title">{item.title}</div>
        </div>
      );
    });
    return arrayOfButtons;
  };

  return (
    <div id="sidebar-parent">
      <ProSidebar className="main-sidebar">
        <img
          src={Logo}
          className="sidebar-logo"
          alt="logotipo de la empresa - Playpod"
          onClick={() => navigate("/")}
        />
        <div className="buttons-container" ref={buttonsContainerRef}>
          {returnButtons()}
        </div>

        {user.accountType === "student" && !user.classCode && (
          <div className="join-class-input">
            <div>¿Tienes tu código de clase? ¡Inscríbelo aquí!</div>
            {error && <div style={{ color: "red" }}>{error}</div>}
            <input
              value={joinPassword}
              onChange={(e) =>
                setJoinPassword(e.target.value.replace(/[^0-9]/g, ""))
              }
            />
            <button onClick={joinClass}>Unirse a la clase</button>
          </div>
        )}

        <h2 id="contact-header">Contacto</h2>
        <p>contact@playpod.education</p>
        <Link to="/Informar error">Informar de un error</Link>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <a
            className="sidebar-terms"
            href="/Terms_And_Conditions_Playpod.pdf"
            target="_blank"
          >
            Términos de servicio
          </a>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <a href="/Privacy_Policy_Playpod.pdf" target="_blank">
            Política de privacidad
          </a>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <span
            id="cookies-link"
            style={{ color: linkVisited ? "purple" : "blue" }}
            onClick={() => handleCookieLinkClick()}
          >
            Política de cookies
          </span>
        </Typography>
        {width >= 1250 && (
          <button className="btn btn-primary mt-4" onClick={() => resetTour()}>
            Tutorial
          </button>
        )}
      </ProSidebar>
    </div>
  );
};

export default Sidebar;
