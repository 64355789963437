import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LoaderContext, UserContext } from "../../contexts";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Button,
  FormControl,
  Input,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import "./index.css";
import axios from "axios";
import { authReq } from "../../utilities/requests";
import { returnUpperCase } from "../../utilities/text";

const EditProfile = () => {
  const navigate = useNavigate();

  let {
    user,
    setUser,
    setToken,
    avatarsMap,
    userAvatar,
    setUserAvatar,
    isGettingAvatar,
    plan,
    preferences,
    setPreferences,
  } = useContext(UserContext);
  let preferencesKeys = Object.keys(preferences);
  const firstInitial = user.firstName[0];
  const lastInitial = user.lastName[0];

  const [showAvatarSelection, setShowAvatarSelection] = useState(false);

  const { linkVisited, handleCookieLinkClick } = useContext(LoaderContext);
  const avatars = [
    { label: "Blue Avatar", index: 1 },
    { label: "Grey Avatar", index: 2 },
    { label: "Orange Avatar", index: 3 },
  ];

  const handleAvatarSelect = (newIndex) => {
    // 1. set new avatar in app state
    setUserAvatar(avatarsMap[newIndex]);

    axios({
      url: "/change_avatar",
      method: "POST",
      data: {
        newIndex,
        id: user.id,
      },
    }).then((res) => {});
  };

  const [errors, setErrors] = useState({
    noFirstName: false,
    shortFirstName: false,
    noLastName: false,
    shortLastName: false,
    noClassCode: false,
    noAbout: false,
    shortAbout: false,
  });

  const [success, setSuccess] = useState(false);

  const [formState, setFormState] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    classCode: user.classCode,
    about: user.about,
  });

  const handleFirstNameChange = (e) => {
    let newFirstName = e.target.value;
    if (errors.noFirstName && newFirstName)
      setErrors({ ...errors, noFirstName: false });
    if (errors.shortFirstName && newFirstName)
      setErrors({ ...errors, shortFirstName: false });
    setFormState((prev) => ({ ...prev, firstName: newFirstName }));
  };

  const handleLastNameChange = (e) => {
    let newLastName = e.target.value;
    if (errors.noLastName && newLastName)
      setErrors({ ...errors, noLastName: false });
    if (errors.shortLastName && newLastName)
      setErrors({ ...errors, shortLastName: false });
    setFormState((prev) => ({ ...prev, lastName: newLastName }));
  };

  const handleClassCodeChange = (e) => {
    let newClassCode = e.target.value;
    if (errors.noClassCode && newClassCode)
      setErrors({ ...errors, noClassCode: false });
    setFormState((prev) => ({ ...prev, classCode: newClassCode }));
  };

  const CHARACTER_LIMIT = 500;
  const handleAboutChange = (e) => {
    let newAbout = e.target.value;
    if (errors.noAbout && newAbout) setErrors({ ...errors, noAbout: false });
    if (errors.shortAbout && newAbout)
      setErrors({ ...errors, shortAbout: false });
    if (newAbout?.length <= CHARACTER_LIMIT)
      setFormState((prev) => ({ ...prev, about: newAbout }));
  };

  const handleSave = () => {
    //* 1. check form valid
    let errorPresent = false;
    const formErrors = {
      noFirstName: false,
      shortFirstName: false,
      noLastName: false,
      shortLastName: false,
      noClassCode: false,
      noAbout: false,
      shortAbout: false,
    };
    if (!formState.firstName) {
      formErrors.noFirstName = true;
      errorPresent = true;
    } else if (formState.firstName.length < 3) {
      formErrors.shortFirstName = true;
      errorPresent = true;
    }
    if (!formState.lastName) {
      formErrors.noLastName = true;
      errorPresent = true;
    } else if (formState.lastName.length < 3) {
      formErrors.shortLastName = true;
      errorPresent = true;
    }
    if (errorPresent) {
      setErrors(formErrors);
      return;
    }
    //* 2. update user
    authReq({
      url: "/update_user",
      method: "PUT",
      data: formState,
    }).then((res) => {
      if (res.status === 200) {
        localStorage.setItem("jwt_user", res.data.token);
        const updatedUser = {
          ...user,
          ...formState,
        };

        setUser(updatedUser);
        setSuccess(true);
      }
    });
  };
  const handlePreferencesSave = () => {
    authReq({
      url: "/update_preferences",
      method: "PUT",
      data: preferences,
    })
      .then((res) => {
        if (res.status === 200) {
          setSuccess(true);
          // set update user in context with new preferences in res.data
          setUser({ ...user, preferences: res.data });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSwitch = (key) => (e) => {
    setPreferences((prev) => ({
      ...prev,
      [key]: { ...preferences[key], boolean: e.target.checked },
    }));
  };

  const [deleteError, setDeleteError] = useState(false);
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const [deleteValue, setDeleteValue] = useState("");

  const handleShowPreference = (preference, key) => {
    setPreferences({
      ...preferences,
      [key]: {
        ...preference,
        displayDesc: !preference.displayDesc,
      },
    });
  };
  const handleDelete = async (e) => {
    //* MODAL DOUBLE CHECK! (cannot be undone!)
    let response = await axios({
      method: "DELETE",
      url: `/delete_user/${user.id}`,
    });
    if (response.data === "success" || response.status === 200) {
      setUser();
      setToken();
      localStorage.setItem("jwt_user", "");
    }
  };

  const handleModalSubmit = (e) => {
    e.preventDefault();
    handleDelete();
    setShowDeleteAccountModal(false);
  };

  return (
    <Box sx={{ flexGrow: 1 }} className="page-padding">
      {success && (
        <div className="success">
          <IconButton
            className="icon-button"
            onClick={() => setSuccess(false)}
            size="small"
          >
            <CloseIcon className="close-icon" size="small"></CloseIcon>
          </IconButton>
          <div className="success-text-profile">
            ¡Felicidades! ¡Tu perfil ha sido actualizado!
          </div>
          <Link className="link-text" to={`/perfil/${user.id}`}>
            Volver al perfil
          </Link>
        </div>
      )}
      <form id="edit-profile-form">
        <Grid container spacing={2}>
          <Grid
            item
            sm={12}
            md={4}
            lg={2}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <Avatar
              id="user-avatar"
              sx={{
                border: "1px solid #424242",
                width: "125px",
                height: "125px",
                fontSize: 75,
                cursor: "auto",
              }}
              imgProps={{
                style: {
                  objectFit: "cover",
                  borderRadius: "50%",
                },
              }}
              src={userAvatar}
              alt={user.firstName}
              title={"Change Avatar"}

              // onClick={() => setShowAvatarSelection(true)}
            >
              {returnUpperCase(firstInitial, lastInitial)}
            </Avatar>
          </Grid>
          <Grid item sm={12} md={8} lg={10} style={{ width: "100%" }}>
            <Grid container justifyContent="space-between">
              <Grid item xs={12} md={6}>
                <div className="error">
                  {errors.noFirstName || errors.shortFirstName
                    ? "* Nombre requerido: al menos 3 caracteres"
                    : ""}
                </div>
                <FormControl className="form-control">
                  <InputLabel htmlFor="firstName">Nombre</InputLabel>
                  <Input
                    required={true}
                    id="firstName"
                    label="Nombre"
                    variant="standard"
                    placeholder="First Name"
                    value={formState.firstName}
                    onChange={handleFirstNameChange}
                    error={
                      errors.noFirstName || errors.shortFirstName ? true : false
                    }
                  />
                </FormControl>
                <div className="error">
                  {errors.noLastName || errors.shortLastName
                    ? "* Apellidos requerido: al menos 3 caracteres"
                    : ""}
                </div>
                {/* <FormControl className="form-control">
                  <InputLabel htmlFor="lastName">Apellidos</InputLabel>
                  <Input
                    required={true}
                    id="lastName"
                    label="Apellidos"
                    variant="standard"
                    placeholder="Last Name"
                    value={formState.lastName}
                    onChange={handleLastNameChange}
                    error={
                      errors.noLastName || errors.shortLastName ? true : false
                    }
                  />
                </FormControl> */}
                <br></br>

                <FormControl className="form-control my-3">
                  <div>Suscripción</div>
                  {user?.isClassAdmin ? (
                    <Link to="/suscripción">
                      {plan
                        ? plan.name[0].toUpperCase() +
                          plan.name.slice(1, plan.name.length)
                        : "Sin suscripción..."}{" "}
                      (Cambiar suscripción)
                    </Link>
                  ) : (
                    <div>
                      {plan ? plan.name : "Sin suscripción..."} (Solo Profesores
                      de tu clase puede{" "}
                      <Link to="suscripción"> cambiar suscripción</Link>)
                    </div>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormControl className="form-control" fullWidth>
                <TextField
                  fullWidth
                  required={true}
                  id="about"
                  label="Sobre mí (Max 500 caracteres) "
                  className="about-input"
                  multiline
                  rows={5}
                  variant="outlined"
                  inputProps={{ maxLength: CHARACTER_LIMIT }}
                  helperText={`${
                    formState.about?.length || 0
                  }/${CHARACTER_LIMIT}`}
                  value={formState.about ? formState.about : ""}
                  onChange={handleAboutChange}
                  error={errors.noAbout || errors.shortAbout ? true : false}
                />
              </FormControl>
            </Grid>
            <Grid container spacing={1} className="edit-container-buttons">
              <Grid item>
                <Button
                  variant="contained"
                  sx={{ color: "white", fontWeight: "bold" }}
                  aria-label="save profile button"
                  onClick={handleSave}
                >
                  Guardar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="themeLightGrey"
                  sx={{ fontWeight: "bold" }}
                  aria-label="go back to profile button"
                  onClick={() => navigate(`/perfil/${user.id}`)}
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>

      <hr id="horizontal-rule" />

      <Grid container spacing={2} id="preferencias">
        <Grid item xs={12}>
          <Typography variant="h5" component={"h5"}>
            Preferencias
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" component={"p"}>
            Correo electrónico y notificaciones
          </Typography>
        </Grid>
        {preferencesKeys.map((key, index) => {
          const preference = preferences[key];
          return (
            <Grid
              item
              xs={12}
              key={preference.description}
              title={preference.description}
            >
              {preference.displayDesc && (
                <span>
                  {preference.description}
                  <br></br>
                </span>
              )}
              <FormControlLabel
                sx={{ marginRight: "0px" }}
                label={preference.label}
                control={
                  <Switch
                    checked={preference.boolean}
                    onChange={handleSwitch(key)}
                  />
                }
              />
              <HelpOutlineIcon
                onClick={() => handleShowPreference(preference, key)}
                style={{ width: "18px" }}
              />
            </Grid>
          );
        })}
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <div
            id="cookies-link"
            style={{
              color: linkVisited ? "purple" : "blue",
              paddingLeft: "16px",
            }}
            onClick={() => handleCookieLinkClick()}
          >
            Política de cookies
          </div>
        </Typography>
        <Grid container spacing={1} className="edit-container-buttons">
          <Grid item>
            <Button
              variant="contained"
              sx={{ color: "white", fontWeight: "bold" }}
              aria-label="save profile button"
              onClick={handlePreferencesSave}
            >
              Guardar
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="themeLightGrey"
              sx={{ fontWeight: "bold" }}
              aria-label="go back to profile button"
              onClick={() => navigate(`/perfil/${user.id}`)}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <hr id="horizontal-rule" />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" component={"h5"} sx={{ color: "black" }} s>
            Eliminar mi cuenta
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            component={"p"}
            sx={{ color: "var(--errorRed)" }}
          >
            Una vez elimines tu cuenta perderás toda la información.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="errorRed"
            sx={{ fontWeight: "bold" }}
            aria-label="delete account button"
            onClick={() => setShowDeleteAccountModal(true)}
          >
            eliminar mi cuenta
          </Button>
        </Grid>
      </Grid>

      <Modal
        open={showDeleteAccountModal}
        onClose={() => setShowDeleteAccountModal(false)}
      >
        <Box className="modal-content" style={{ width: "500px" }}>
          <IconButton
            className="modal-close-icon"
            onClick={() => setShowDeleteAccountModal(false)}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="h2">
            <strong>ADVERTENCIA</strong>
          </Typography>
          <Typography
            variant="h6"
            component="h2"
            style={{ marginBottom: "12px" }}
          >
            Estás a punto de eliminar tu cuenta permanentemente. Esta acción no
            se puede deshacer. Si decides continuar, perderás todos los datos y
            el acceso a los servicios asociados a tu cuenta. Para confirmar la
            eliminación de tu cuenta, por favor haz clic en el botón a
            continuación:
          </Typography>
          {/* <Typography sx={{ my: 1 }}>
            Por favor, escriba:{" "}
            <em id="delete-account-text text-black">Eliminar mi cuenta</em> en
            el <br></br> cuadro de abajo para eliminar tu cuenta.
          </Typography> */}
          <form onSubmit={handleModalSubmit}>
            {/* <TextField
              required={true}
              id="deleteText"
              label="eliminar mi cuenta"
              placeholder="eliminar mi cuenta"
              value={deleteValue}
              onChange={(e) => {
                setDeleteError(false);
                setDeleteValue(e.target.value);
              }}
              error={deleteError}
              fullWidth
              sx={{ mt: 1 }}
            /> */}
            <Button
              type="submit"
              variant="contained"
              color="errorRed"
              sx={{ fontWeight: "bold", mt: 1 }}
              fullWidth
              aria-label="delete account button"
            >
              Eliminar mi cuenta
            </Button>
          </form>
        </Box>
      </Modal>
      <Modal
        open={showAvatarSelection}
        onClose={() => setShowAvatarSelection(false)}
      >
        <Box className="modal-content">
          <IconButton
            className="modal-close-icon"
            onClick={() => setShowAvatarSelection(false)}
          >
            <CloseIcon />
          </IconButton>
          <p>Elige tu avatar</p>
          {avatars.map((avatar, index) => (
            <img
              key={index}
              src={avatarsMap[avatar.index]}
              alt={avatar.label}
              onClick={() => handleAvatarSelect(avatar.index)}
            />
          ))}
        </Box>
      </Modal>
    </Box>
  );
};

export default EditProfile;
