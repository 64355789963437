import React, { useState, createContext } from "react";

export const AudioContext = createContext();

export function AudioProvider(props) {
  const [currentAudio, setCurrentAudio] = useState(null);
  const [player, setPlayer] = useState(null);

  const handlePlay = async (clickedPodcast) => {
    if (clickedPodcast?.id === currentAudio?.id) {
      setCurrentAudio({
        ...currentAudio,
        playing: currentAudio.playing ? false : true,
      });
    } else {
      setCurrentAudio({
        ...clickedPodcast,
        playing: true,
      });
    }
  };

  return (
    <AudioContext.Provider
      value={{
        currentAudio,
        setCurrentAudio,
        player,
        setPlayer,

        handlePlay,
      }}
    >
      {props.children}
    </AudioContext.Provider>
  );
}
