import React, { useContext, useState } from "react";
import "./index.css";
import { LoaderContext, UserContext } from "../../contexts";
import axios from "axios";
import { useEffect } from "react";
import { authReq, generateAlphanumericCode } from "../../utilities/requests";
const EmailConfirmation = () => {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");

  const { user, setUser, token, setToken } = useContext(UserContext);
  const [resent, setResent] = useState(false);
  const { setLoader } = useContext(LoaderContext);

  useEffect(() => {
    if (resent) {
      setTimeout(() => {
        setResent(false);
      }, 3000);
    }
  }, [resent]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);

    let res = await axios({
      method: "POST",
      url: "/check_code",
      data: {
        user,
        enteredCode: code,
      },
    }).catch((err) => {
      setLoader(false);
      setError("Código no válido ");
      return;
    });
    setLoader(false);
    //
    localStorage.setItem("jwt_user", res.data.token);
    setUser(res.data.user);
    setToken(res.data.token);
  };

  const reset = () => {
    setUser();
    setToken();
  };
  const resend = () => {
    let newCode = generateAlphanumericCode();
    setResent(true);
    authReq({
      url: "/send_code",
      method: "POST",
      data: { code: newCode, token },
    }).then((res) => {
      localStorage.setItem("jwt_user", res.data.token);
    });
  };
  return (
    <div className="email-confirmation-container">
      <div onClick={reset} className="back">
        volver atrás
      </div>
      {error && <div style={{ color: "red" }}>{error}</div>}
      <h2>Confirmación del código de correo electrónico</h2>
      <p className="code-msg">
        Introduzca el código de 6 dígitos enviado a{" "}
        <strong>{user?.email}</strong>
      </p>
      <p>Por favor, compruebe la carpeta de spam</p>
      <p className="resend" onClick={() => resend()}>
        Reenviar código
      </p>
      <p className="resent">{resent && "El código fue reenviado"}</p>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          maxLength="8"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          placeholder="Ingresar código"
        />
        <button type="submit">Confirmar</button>
      </form>
    </div>
  );
};

export default EmailConfirmation;
