import "./index.css";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PodcastContext, UserContext } from "../../contexts";
import Card from "../../components/card";
import { Skeleton } from "@mui/material";
import axios from "axios";
import { authReq } from "../../utilities/requests";
import StudioHeader from "../../components/studioHeader";

const SelectEdit = () => {
  const navigate = useNavigate();

  const { usersPodcasts, setUsersPodcasts } = useContext(PodcastContext);
  const { user, podcastCallWasMade, setPodcastCallWasMade } =
    useContext(UserContext);

  useEffect(() => {
    if (!podcastCallWasMade) {
      authReq({
        url: `/get_users_podcasts/${user.id}/${user.classCode}`,
      }).then((result) => {
        setPodcastCallWasMade(true);
        if (result.data.length > 0) {
          setUsersPodcasts(result.data);
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  const loadersArray = Array.from({
    length: usersPodcasts?.length || 1,
  });

  return (
    <div className="">
      <StudioHeader
        isUpload={false}
        subtitle="¿Qué podcast te gustaría editar?"
      />

      <div className="card-container">
        {!podcastCallWasMade ? (
          loadersArray.map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              width={180}
              height={208}
            />
          ))
        ) : usersPodcasts?.length > 0 ? (
          usersPodcasts.map((data) => (
            <Card key={JSON.stringify(data)} data={data} edit={true} />
          ))
        ) : (
          <div id="create-podcast-div">
            <p>Parece que no tienes ningún podcast.</p>
            <p>
              ¿te gustaría{" "}
              <span
                id="create-podcast-text"
                onClick={() => navigate("/mi estudio/subir")}
              >
                crear
              </span>{" "}
              uno?
            </p>
          </div>
        )}
      </div>
      <div className="flex-container"></div>
    </div>
  );
};

export default SelectEdit;
