import React, { useContext, useEffect } from "react";
import { PodcastContext, UserContext } from "../../contexts";
import Card from "../../components/card";
import { Skeleton } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "./index.css";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { logDOM } from "@testing-library/react";

const Dashboard = () => {
  let { podcastCache, myClass } = useContext(PodcastContext);
  let { user } = useContext(UserContext);
  const navigate = useNavigate();
  let [width, setWidth] = useState(1300);
  // make this depending on
  // let podcasts = podcastCache?.random?.length
  //   ? podcastCache.map((data) => (
  //       <Card key={JSON.stringify(data)} data={data} />
  //     ))
  //   : [1, 2, 3, 4, 5].map((number) => (
  //       <Skeleton
  //         key={number}
  //         variant="rectangular"
  //         width={180}
  //         height={344}
  //       />
  //     ));

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // The empty dependency array ensures the effect runs only once, similar to componentDidMount
  const responsiveValues = {
    300: { items: 2 },
    500: { items: 3 },
    915: { items: 4 },
    1150: { items: 4 },
    1500: { items: 5 },
    1700: { items: 6 },
  };
  const returnCards = (array) => {
    //

    let podcasts = array
      ? array.map((data) => <Card key={JSON.stringify(data)} data={data} />)
      : [1, 2, 3, 4, 5].map((number) => (
          <Skeleton
            key={number}
            variant="rectangular"
            width={180}
            height={208}
          />
        ));

    let cardsNumber;
    const sortedKeys = Object.keys(responsiveValues)
      .map(Number)
      .sort((a, b) => a - b);
    for (let i = 0; i < sortedKeys.length; i++) {
      if (width >= sortedKeys[i]) {
        // Update myVar to the 'items' value for the current key
        cardsNumber = responsiveValues[sortedKeys[i]].items;
      } else {
        // Since keys are sorted, if current key is greater than width, we break the loop
        break;
      }
    }

    // when we have 4 or less cards, fill extra carousel space with divs
    if (podcasts.length > 0 && podcasts.length < 5) {
      let extra = cardsNumber - podcasts.length;
      for (let i = 0; i < extra; i++) {
        podcasts.push(<div></div>);
      }
    }

    return podcasts;
  };
  // const responsiveValues = {
  //   1000: {items: 1},

  // };
  const carouselRows = [
    { title: "Lo más nuevo", podcastArray: returnCards(podcastCache?.newest) },
    {
      title: "Lo más popular",
      podcastArray: returnCards(podcastCache?.random),
    },
    {
      title: "Primaria (6-11 años)",
      podcastArray: returnCards(podcastCache?.elementary),
    },

    // { title: 'College Podcasts', podcastArray: returnCards(podcastCache?.college) },
  ];
  if (user.schoolLevel !== "primaria") {
    carouselRows.push({
      title: "Secundaria (12-14 años)",
      podcastArray: returnCards(podcastCache?.middle),
    });
    carouselRows.push({
      title: "Bachillerato (16-18 años)",
      podcastArray: returnCards(podcastCache?.high),
    });
  }

  if (myClass?.audios?.length < 1) {
    // message saying your class must upload an audio before you can see the dashboard (other podcasts)
    return (
      <div className=" w-100">
        <div id="upper-text-container">
          <div id="greeting">¡Bienvenido!</div>
          <div className="sub-heading">
            Sube tu primer podcast en Mi Estudio para acceder a la página de
            inicio.
          </div>
          {/* <img src={uploadImage} alt="Upload A Podcast" /> */}
          <button
            className="btn btn-primary"
            onClick={() => navigate("/Mi Estudio/Subir")}
          >
            Ir a Mi Estudio
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className=" w-100">
      <div id="upper-text-container">
        <div id="greeting">
          ¡Hola {user.firstName}! ¿Qué quieres escuchar hoy?
        </div>
        {/* <div className="sub-heading">Últimos podcasts</div> */}
      </div>
      <div className="card-container">
        {carouselRows.map((rowObj, index) => (
          <div
            className={`single-carousel ${
              !rowObj.podcastArray.length ? "py-3  " : ""
            }`}
            key={rowObj.title}
          >
            <h3>{rowObj.title}</h3>
            {rowObj.podcastArray.length ? (
              <AliceCarousel
                mouseTracking
                items={rowObj.podcastArray}
                responsive={responsiveValues}
                // autowidth true causing responsiveness issues
                // autoWidth={true}
              />
            ) : (
              <Link to="/Mi Estudio/subir" className="padding-left">
                El nuevo contenido puede tardar un poco en aparecer. Crea tu
                primer podcast y pronto verás contenido de otros centros.
              </Link>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
