import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { authReq, sendErrorReport } from "../../utilities/requests";
import { useContext, useState } from "react";
import { LoaderContext, UserContext } from "../../contexts";
import { TextField, Grid } from "@mui/material";

const SubscriptionForm = ({ selectedPlan }) => {
  const stripe = useStripe();
  const elements = useElements();
  const cardElement = elements.getElement(CardElement);
  const { customerData, setPlan, setPaymentSuccess } = useContext(UserContext);

  const { setLoader, setMessageState } = useContext(LoaderContext);
  const [isSame, setIsSame] = useState(true);
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
  });
  const cardElementOptions = {
    style: {
      base: {
        fontSize: "16px",
        color: "#424770",
        "::placeholder": {
          color: "#d5d5d8",
        },
      },
      invalid: {
        color: "#9e2146",
      },
    },
    hidePostalCode: true,
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const setupSubscription = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);
      let billingDetails = isSame
        ? customerData.info
        : {
            name: formData.name,
            email: formData.email,
            address: {
              line1: formData.addressLine1,
              line2: formData.addressLine2,
              city: formData.city,
              state: formData.state,
              postal_code: formData.postalCode,
              country: formData.country,
            },
          };

      const result = await stripe.confirmCardPayment(
        customerData.client_secret,
        {
          payment_method: {
            card: cardElement,
            billing_details: billingDetails,
          },
        }
      );
      if (result?.paymentIntent?.status === "succeeded") {
        setLoader(false);
        // here set some state to show that the payment was successful
        // also update plans table, create plan token
        try {
          let res = await authReq({
            url: "/update_plan",
            method: "POST",
            data: {
              customerData,
              plan: selectedPlan,
            },
          });

          setPlan(res.data);
          setLoader(false);
        } catch (error) {
          sendErrorReport("Subscription From - SetupSubscription", error);
        }

        setPaymentSuccess(true);
        authReq({ url: "/get_user_plan" }).then((res) => {
          setPlan(res.data.plan);
          localStorage.setItem("plan", res.data.planToken);
        });
        setLoader(false);
      } else {
        setLoader(false);
        console.log(`Payment Declined: ${result?.error?.message}`);
      }
    } catch (error) {
      console.error(error);
      sendErrorReport("SubscriptionForm - setupSubscription outer", error);

      setLoader(false);
      setMessageState({
        text: "Hubo un error al procesar el pago - intenta de nuevo más tarde o contacta a tech@playpod.education",
        time: 9000,
      });
    }
  };

  return (
    <form onSubmit={setupSubscription}>
      {/* <input
        type="checkbox"
        checked={isSame}
        onChange={() => setIsSame(!isSame)}
      />
      <label>
        La dirección de facturación es la misma que la dirección anterior
      </label> */}
      <br></br>
      <br></br>
      {!isSame && (
        <Grid container alignItems="flex-start" spacing={2}>
          <h2>Dirección de facturación</h2>

          <Grid item xs={12}>
            <TextField
              name="name"
              label="Nombre y apellidos"
              fullWidth
              value={formData.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="addressLine1"
              label="Dirección línea 1"
              fullWidth
              value={formData.addressLine1}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="addressLine2"
              label="Dirección línea 2"
              fullWidth
              value={formData.addressLine2}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="city"
              label="Ciudad"
              fullWidth
              value={formData.city}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="state"
              label="Estado"
              fullWidth
              value={formData.state}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="postalCode"
              label="Código postal"
              fullWidth
              value={formData.postalCode}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="country"
              label="País"
              fullWidth
              value={formData.country}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            {/* <Button variant="contained" color="primary" type="submit">
              Enviar
            </Button> */}
            <br></br>
            <br></br>
          </Grid>
        </Grid>
      )}

      <CardElement options={cardElementOptions} />
      <br></br>
      <button>Pagar</button>
    </form>
  );
};
export default SubscriptionForm;
